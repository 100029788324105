import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Modal, ModalBody } from "reactstrap";
import GoogleMapReact from 'google-map-react';
import { Loader } from '@googlemaps/js-api-loader';
import AuthContext from "../../../contexts/auth/AuthContext";
import AutoComplete from "../../container-management/AutoComplete";
import Button from "../../layout/Button";
import StatusBarButton from "../../layout/StatusBarButton";
import ProcessContext from "../../../contexts/process/ProcessContext";
import ContainersApiService from "../../../services/apiServices/ContainersApiService";
import HarvestingProceduresApiService from "../../../services/apiServices/HarvestingProceduresApiService";
import Loading from "../../ui-elements/Loading";
import { SINGLE_HARVEST_ROOTED, harvestingProcedureTypes } from "../../../constants/harvestingProcedures";
import { separateOnCapitalLetters } from "../../../utility/PascalCaseSeperator";
import FarmhandInstallationsApiService from "../../../services/apiServices/FarmhandInstallationsApiService";
import UIContext from "../../../contexts/ui/UIContext";
import { HELP_DESK_LINK } from "../../../constants/helpDesk";

const Create = () => {

    const {currentTenant, setAccessibleContainers} = useContext(AuthContext);
    const {notifySuccess, notifyWarning, notifyError, notifyStayInformation} = useContext(ProcessContext);
    const {setInfo} = useContext(UIContext);

    const initialContainerModelState = {
        tenantId: currentTenant.tenantId,
        name: {
            value: '',
            touched: false,
            validate: function (text) {
                return text?.length >= 3;
            },
            isValid: false,
            placeholder: 'Name',
            label: 'Name'
        },
        description: {
            value: '',
            touched: false,
            validate: function (text) {
                return true
            },
            isValid: true,
            placeholder: ''
        },
        arloCameraIds: {
            value: [],
            touched: false,
            validate: function (guid) {
                const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
                return regex.test(guid);
            },
            isValid: true,
            placeholder: ''
        },
        farmhandId: {
            value: '',
            touched: false,
            validate: function (text) {
                return true
            },
            isValid: true,
            placedholder: '',
        },
        storageAdviceRoot: {
            value: '',
            touched: false,
            validate: function (text) {
                return true
            },
            isValid: true,
            placeholder: ''
        },
        storageAdviceCut: {
            value: '',
            touched: false,
            validate: function (text) {
                return true
            },
            isValid: true,
            placeholder: ''
        },
        harverstingProcedures: {
            value: '',
            touched: false,
            validate: function (id) {
                return true;
            },
            isValid: true,
            placeholder: ''
        },
        location: {
            value: {
                latitude: null,
                longitude: null,
                street: '',
                number: '',
                postalCode: '',
                city: '',
                country: ''
            }
        }
    };
    
    const navigate = useNavigate();
    const [addedArloCameraIds, setAddedArloCameraIds] = useState([]);
    const [arloCameraInput, setArloCameraInput] = useState('');
    const [farmhandInstallation, setFarmhandInstallation] = useState();
    const [google, setGoogle] = useState(null);
    const [localLoading, setLocalLoading] = useState(false);
    const [locationString, setLocationString] = useState('');
    const [location, setLocation] = useState({
        lat: 50.9396911,
        lng: 6.8551075,
      });
    const [modelState, setModelState] = useState(initialContainerModelState);
    const [openLocation, setOpenLocation] = useState(false);
    const [selectedHarvestProcedures, setSelectedHarvestProcedures] = useState([]);

    useEffect(() => {
        setInfo('Create Container')
        const loader = new Loader({
            apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
            libraries: ['places']
        });
        loader.load().then((g) => {
            setGoogle(g);
        })
    }, []);

    const getLocation = (place) => {
        var number = place.address_components.find(element => element.types.includes('street_number'));
        var street = place.address_components.find(element => element.types.includes('route'));
        var postalCode = place.address_components.find(element => element.types.includes('postal_code'));
        var city = place.address_components.find(element => element.types.includes('locality') || element.types.includes('postal_town'));
        var country = place.address_components.find(element => element.types.includes('country'));
    
        setModelState((prev) => ({
          ...prev,
          ['location']: {
            ...prev.location,
            value: {
              ...prev.location.value,
              number: number ? number.long_name : '',
              street: street ? street.long_name : '',
              postalCode: postalCode ? postalCode.long_name : '',
              city: city ? city.long_name : '',
              country: country ? country.long_name : '',
            }
          },
        }));
        setLocationString(place.formatted_address);
    };

    const handleAddHarvestProcedure = () => {
        const selectedHarvestProcedureValue = document.querySelector('select').value;
        var contained = false;
        selectedHarvestProcedures.map((selection) => {
            if (selection == selectedHarvestProcedureValue) {
                contained = true;
            }
        });
        if (contained == false) {
            const harvestProcedure = harvestingProcedureTypes.find((type) => type === selectedHarvestProcedureValue);
            setSelectedHarvestProcedures([...selectedHarvestProcedures, harvestProcedure]);
        }
    };

    const handleArloCameraIdInputChange = (event) => {
        setArloCameraInput(event.target.value);
    }

    const handleAddArloCameraId = () => {
        if (arloCameraInput.trim() !== '') {
            var contained = false;
            addedArloCameraIds.map((id) => {
                if (id.toLowerCase() == arloCameraInput.trim().toLowerCase()) {
                    contained = true;
                }
            });
            if (arloCameraInput.trim() && contained == false) {
                setAddedArloCameraIds([...addedArloCameraIds, arloCameraInput]);
                setModelState((prev) => ({
                    ...prev,
                    ['arloCameraIds']: {
                        ...modelState['arloCameraIds'],
                        value: [...addedArloCameraIds,arloCameraInput],
                        isValid: true,
                        touched: true,
                    }
                }));
                setArloCameraInput('');
            }
            else {
                setModelState((prev) => ({
                    ...prev,
                    ['arloCameraIds']: {
                        ...modelState['arloCameraIds'],
                        isValid: false,
                        touched: true,
                    }
                }))
            }
        }
    }

    const handleRemoveArloCameraId = (arloCameraId) => {
        const updateArloCameraIds = addedArloCameraIds.filter((id) => id.toLowerCase() != arloCameraId.toLowerCase());
        setAddedArloCameraIds(updateArloCameraIds);
        setModelState((prev) => ({
            ...prev,
            ['arloCameraIds']: {
                ...modelState['arloCameraIds'],
                value: updateArloCameraIds,
            }
        }));
    }

    const handleRemoveHarvestProcedure = (harvestingProcedure) => {
        const updateHarvestingProcedure = selectedHarvestProcedures.filter((type) => type != harvestingProcedure);
        setSelectedHarvestProcedures(updateHarvestingProcedure);
    }

    const Marker = () => {
        return <>
          <div className="pin"></div>
          <div className="pulse"></div>
        </>
    };

    const onChange = (event) => {
        event.persist();
        const key = event.currentTarget.name;
        const value = event.currentTarget.value;
        setModelState((prev) => ({
            ...prev,
            [key]: {
                ...modelState[key],
                value,
                isValid: modelState[key].validate(value),
                touched: true,
            },
        }));
    };

    const onConfirm = async () => {  
        setLocalLoading(true);
        const container = {
            name: modelState.name.value,
            description: modelState.description.value,
            street: modelState.location.value.street,
            number: modelState.location.value.number,
            postalCode: modelState.location.value.postalCode,
            city: modelState.location.value.city,
            country: modelState.location.value.country,
            latitude: modelState.location.value.latitude,
            longitude: modelState.location.value.longitude
        };
        const harvestProcedureRequest = [];
        selectedHarvestProcedures.map((procedure) => {
            if (procedure === SINGLE_HARVEST_ROOTED) {
                harvestProcedureRequest.push({
                    type: procedure,
                    storageAdvice: modelState.storageAdviceRoot.value
                });
            }
            else {
                harvestProcedureRequest.push({
                    type: procedure,
                    storageAdvice: modelState.storageAdviceCut.value
                });
            }
        });
        
        let containsErrors = false;
        let createContainerResponse;
        try {
            createContainerResponse = await ContainersApiService.createContainer(currentTenant.tenantId, container);
        } catch (error) {
            console.error(error);
            if (error.response.data.code === 400) {
                notifyWarning("The container name is missing. Please provide a name for the container and try again.");
            }
            if (error.response.data.code === 403) {
                notifyError(<>We have encountered an issue on our side while creating a new container. Could you please logout, sign back in and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
            }
            if (error.response.data.code === 404) {
                notifyError(<>We have encountered an issue on our side while creating a new container. Could you please logout, sign back in and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
            }
            if (error.response.data.code === 409) {
                notifyWarning("The container name already exists. Please select another name and try again.");
            }
            if (error.response.data.code === 500) {
                notifyError(<>We have encountered an issue on our side while creating a new container. Please contact the support team {HELP_DESK_LINK}.</>);
            }
            setLocalLoading(false);
            return;
        }

        let promises = [];

        if (harvestProcedureRequest.length !== 0) {
            try {
                harvestProcedureRequest.forEach(request => {
                    const harvestingProcedurePromise = HarvestingProceduresApiService.createHarvestingProcedure(currentTenant.tenantId, createContainerResponse.data.data.id, request)
                    promises.push(harvestingProcedurePromise);
                });
            } catch (error) {
                console.error(error);
                containsErrors = true;
            }
        }

        if (farmhandInstallation) {
            try {
                const farmHandInstallationRequest = {farmHandInstallationId: farmhandInstallation.id};
                const farmhandInstallationPromise = FarmhandInstallationsApiService.assignFarmHandInstallationToContainer(currentTenant.tenantId, createContainerResponse.data.data.id, farmHandInstallationRequest);
                promises.push(farmhandInstallationPromise);
            } catch (error) {
                console.error(error);
                containsErrors = true;
            }
        }

        try {
            await Promise.all(promises);
        } catch (error) {
            console.error(error);
            containsErrors = true;
        }

        if (containsErrors) {
            notifyError(<>The container has been created, but some settings could not be applied. Please check the container and try to update. If the error persists, contact our team at {HELP_DESK_LINK}.</>, "management/containers");
        }
        else {
            notifySuccess("Successfully created container.");
        }
        setAccessibleContainers(currentTenant);
        setLocalLoading(false);
        navigate('/management/containers/list');
    };

    const renderContainerLocation = () => {
        return <>
            <AutoComplete 
                locationPicked={(place) => {
                    getLocation(place);
                    setLocation({lat: place.geometry.location.lat(), lng: place.geometry.location.lng()});
                    setModelState((prev) => ({
                        ...prev,
                        ['location']: {
                            ...prev.location,
                            value: {
                                ...prev.location.value,
                                latitude: place.geometry.location.lat(),
                                longitude: place.geometry.location.lng(),
                            }
                        }
                    }))
                }}
                currentLocation={locationString}
            />
            <div className='location-map-container'>
                {location && <GoogleMapReact
                id='location-map'
                bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, libraries: ['places'] }}
                center={location}
                defaultZoom={17}
                options={{
                    fullscreenControl: false,
                }}
                onClick={ev => {
                    const latlng = {
                    lat: parseFloat(ev.lat),
                    lng: parseFloat(ev.lng),
                    };

                    const geocoder = new google.maps.Geocoder();

                    geocoder
                    .geocode({ location: latlng })
                    .then((response) => {
                        if (response.results[0]) {
                        getLocation(response.results[0]);
                        setLocation(latlng);
                        setModelState((prev) => ({
                            ...prev,
                            ['location']: {
                            ...prev.location,
                            value: {
                                ...prev.location.value,
                                latitude: parseFloat(ev.lat),
                                longitude: parseFloat(ev.lng),
                            }
                            },
                        }));
                        (document.getElementById('location-search')).value = response.results[0].formatted_address;
                        } else {
                        console.log("No results found");
                        }
                    })
                    .catch((error) => {
                        notifyWarning(<>We have encountered an issue. Could you please close the location popup and try again. If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
                        console.error(error);
                    });
                }}
                >
                    <Marker lat={location.lat} lng={location.lng}></Marker>
                </GoogleMapReact>}
            </div>
        </>
    };

    const validateFarmhandInstallation = async () => {
        if (modelState.farmhandId.value === '') return;
        await FarmhandInstallationsApiService.getFarmHandInstallationId(modelState.farmhandId.value)
        .then(async (response) => {
            await FarmhandInstallationsApiService.getContainerForFarmHandInstallationId(response.data.data.id)
            .then((resp) => {
                console.log(response.data.data);
                if (resp.data.data.id == null) {
                    setFarmhandInstallation(response.data.data);
                }
                if (resp.data.data.id != null) {
                    notifyWarning("The farmhand installation is already in use. Please select another farmhand installation and try again.")
                }
            })
            .catch((error) => {
                console.error(error);
            })
        })
        .catch((error) => {
            console.error(error);
            if (error.response.data.code == 404) {
                notifyWarning("The given farmhand installation does not exist. Please select another farmhand installation and try again.")
            }
            if (error.response.data.code === 500) {
                notifyError(<>We have encountered an issue on our side while checking the given farmhand id. No farmhand installation will be added to the container. You can still create a new container and add a farmhand installation later. If the error still exists, please contact {HELP_DESK_LINK}.</>)
            }
        });
    };

    return <>
        {localLoading ? <Loading fullScreen /> : <>
            <div className='create-container-container' >
                <div className='create-container-card-container' >
                    <div className='create-container-card2' >
                        <div className='create-container-header general-information' >General Information</div>
                        <div className='name-input-container' >
                            <div className='name-input-label'><i className='fa-solid fa-signature' ></i> Name</div>
                            <input
                                type='text'
                                name='name'
                                value={modelState.name?.value}
                                onChange={onChange}
                                className={(!modelState.name?.isValid && modelState.name?.touched) ? 'name-input red-border' : 'name-input'}
                            />
                            <p
                                className='name-input-error'
                                style={{ visibility: (!modelState.name?.isValid && modelState.name?.touched) ? 'visible' : 'hidden' }}
                            >
                                Name should have three characters or more.
                            </p>
                        </div>
                        <div className='select-location-container'>
                            <div className='select-location-label' ><i className='fa-solid fa-location-dot' ></i> Location</div>
                            <div className='location-output' >
                                {locationString == '' ? 
                                    (
                                        <p className='location-output-text' >Please specify the exact location of the container.</p>
                                    ) : (
                                        <p className='location-output-text' >{locationString}</p>
                                    )
                                }

                            </div>
                            <button
                                className='select-location-button'
                                onClick={() => setOpenLocation(true)}
                            >
                                <i className='fas fa-pen' ></i>
                            </button>

                        </div>
                        <div className='create-container-header devices' >Devices</div>
                        <div className='scale-container-container' >
                            <div className='scale-label' ><i className='fa-solid fa-scale-balanced' ></i> Scale</div>
                            <div className='scale-placeholder-label' >Auto-generated after pressing confirm.</div>
                        </div>
                        <div className='label-printer-container-container' >
                            <div className='label-printer-label' ><i className='fa-solid fa-print' ></i> Label Printer</div>
                            <div className='label-printer-placeholder-label' >Auto-generated after pressing confirm.</div>
                        </div>
                        <div className='birds-eye-view-camera-container-container' > 
                            <div className='birds-eye-view-camera-label' ><i className='fa-solid fa-camera' ></i> Bird's Eye-View Camera</div>
                            <div className='birds-eye-view-camera-placeholder-label' >Auto-generated after pressing confirm.</div>
                        </div>
                        <div className='create-container-header miscellaneous' >Miscellaneous</div>
                        <div className='farmhand-id-input-container'>
                            <div className='farmhand-id-label' ><i className='fa-solid fa-tractor' ></i> Farmhand ID</div>
                            <input
                                type='text'
                                name='farmhandId'
                                value={modelState.farmhandId?.value}
                                onChange={onChange}
                                onBlur={validateFarmhandInstallation}
                                className={(!modelState.farmhandId?.isValid && modelState.farmhandId?.touched) ? 'farmhand-id-input red-border' : 'farmhand-id-input'}
                            />
                        </div>
                        <div className='storage-advice-root-input-container'>
                            <div className='storage-advice-root-label' ><i className="fas fa-archive"></i> Storage Advice Text for Root Produce</div>
                            <textarea
                                name='storageAdviceRoot'
                                disabled={!selectedHarvestProcedures.some(hp => hp.includes('Root'))}
                                value={modelState.storageAdviceRoot?.value}
                                onChange={onChange}
                                className={(!modelState.storageAdviceRoot?.isValid && modelState.storageAdviceRoot?.touched) ? 'storage-advice-root-input red-border' : 'storage-advice-root-input'}
                            />
                        </div>
                        <div className='storage-advice-cut-input-container'>
                            <div className='storage-advice-cut-label' ><i className="fas fa-archive"></i> Storage Advice Text for Cut Produce</div>
                            <textarea
                                name='storageAdviceCut'
                                disabled={!selectedHarvestProcedures.some(hp => hp.includes('Cut'))}
                                value={modelState.storageAdviceCut?.value}
                                onChange={onChange}
                                className={(!modelState.storageAdviceCut?.isValid && modelState.storageAdviceCut?.touched) ? 'storage-advice-cut-input red-border' : 'storage-advice-cut-input'}
                            />
                        </div>
                        <div className='description-textarea-container' >
                            <div className='description-label' ><i className='fa-regular fa-comment-dots' ></i> Description</div>
                            <textarea 
                                rows={5}
                                name='description'
                                value={modelState.produceDescription?.value}
                                onChange={onChange}
                                className={(!modelState.description.isValid && modelState.description.touched) ? 'description-input red-border' : 'description-input'}
                            />
                        </div>
                        <div className='arlo-camera-ids-container' >
                            <div className='arlo-camera-ids-label' ><i className='fa-solid fa-video' ></i> Arlo Camera IDs</div>
                            <input 
                                type='text'
                                name='arloCameraIds'
                                className={(!modelState.arloCameraIds.isValid && modelState.arloCameraIds.touched) ? 'arlo-camera-input red-border' : 'arlo-camera-input'}
                                value={arloCameraInput}
                                placeholder="Out of service."
                                disabled
                                onChange={handleArloCameraIdInputChange}
                            />
                            <button
                                className='arlo-camera-input-button'
                                onClick={handleAddArloCameraId}
                                disabled
                            >
                                <i className='fa-solid fa-plus' ></i>
                            </button>
                            <div className='arlo-camera-ids-content'>
                                {addedArloCameraIds.map((camera) => (
                                    <div key={camera} className='arlo-camera-ids-content-option' >
                                        <i className="fa-solid fa-x remove-button" onClick={() => handleRemoveArloCameraId(camera)} ></i>
                                        <span>
                                            {camera}    
                                        </span>  
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='harvesting-procedures-container' >
                            <div className='harvesting-procedures-label' id='harvesting-procedures-label' ><i className='fa-solid fa-wheat-awn' ></i> Harvesting Procedures</div>
                            <select
                                aria-labelledby="harvesting-procedures-label" 
                                name='harvestingProcedures'
                                className='select-harvesting-procedures'
                            >
                                {harvestingProcedureTypes.map((type, index) => (
                                    <option key={index} value={type} disabled={false} >
                                        {separateOnCapitalLetters(type)}
                                    </option>
                                ))}
                            </select>
                            <button
                                className='select-harvesting-procedures-button'
                                onClick={handleAddHarvestProcedure}
                            >
                                <i className='fa-solid fa-plus' ></i>
                            </button>
                            <div className='select-harvesting-procedures-content'>
                                {selectedHarvestProcedures.map((type, index) => (
                                    <div key={index} className='select-harvesting-procedures-content-option' >
                                        <i className="fa-solid fa-x remove-button" onClick={() => handleRemoveHarvestProcedure(type)} ></i>  
                                        <span>
                                            {separateOnCapitalLetters(type)}
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
                <StatusBarButton
                    statusSlot={3}
                    label='Abort'
                    clickHandler={() => navigate('/management/containers/list')} icon='fas fa-ban' type='inline' />
                <StatusBarButton
                    className='seed-card-btn'
                    label='Confirm'
                    statusSlot={5}
                    icon='fas fa-check'
                    type='inline'
                    reversed={true}
                    clickHandler={onConfirm}
                    disabled={modelState.name.value.length < 3 ? true : false}
                />
            </div>
            <Modal size='xl' centered isOpen={openLocation} className='modal-config' >
                <ModalBody className='location-card-body' >
                    <div>{renderContainerLocation()}</div>
                </ModalBody>
                <div>
                    <>
                        <Button
                            className='location-card-btn'
                            label='Done'
                            clickHandler={
                                async () => {
                                    setOpenLocation(false);
                                }
                            }
                            disabled={false}
                        />
                    </>
                </div>
            </Modal>
        </>
        }
    </>
}

export default Create;