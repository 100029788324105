import React, { Component, Fragment, useContext } from 'react';
import { Link } from 'react-router-dom';
import { NavItem, NavLink } from 'reactstrap';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import authService from './AuthorizeService';
import Select from 'react-select';
import Avatar from 'react-avatar';
import LoggedInAvatar from '../layout/LoggedInAvatar';
import AuthContext from '../../contexts/auth/AuthContext';
import Button from '../layout/Button';

export const LoginMenu = (props) => {
  const { isAuthenticated, userInformation, signIn, signOut } = useContext(AuthContext);
  const authenticatedView = () => (
    <Fragment>       
      {/* <NavItem>
        {/* <NavLink tag={Link} className='text-dark' to={logoutPath}>
          Logout
        </NavLink> */}
        {/* <button onClick={signOut}>Logout</button> */}
      {/* </NavItem> */}
      {userInformation && (
        <LoggedInAvatar givenName={userInformation?.firstName} familyName={userInformation?.lastName} signOut={signOut} />
      )}     
    </Fragment>
  );

  const unauthenticatedView = () => (
    <Fragment>
      {/* <NavItem>
        <Button label={'Login'} clickHandler={signIn} disabled={false} />
        {/* <button onClick={signIn}>Login</button> */}
      {/* </NavItem> */} 
    </Fragment>
  );

  return isAuthenticated ? authenticatedView() : unauthenticatedView();
};
