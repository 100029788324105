import PropTypes from 'prop-types';
import React from 'react';

const Overlay = ({ open, closeHandler }) => {
  return (
      <div
        className='overlay'
        style={{
          display: open ? 'block' : 'none',
        }}
        onClick={closeHandler}
      >
        <button className='close-btn'><i className='fas fa-times'></i></button>
      </div>
  );
};

Overlay.propTypes = {
    open: PropTypes.bool.isRequired,
    closeHandler: PropTypes.func.isRequired
}

export default Overlay;
