import React, { Fragment, useContext, useEffect, useState } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';
import StatusBarButton from '../../layout/StatusBarButton';
import Loading from '../../ui-elements/Loading';
import Tray from '../Tray';
import SeedSlotConfirm from './SeedSlotConfirm';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../layout/BackButton';

const ConfirmSeedingSelection = ({ backwardTo }) => {
  const {
    activeProcessId,
    occupancyArray,
    loadOccupancy,
    additionalProcessInformation,
    continueProcess,
  } = useContext(ProcessContext);

  const { loading } = useContext(UIContext);
  const navigate = useNavigate();
  const [confirmed, setConfirmed] = useState(false);

  const load = async () => {
    await loadOccupancy(additionalProcessInformation.destinationEntity);
  };

  useEffect(() => {
    load();
  }, []);

  const confirmSelection = async () => {
    
    try {
      setConfirmed(true);
      
      await continueProcess(activeProcessId, true);
      navigate('/');

    } catch (error) {
      console.error(error);
    }
  };

  const reversedArray = [...occupancyArray].reverse();

  return loading || confirmed ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      <BackButton disabled={false} navigateTo={backwardTo}/>
      {!confirmed && (
        <Tray>
          {occupancyArray &&
            occupancyArray.length > 0 &&
            reversedArray.map((unit) => (
              <SeedSlotConfirm
                key={unit.index}
                displayName={unit.display}
                index={unit.index}
                seedType={additionalProcessInformation.seedType}
                isMultiharvestable={additionalProcessInformation.isMultiharvestable}
              />
            ))}
        </Tray>
      )}

      <StatusBarButton label='Confirm' icon='fas fa-check' reversed={true} clickHandler={confirmSelection} statusSlot={5} />
    </Fragment>
  );
};

export default ConfirmSeedingSelection;
