import { Route, Routes } from "react-router-dom";
import Create from '../../components/management/produceTypes/Create';
import Detail from '../../components/management/produceTypes/Detail';
import Edit from '../../components/management/produceTypes/Edit';
import ProduceTypeList from '../../components/management/produceTypes/List';

const ProduceTypeRouting = () => {
    return(
        <div id="produce-type-management" className="produce-type-management-container process-container">
            <Routes>
                <Route path={'/list'} element={<ProduceTypeList />} />
                <Route path={'/create'} element={<Create />} />
                <Route path={'/:produceTypeId/edit'} element={<Edit />} />
                <Route path={'/:produceTypeId/details'} element={<Detail />} />
            </Routes>
        </div>
    );
};

export default ProduceTypeRouting;