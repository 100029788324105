import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../../contexts/process/ProcessContext';
import Button from '../../layout/Button';

const ReportingScopeOption = () => {
  const { additionalProcessInformation, continueProcess, activeProcessId } =
    useContext(ProcessContext);

  const options = additionalProcessInformation.reportScopes;
  const navigate = useNavigate();

  const handleClick = async (option) => {
    
    try {
      let optionAsBool = option === 'On Plants' ? '0' : '1';
      await continueProcess(activeProcessId, optionAsBool);
  
      const target = (() => {
        switch (option) {
          case 'On Panel':
            return 'panel';
          case 'On Tray':
            return 'tray';
          default:
            return null;
        }
      })();
  
      if (!target) {
        const source = additionalProcessInformation?.sourceEntity
          ?.toLowerCase()
          .startsWith('p')
          ? 'panel'
          : 'tray';
  
        navigate('/reporting/' + source + '/units');
        
      } else {
        navigate('/reporting/submit-report');
      }

    } catch (error) {
      console.error(error);
    }
  };

  const getIcon = (option) => {
    switch (option) {
      case 'On Plants':
        return 'fas fa-seedling';
      case 'On Panel':
        return 'fas fa-grip-lines-vertical';
      case 'On Tray':
      default:
        return 'fas fa-th';
    }
  };

  return (
    <div className='scope-option-container'>
      {options.map((option) => (
        <Button
          className='reporting-options'
          icon={getIcon(option)}
          key={option}
          label={option}
          clickHandler={() => handleClick(option)}
        />
      ))}
    </div>
  );
};

export default ReportingScopeOption;
