import React, { Fragment, useContext, useEffect } from 'react';
import AuthContext from '../../contexts/auth/AuthContext';
import UIContext from '../../contexts/ui/UIContext';
import Button from '../layout/Button';


const LoginScreen = () => {
  const { signIn } = useContext(AuthContext);
  const { setInfo } = useContext(UIContext);

  useEffect(() => {
    setInfo('Please log in');
  }, []);  

  return (
    <Fragment>
      <div className='self-center image'>
        <h2>Welcome to FarmerApp</h2>
        <p>To start using the app, please login with your email address and password.</p>
        <div>
          <Button className='mt-8' label={'Login'} clickHandler={signIn} disabled={false} />
        </div>
      </div>
    </Fragment>
  );
};

export default LoginScreen;
