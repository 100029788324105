import React, { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AuthContext from "../../../contexts/auth/AuthContext";
import HarvestingProceduresContext from "../../../contexts/management/harvestingProcedures/HarvestingProceduresContext";
import Button from "../../layout/Button";

const ConfirmEditModal = ({ show = false, onConfirm, onCancel }) => {

    const handleCancel = () => {
        onCancel();
    }

    const handleConfirm = () => {
        onConfirm();
    }

    return (
        <Modal isOpen={show} className='modal-config confirm-edit-modal'>
            <div className='confirm-edit-modal-container'>
                <div className='confirm-edit-modal-header-container'>
                    <div className="confirm-edit-modal-header">DO YOU REALLY WANT TO EDIT?</div>
                </div>
                <div className="confirm-edit-modal-body">
                    Editing this produce type will affect ALL produces, including those that have already left the container or are in the hands of consumers.
                    <br />
                    <br />
                    Do you really want to continue?
                </div>
                <Button label='No' className="confirm-edit-modal-cancel-button" clickHandler={handleCancel} />
                <Button label='Yes' className="confirm-edit-modal-confirm-button" clickHandler={handleConfirm} />
            </div>
        </Modal>
    );
}

export default ConfirmEditModal;