import React, { useContext } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';

const SeedSlotConfirm = ({ displayName, index, seedType, isMultiharvestable }) => {
  const { additionalProcessInformation } = useContext(ProcessContext);

  const isOccupied = displayName !== 'available';
  const isSelected = (additionalProcessInformation.destinationSelection.includes(index)) && !isOccupied;

  const row = Math.floor(index / 10);
  const col = index - (row * 10);
  const trayGridLabel = `${row + 1}/${col + 1}`;
  const trayName = displayName == 'available' ? '' : displayName

  return (
    <div
      className='slot-unit seed-slot-confirm'
      data-selected={isSelected}
      data-occupied={isOccupied}
    >
      <i className='fas fa-seedling plant-icon'></i>
      <span className='tray-name'>{isSelected ? seedType : trayName}</span>
      <span className="tray-grid-label">{trayGridLabel}</span>
    </div>
  );
};

export default SeedSlotConfirm;
