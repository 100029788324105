import React from 'react';

const Panel = ({ alignment = 'vertical', children }) => {

  return (
    <div className='panel' data-alignment={alignment}>
      {children}
    </div>
  );
};

export default Panel;
