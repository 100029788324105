import React, { useState, useContext } from 'react';
import ConfirmDeletionModal from './ConfirmDeletionModal';
import AuthContext from '../../../contexts/auth/AuthContext';
import ProcessContext from '../../../contexts/process/ProcessContext';
import TenantsApiService from '../../../services/apiServices/TenantsApiService';
import { TENANT_ADMIN, OPERATOR, MARKETING } from '../../../constants/roles/roles';

const Card = ({tenantUser, handleDelete}) => {
    const [editEnabled, setEditEnabled] = useState(false);
    const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
    const { currentTenant } = useContext(AuthContext);
    const { notifySuccess, notifyError } = useContext(ProcessContext);

    let initialUserState = {
        id: tenantUser.id,
        familyName: tenantUser.familyName,
        givenName: tenantUser.givenName,
        email: tenantUser.email,
        roles: tenantUser.roles
    };

    const [user, setUser] = useState(initialUserState);
    const [userCancel, setUserCancel] = useState(initialUserState);

    const changeRoles = (role) => {
        if (user.roles.includes(role)) {
            let newRoles = user.roles.filter(e => e !== role)
            setUser({
                ...user,
                roles: newRoles
            });

            return;
        }

        let newRoles = [...user.roles, role];
        setUser({
            ...user,
            roles: newRoles
        });
    };

    const toggleEdit = () => {
        if (editEnabled) {
            setUser(userCancel);
        }
        setEditEnabled(!editEnabled);
    };

    const onCancelConfirmDeletion = () => {
        setShowConfirmDeletionModal(false);
    };

    const confirmDeletion = () => {
        handleDelete(user.id);   
    };

    const updateUserRoles = () => {
        TenantsApiService.updateUserRolesForTenant(currentTenant.tenantId, user.id, user.roles).then(() => {
            notifySuccess("Successfully updated user roles.");
            setUserCancel({
                ...userCancel,
                roles: user.roles
            });
        })
            .catch((e) => {
                if (e?.statusCode === 500) {
                    notifyError("We have encountered an issue on our side while updating a produce type. Please contact support team support.farmer@diecityfarm.de.");
                }
                else if (e?.statusCode === 404) {
                    notifyError("We have encountered an issue on our side while updating user roles. Please log out, log back in and try again. If this error persists please contact support team support.farmer@diecityfarm.de.");
                }
                else {
                    notifyError("We have encountered an issue on our side while updating user roles. Please contact support team support.farmer@diecityfarm.de. No detailed error information possible.");
                }
                setUser(userCancel);
                console.log("Error while updating user roles: ", e);
            })
            .finally(() => {
                setEditEnabled(!editEnabled);
            });
    };

    return <>
        <ConfirmDeletionModal show={showConfirmDeletionModal} email={user.email} onCancel={onCancelConfirmDeletion} onConfirm={confirmDeletion} />
        <div className="tenant-user-management-card">
            <div className="tenant-user-management-card-header">
                <div className="tenant-user-management-card-name">
                    {user.familyName && user.givenName ? user.familyName + ", " + user.givenName : 'No name provided yet'}
                </div>
            </div>
            <div className="tenant-user-management-card-email tenant-user-management-card-label">
                Email
            </div>
            <div className="tenant-user-management-card-email tenant-user-management-card-value">
                {user.email}
            </div>
            <div className="tenant-user-management-card-roles tenant-user-management-card-label">
                Roles
            </div>
            {!editEnabled ?
                <div>
                    <input type="checkbox" checked={user.roles?.includes(OPERATOR)} disabled className="tenant-user-management-card-operator tenant-user-management-card-checkbox-value-disabled"></input>
                    <div className="tenant-user-management-card-operator tenant-user-management-card-checkbox-label">
                        Operator
                    </div>
                    <input type="checkbox" checked={user.roles?.includes(MARKETING)} disabled className="tenant-user-management-card-marketing tenant-user-management-card-checkbox-value-disabled"></input>
                    <div className="tenant-user-management-card-marketing tenant-user-management-card-checkbox-label">
                        Marketing
                    </div>
                    <input type="checkbox" checked={user.roles?.includes(TENANT_ADMIN)} disabled className="tenant-user-management-card-tenant-admin tenant-user-management-card-checkbox-value-disabled"></input>
                    <div className="tenant-user-management-card-tenant-admin tenant-user-management-card-checkbox-label">
                        Tenant Admin
                    </div>
                </div>
                :
                <div>
                    <input type="checkbox" onChange={() => changeRoles(OPERATOR)} checked={user.roles?.includes(OPERATOR)} className="tenant-user-management-card-operator tenant-user-management-card-checkbox-value-enabled"></input>
                    <div className="tenant-user-management-card-operator tenant-user-management-card-checkbox-label">
                        Operator
                    </div>
                    <input type="checkbox" onChange={() => changeRoles(MARKETING)} checked={user.roles?.includes(MARKETING)} className="tenant-user-management-card-marketing tenant-user-management-card-checkbox-value-enabled"></input>
                    <div className="tenant-user-management-card-marketing tenant-user-management-card-checkbox-label">
                        Marketing
                    </div>
                    <input type="checkbox" onChange={() => changeRoles(TENANT_ADMIN)} checked={user.roles?.includes(TENANT_ADMIN)} className="tenant-user-management-card-tenant-admin tenant-user-management-card-checkbox-value-enabled"></input>
                    <div className="tenant-user-management-card-tenant-admin tenant-user-management-card-checkbox-label">
                        Tenant Admin
                    </div>
                </div>}
            {!editEnabled ?
                <div>
                    <div className="tenant-user-management-card-left tenant-user-management-card-button" onClick={() => setShowConfirmDeletionModal(true)}>
                        <div className="tenant-user-management-card-left tenant-user-management-card-button-label">
                            Delete
                        </div>
                    </div>
                    <div className="tenant-user-management-card-right tenant-user-management-card-button" onClick={toggleEdit}>
                        <div className="tenant-user-management-card-right tenant-user-management-card-button-label">
                            Edit
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className="tenant-user-management-card-left tenant-user-management-card-button" onClick={toggleEdit}>
                        <div className="tenant-user-management-card-left tenant-user-management-card-button-label">
                            Cancel
                        </div>
                    </div>
                    <div className="tenant-user-management-card-right tenant-user-management-card-button" onClick={updateUserRoles}>
                        <div className="tenant-user-management-card-right tenant-user-management-card-button-label">
                            Save
                        </div>
                    </div>
                </div>}
        </div >
    </>
}

export default Card;