import React, { useContext } from 'react'

import UIContext from  '../contexts/ui/UIContext';

const InfoPanel = () => {

    const { info } = useContext(UIContext)

    return (
        <div id='info-panel'>
            {info}
        </div>
    )
}

export default InfoPanel
