export const PROCESS_ROUTES = {
    Discarding: '/discarding',
    EndOfLife: '/discarding',
    Harvesting: '/harvesting',
    Photographing: '/photo',
    Reporting: '/reporting',
    Repositioning: '/repositioning',
    Seeding: '/seeding',
    Transplanting: '/transplanting',
    Weighing: '/weighing',
  };