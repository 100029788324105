import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router-dom';

import { Card } from 'reactstrap';
import AuthContext from '../../contexts/auth/AuthContext';
import UIContext from '../../contexts/ui/UIContext';
import StatusBarButton from '../layout/StatusBarButton';
import SeedVariantTypeEditor from './SeedVariantTypeEditor';

const SeedVarietyTypeList = () => {
    const params = useParams();
    const { seedTypeId } = params;
    const navigate = useNavigate();

    const { setInfo } = useContext(UIContext);
    const { accessibleSeedTypes } = useContext(AuthContext);

    const [addingNew, setAddingNew] = useState(false);
    const [seedVariantTypes, setSeedVariantTypes] = useState([]);
    const parentSeed = accessibleSeedTypes?.filter((seedType) => !seedType.outdated).find((seedType) => seedType?.id == seedTypeId);

    const emptySeedVariantType = {
        seedTypeId: parentSeed?.id,
        count: 1000,
        lot: '',
        manufacturer: '',
        id: '',
        tgw: 0.001,
        packageWeight: 0.001
    };

    useEffect(() => {
        let x = accessibleSeedTypes?.filter((seedType) => !seedType.outdated).find((seedType) => seedType.id == seedTypeId)?.seedVariantTypes;
        if (x?.length < 1) {
            setSeedVariantTypes([]);
        } else {
            setSeedVariantTypes(x);
        }
    }, [accessibleSeedTypes]);

    useEffect(() => {
        let seedType = accessibleSeedTypes?.filter((seedType) => !seedType.outdated).find((seedType) => seedType.id == seedTypeId);
        setInfo(`No̱ ${seedType?.number} ${seedType?.name}`);
    }, [accessibleSeedTypes, seedVariantTypes]);

    const openNewSeedVariantTypesForm = () => {        
        setAddingNew(true);
    };

    const closeNewSeedVariantTypesForm = () => {
        setAddingNew(false);
    };

    return (
        <div className='seed-list-overview'>
            <Card className='seed-card' onClick={openNewSeedVariantTypesForm}>
                <h1 className='add-seed-symbol'>
                    <i className='fas fa-plus'></i>
                </h1>
            </Card>
            {addingNew && (
                <SeedVariantTypeEditor
                    seedType={parentSeed}
                    seedTypeId={parentSeed?.id}
                    seedVariantType={emptySeedVariantType}
                    outerReset={closeNewSeedVariantTypesForm}
                    defaultEditOn={true}
                />
            )}
            {seedVariantTypes?.sort((a, b) => {
                    var manufacturerA = a.manufacturer.toUpperCase();
                    var manufacturerB = b.manufacturer.toUpperCase();
                    if (manufacturerA < manufacturerB) {
                        return -1;
                    }
                    if (manufacturerA > manufacturerB) {
                        return 1;
                    }
                    return 0;
                }).map((seedVariantType, index) => (
                <SeedVariantTypeEditor key={seedVariantType.id} seedVariantType={seedVariantType} seedTypeId={seedVariantType.seedTypeId} />
            ))}
            <StatusBarButton
                statusSlot={1}
                label='Back'
                type='inline'
                icon='fas fa-chevron-left'
                clickHandler={() => navigate('/management/seed-types/list')}
            />
        </div>
    );
};

export default SeedVarietyTypeList;
