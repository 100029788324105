import React, { useEffect, useReducer } from 'react';
import { REMOVE_SEQUENCE, RESET, SET_SELECTION_END, SET_SELECTION_START } from '../../types/contextTypes';
import SelectableContext from './SelectableContext';
import selectableReducer from './SelectableReducer';
import SelectionSidebar from '../../../components/layout/Sidebar/SelectionSidebar';
function SelectableState({ availableItems, children }) {
  const initialState = {
    selection: [],
    lastSelectedNumber: null,
    isSelecting: false,
  };

  

  useEffect(() => {
    if (!availableItems) {
      throw new Error(
        "If you use the Selectable Context you have to provide the SelectableState with a prop 'availableItems' which is an array of numbers."
      );
    }
  }, []);

  const [state, dispatch] = useReducer(selectableReducer, initialState);

  /**
   * Handles the selection number sequences via handling the isSelecting state and filtering out the necessary elements from the availableItems prop.
   * @param {number} num A number. Just a simple number.
   * @returns void
   */
  const handleSelection = (num) => {

    if (!state.isSelecting) {
      dispatch({
        type: SET_SELECTION_START,
        payload: num,
      });

      return;
    }
    const startSequenceAt =
      num > state.lastSelectedNumber ? state.lastSelectedNumber : num;
    const stopSequenceAt =
      num > state.lastSelectedNumber ? num : state.lastSelectedNumber;

    const sequence = _createSequence(startSequenceAt, stopSequenceAt);

    if (
      state.selection.includes(num) &&
      state.selection.includes(state.lastSelectedNumber)
    ) {
      return handleRemoveItems(sequence);
    }

    dispatch({
      type: SET_SELECTION_END,
      payload: sequence,
    });
  };

  const handleRemoveItems = (sequence) => {
    dispatch({
      type: REMOVE_SEQUENCE,
      payload: sequence,
    });
  };

  const determineIfSelected = (num) => {
    return num === state.lastSelectedNumber || state.selection.includes(num);
  };

  const determineIfBeingDeselected = (num) => {
    return state.isSelecting && state.selection.includes(num) && state.lastSelectedNumber === num
  };

  const _createSequence = (startSequenceAt, stopSequenceAt) => {
    const sequence = [];
    for (let index = startSequenceAt; index <= stopSequenceAt; index++) {
      if (availableItems.includes(index)) sequence.push(index);
    }

    return sequence;
  };

  const selectAll = () => {
    dispatch({
      type: SET_SELECTION_END,
      payload: availableItems,
    });
  }

  /**
   * Empties the selection array
   */
  const reset = () => {
    dispatch({
      type: RESET,
    });
  };

  return (
    <SelectableContext.Provider
      value={{
        ...state,
        determineIfSelected,
        determineIfBeingDeselected,
        handleSelection,
        reset,
        selectAll,
        Sidebar: <SelectionSidebar selectAll={selectAll} reset={reset} />,
      }}
    >
      {children}
    </SelectableContext.Provider>
  );
}

export default SelectableState;
