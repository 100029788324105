import axiosClient from "../../http-common";

/**
 * The harvesting procedure api service.
 */
class HarvestingProceduresApiService {
  
  /**
   * 
   * @param {string} tenantId - The tenant id.
   * @param {string} containerId - The container id.
   * @returns {object} - The response of the api call.
   */
  getManyHarvestingProcedures(tenantId, containerId) {
    return axiosClient.get(`/api/v1/Tenants/${tenantId}/Containers/${containerId}/HarvestingProcedures`);
  }
  /**
   * 
   * @param {string} tenantId - The tenant id.
   * @param {string} containerId - The container id.
   * @param {string} harvestingProcedureId - The harvesting procedure id.
   * @returns {object} - The response of the api call.
   */
  getHarvestingProcedureByHarvestingProcedureId(tenantId, containerId, harvestingProcedureId) {
    return axiosClient.get(`/api/v1/Tenants/${tenantId}/Containers/${containerId}/HarvestingProcedures/${harvestingProcedureId}`);
  }

  /**
   * Create a new harvesting procedure.
   * 
   * @param {string} tenantId - The tenant id.
   * @param {string} containerId - The container id.
   * @param {object} procedureData - The harvesting procedure data.
   * @returns {Promise} - The response of the API call.
   */
  createHarvestingProcedure(tenantId, containerId, harvestingProcedure) {
    if (!tenantId || !containerId || !harvestingProcedure) return;

    return axiosClient.post(`/api/v1/Tenants/${tenantId}/Containers/${containerId}/HarvestingProcedures`, harvestingProcedure);
  }

  /**
   * Delete a harvesting procedure by its id.
   * 
   * @param {string} tenantId - The tenant id.
   * @param {string} containerId - The container id.
   * @param {string} harvestingProcedureId - The harvesting procedure id.
   * @returns {Promise} - The response of the API call.
   */
  deleteHarvestingProcedure(tenantId, containerId, harvestingProcedureId) {
    if (!tenantId || !containerId || !harvestingProcedureId) return;

    return axiosClient.delete(`/api/v1/Tenants/${tenantId}/Containers/${containerId}/HarvestingProcedures/${harvestingProcedureId}`);
  }

  /**
   * Update an existing harvesting procedure.
   * 
   * @param {string} tenantId - The tenant id.
   * @param {string} containerId - The container id.
   * @param {string} harvestingProcedureId - The harvesting procedure id.
   * @param {object} procedureData - The updated harvesting procedure data.
   * @returns {Promise} - The response of the API call.
   */
  updateHarvestingProcedure(tenantId, containerId, harvestingProcedure) {
    if (!tenantId || !containerId || !harvestingProcedure) return;
    return axiosClient.put(`/api/v1/Tenants/${tenantId}/Containers/${containerId}/HarvestingProcedures/${harvestingProcedure.id}`, harvestingProcedure);
  }
}

export default new HarvestingProceduresApiService();