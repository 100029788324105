import InputField from "./InputField";

/**
 * @component InputField Component - A reusable React component for rendering a labeled input field with error messaging.
 * @param {string} labelText The label text for the input field.
 * @param {string} inputName The name attribute for the input field.
 * @param {object} inputProp An object containing properties related to the input field.
 * @property {string | number} inputProp.value Current value of the input field.
 * @property {boolean} inputProp.isValid Indicates whether the input is considered valid.
 * @property {boolean} inputProp.touched Indicates whether the input field has been interacted with.
 * @param {string} inputType The type attribute for the input field (e.g., "text", "password", etc.).
 * @param {function} onChange Callback function to handle the onChange event of the input field.
 * @param {boolean} disabled Indicates whether the input field is disabled.
 * @param {string} errorMessage The error message to be displayed when the input is invalid.
 * @returns {JSX.Element} The rendered InputField component.
 */
const LabeledInputField = ({labelText, inputName, value, isValid, touched, inputType, onChange, onBlur = () => {}, placeholder, disabled, errorMessage, step}) => {

    return(
        <>
            <label className='input-label' >{labelText}</label>
            <InputField
                inputName={inputName}
                value={value}
                touched={touched}
                isValid={isValid}
                inputType={inputType}
                onChange={onChange}
                onBlur={onBlur}
                placeholder={placeholder}
                disabled={disabled}
                errorMessage={errorMessage}
                step={step}
            />
        </>
    )
};

export default LabeledInputField;