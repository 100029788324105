// #region Libraries
import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useNavigate } from 'react-router';
import heic2any from 'heic2any';
// #endregion

// #region Components
import Loading from '../../ui-elements/Loading';
import PrintLabelModal from './PrintLabelModal';
import SeedTypeSelection from './seedTypeSelection/List';
import StatusBarButton from '../../layout/StatusBarButton';
// #endregion

// #region Contexts
import AuthContext from '../../../contexts/auth/AuthContext';
import ProduceTypeContext from '../../../contexts/management/produceTypes/ProduceTypeContext';
import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';
// #endregion

// #region Services
import LabelPrinterTemplateApiService from '../../../services/apiServices/LabelPrinterTemplateApiService';
// #endregion

// #region Models
import ProduceTypeRequest from '../../../models/ApiRequestModels/ProduceTypesController/AddProduceTypeRequest';
// #endregion

// #region Utilities
import {isValidEAN13GTIN} from '../../../utility/Guard';
import Button from '../../layout/Button';
import { HELP_DESK_LINK } from '../../../constants/helpDesk';
// #endregion

/**
  * `Create` is a React component used to facilitate the creation of a new produce type.
 * It provides fields for entering details like marketing name, classification, produce number,
 * GTIN, appetizer text, produce description, and more. It also allows users to upload an image
 * for the produce and select a seed type from a list.
 * 
 * The component uses various contexts to fetch and manage data, including `AuthContext`, 
 * `ProduceTypeContext`, `ProcessContext`, and `UIContext`.
 * 
 * @component
 * @example
 * return <Create />
 */
const Create = () => {

  // #region Context properties
  const { setInfo } = useContext(UIContext);
  const { currentTenant, accessibleSeedTypes, accessibleTemplates } =
    useContext(AuthContext);
  const { addProduceType, getManyProduceTypes, loading } = useContext(ProduceTypeContext);
  const { notifyError, notifySuccess } = useContext(ProcessContext);
  // #endregion

  // #region Initial states
  const initialProduceTypeModelState = {
    tenantId: currentTenant.tenantId,
    marketingName: {
      value: '',
      touched: false,
      validate: function (text) {
        return text?.length >= 3;
      },
      isValid: false,
      placeholder: 'Marketing Name',
      label: 'Marketing Name'
    },
    produceNumber: {
      value: '',
      touched: false,
      validate: function (text) {
        if (text?.length < 1) {
          this.error = "Produce Number is mandatory.";
          return false;
        }
        this.error = '';
        return true;
      },
      isValid: false,
      placeholder: '',
      error: ''
    },
    classification: {
      value: '',
      touched: false,
      validate: function (text) {
        return text.length >= 3;
      },
      isValid: false,
      placeholder: '',
    },
    gtin: {
      value: '',
      touched: false,
      validate: function (gtin) {
        if (gtin) {
          return (isValidEAN13GTIN(gtin) && gtin.length === 13) || gtin === '';
        }

        return true;
      },
      isValid: true,
      placeholder: '',
    },
    appetizerText: {
      value: '',
      touched: false,
      validate: function (text) {
        return true;
      },
      isValid: true,
      placeholder: '',
    },
    produceDescription: {
      value: '',
      touched: false,
      validate: function (text) {
        return true;
      },
      isValid: true,
      placeholder: '',
    },
    labelPrinterTemplateId: {
      value: '',
      touched: false,
      validate: function (id) {
        return id !== undefined && id !== null && id
      },
      isValid: false,
      placeholder: '',
    },
  };
  const initialSelectedSeedTypeState = {
    seedType: null,
    touched: false
  };
  // #endregion

  // #region Variables
  const activeTemplates = accessibleTemplates.filter(t => t.isActive);
  // #region useStates
  const [localLoading, setLocalLoading] = useState(false);
  const [modelState, setModelState] = useState(initialProduceTypeModelState);
  const [photoData, setPhotoData] = useState(null);
  const [selectedSeedType, setSelectedSeedType] = useState(initialSelectedSeedTypeState);
  const [showSeedTypeError, setShowSeedTypeError] = useState(false);
  const [showPrintLabelModal, setShowPrintLabelModal] = useState(false);
  const [showSeedTypeSelection, setShowSeedTypeSelection] = useState(false);
  // #endregion

  // #region useEffects
  useEffect(() => {
    setInfo('Add New Produce Type');
  }, []);
  // #endregion

  // #region Other hooks
  const navigate = useNavigate();
  const inputRef = useRef(null);
  // #endregion

  // #region Functions

  /**
   * Clicks on the file upload elements with the id 'produce-image-upload'.
   */
  const clickFileInput = (e) => {
    e.stopPropagation();
    let input = document.getElementById('produce-image-upload');
    input?.click();
  };

  /**
   * 
   * @param {string} file - The file name of the uploaded image. 
   * @returns {boolean} - Indicating, whether the picture if of a format contained in validImageTypes. 
   */
  const isPicture = (file) => {
    let fileName = file;
    const validImageTypes = ['.jpg', '.jpeg', 'png', '.heic', '.gif', '.svg', '.bmp', '.webp', '.tif', '.tiff'];

    for (let i = 0; i < validImageTypes.length; i++) {
      if (fileName?.toLowerCase().endsWith(validImageTypes[i])) {
        return true;
      }
    }
    return false;
  };

  /**
   * 
   * @returns {boolean} - Are all values in model state and selected seed types valid?
   */
  const modelStateIsValid = () => {
    if (!selectedSeedType) return false;

    const keys = Object.keys(modelState);
    const returner = keys.every(key => {
      // Check if the property has an isValid attribute
      if (modelState[key].hasOwnProperty('isValid')) {
        return modelState[key].isValid;
      }
      // If the property doesn't have an isValid attribute, consider it as valid
      return true;
    });

    // Check if every key has a valid property set to true
    return keys.every(key => {
      // Check if the property has an isValid attribute
      if (modelState[key].hasOwnProperty('isValid')) {
        return modelState[key].isValid;
      }
      // If the property doesn't have an isValid attribute, consider it as valid
      return true;
    });
  }

  /**
   * Shows the seed type selection component.
   */
  const navigateToSeedTypeSelection = () => {
    setShowSeedTypeSelection(true);
  }

  /**
   * Shows the print label modal.
   */
  const onCancelPrintLabel = () => {
    setShowPrintLabelModal(false);
  }

  /**
   * Determines which model state properties to update on a change.
   * @param {event} event - The React event for the onChange event.
   */
  const onChange = (event) => {
    event.persist();
    const key = event.currentTarget.name;
    const value = event.currentTarget.value;
    setModelState((prev) => ({
      ...prev,
      [key]: {
        ...modelState[key],
        value,
        isValid: modelState[key].validate(value),
        touched: true,
      },
    }));
  };

  /**
   * When the user clicks on retake, photo data is erased.
   */
  const onClickRetake = () => {
    setPhotoData(null);
  }

  /**
   * Check's the uploaded picture's format, if it is heic it will be converted to jpg.
   * @param {event} event - The React file upload event.
   */
  const onPictureUpload = async (event) => {
    try {
      let img = event.target.files[0];
      setLocalLoading(true);

      // Firstly, let's make sure the file is a jpg or heic-picture.
      if (!isPicture(img.name)) {
        throw new Error('Image is in an unknow format.');
      }

      // If it's heic, let's remember that for later.
      let isOtherFormat = img.name.toLowerCase().endsWith('.heic');
      let data = await readImage(img);

      if (isOtherFormat) {
        let blob = new Blob([img], { type: 'image/heic' });
        await heic2any({ blob, toType: 'image/jpeg', quality: 1 }).then((conversionResult) => {
          blob = conversionResult;
        });

        blob.lastModifiedDate = new Date();
        blob.name = img.name;
        data = await readImageAsBase64(blob);
        setLocalLoading(false);
      } else {
        data = await readImageAsBase64(img);
      }

      setPhotoData(data);
    } catch (error) {
      notifyError("The image you tried to upload has the wrong type. Please provide another image and try again.");
    } finally {
      setLocalLoading(false);
    }
  };

  /**
   * The onPrintLabel callback.
   * Opens the modal to enter additional data to be able to print a test label.
   */
  const onPrintLabel = async () => {
    setShowPrintLabelModal(true);
  }

  /**
   * The onSave callback.
   * Validates all data, forms and executes the create produce type api request and handles error notifications.
   */
  const onSave = async () => {
    if (!selectedSeedType.seedType) setShowSeedTypeError(true);
    for (let key in modelState) {
      if (modelState[key].hasOwnProperty('touched')) {
        modelState[key].touched = true;
      }
    }

    if (modelStateIsValid()) {
      const addProduceTypeRequest = new ProduceTypeRequest(currentTenant, selectedSeedType, modelState, photoData);

      try {
        await addProduceType(addProduceTypeRequest);
        notifySuccess("Successfully added new produce type.");
        getManyProduceTypes();
        navigate('/management/produce-types/list');
      }
      catch (e) {
        if (e?.failingPropertyName === 'tenantId' || e?.failingPropertyName === 'labelPrinterTemplateId' || e?.statusCode === 500) {
          notifyError("We have encountered an issue on our side while creating a new produce type. Please contact support team support.farmer@diecityfarm.de.");
        }
        else if (e?.statusCode === 500) {
          notifyError("We have encountered an issue on our side while creating a new produce type. Please contact support team support.farmer@diecityfarm.de.");
        }
        else if (e?.internalStatusCode === 409) {
          notifyError("A produce type for this seed type already exists. Please pick a different seed type. If you think this seed type should not already have a produce type attached, please contact support.farmer@diecityfarm.de")
        }
        else if (e?.failingPropertyName === 'gtin') {
          notifyError("The gtin you have entered seems invalid. Please enter it again and take special care not to have any additional whitespaces in the input field. However, please report this behaviour to support.farmer@diecityfarm.de as this should have been prevented in the first place.");
        }
        else {
          notifyError("We have encountered an issue on our side while creating a new produce type. Please contact support team support.farmer@diecityfarm.de. No detailed error information possible.");
        }
        console.log("Error while adding a new produce type: ", e);
      }
    }
  }

  /**
   * The onSelectSeedType callback.
   * Updates state and ui after a seed type has been selected.
   * @param {string} seedTypeId - The Guid of the selected seed type.
   */
  const onSelectSeedType = (seedTypeId) => {

    const newSelectedSeedTypeState = {
      seedType: accessibleSeedTypes.find(seedType => seedType.id === seedTypeId),
      touched: true
    }

    setSelectedSeedType(newSelectedSeedTypeState);
    setShowSeedTypeError(false);
    setShowSeedTypeSelection(false);
  }

  /**
   * Prints the selected label on the printer in the selected container, assuming the selected harvesting procedure.
   * @param {object} container - The container in which the label printer is located that the label shall be printed on.
   * @param {object} harvestingProcedure - The harvesting procedure whose storage advice to apply to the label.
   */
  const printLabel = useCallback((container, harvestingProcedure) => {
    const labelPrintingRequest = {
      labelPrinterTemplateId: modelState?.labelPrinterTemplateId?.value,
      printData: {
        produceBusinessKeyHash: 'testprint',
        produceHarvestingDate: new Date(),
        produceWeight: 0.00,
        produceTypeClassification: modelState.classification?.value,
        produceTypeGtin: modelState.gtin?.value ?? null,
        produceTypeMarketingName: modelState.marketingName?.value,
        produceTypeNumber: modelState.produceNumber?.value,
        produceStorageAdvice: harvestingProcedure?.storageAdvice
      }
    }

    LabelPrinterTemplateApiService.printLabel(currentTenant?.tenantId, container?.id, labelPrintingRequest)
      .then(() => {
        notifySuccess("Printed label.");
      })
      .catch((e) => {
        notifyError(<>We have encountered a problem when printing. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
        console.error("Error while printing a test label: ", e);
      });

  });

  /**
   * Reads the actual image data from the blob.
   * @param {blob} img - The image as uploaded by the user
   * @returns {Promise} - Whether the image could be read.
   */
  const readImage = (img) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(img);
    });
  };

  /**
   * Reads the image as base 64 string.
   * @param {blob} img 
   * @returns 
   */
  const readImageAsBase64 = (img) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(img);
    });
  };
  // #endregion

  /**
   * Determines which ui elements to render.
   * @returns {import('react').FC} - The desired view.
   */
  const renderView = () => {
    if (showSeedTypeSelection) {
      return <><SeedTypeSelection clickHandler={onSelectSeedType} /><StatusBarButton statusSlot={3} label='Abort' clickHandler={() => { setShowSeedTypeSelection(false); }} icon='fas fa-ban' type='inline' /></>
    }

    

    return <>
      <PrintLabelModal show={showPrintLabelModal} onCancel={onCancelPrintLabel} onPrint={printLabel} />
      <div className='create-produce-type-container'>
        <div className='create-produce-type-card-container'>
          <div className='create-produce-type-card'>
            <div className="marketing-name-input-container">
              <div className="marketing-name-input-label">Marketing Name</div>
              <input
                type="text"
                name='marketingName'
                value={modelState.marketingName?.value}
                onChange={onChange}
                className={(!modelState.marketingName?.isValid && modelState.marketingName?.touched) ? 'marketing-name-input red-border' : 'marketing-name-input'} />
                <p
                className='marketing-name-error'
                style={{ visibility: (!modelState.marketingName?.isValid && modelState.marketingName?.touched) ? 'visible' : 'hidden' }}>Marketing name should have three characters or more.</p>
            </div>
            <div className='classification-input-container'>
              <div className="classification-input-label">Classification</div>
              <input
                type="text"
                name='classification'
                value={modelState.classification.value}
                onChange={onChange}
                className={(!modelState.classification.isValid && modelState.classification?.touched) ? 'classification-input red-border' : 'classification-input'} />
                <p
                className='classification-error'
                style={{ visibility: (!modelState.classification?.isValid && modelState.classification?.touched) ? 'visible' : 'hidden' }}>Classification should have three characters or more.</p>
            </div>
            <div className='produce-number-and-gtin-input-container'>
              <div className="produce-number-input-label">Produce Number</div>
              <input
                type="text"
                name='produceNumber'
                value={modelState.produceNumber.value}
                onChange={onChange}
                className={(!modelState.produceNumber.isValid && modelState.produceNumber?.touched) ? 'produce-number-input red-border' : 'produce-number-input'} />
                <p
                className='produce-number-error'
                style={{ visibility: (!modelState.produceNumber?.isValid && modelState.produceNumber?.touched) ? 'visible' : 'hidden' }}>Produce number is mandatory.</p>
              <div className="gtin-input-label">GTIN</div>
              <input
                type="text"
                name='gtin'
                value={modelState.gtin.value}
                onChange={onChange}
                className={(!modelState.gtin.isValid && modelState.gtin?.touched) ? 'gtin-input red-border' : 'gtin-input'} />
              <p
                className='gtin-error'
                style={{ visibility: (!modelState.gtin?.isValid && modelState.gtin?.touched) ? 'visible' : 'hidden' }}>GTIN must be a valid EAN13 GTIN.</p>
            </div>
            <div className='template-container'>
              <div className="template-label">Label Printer Template</div>
              <select
                name='labelPrinterTemplateId'
                className='template-select'
                onChange={onChange}
                value={modelState?.labelPrinterTemplateId?.value}
              >
                <option key={`empty-option`} disabled value='' style={{ display: 'none' }}>Please select a Label Printer Template</option>
                {activeTemplates?.map((template, index) => (
                    <option key={`${template?.templateId}-${index}`} value={template?.id}>
                      {template?.name}
                    </option>
                ))}
              </select>
            </div>
            <div className="seed-selection-container">
              <div className="seed-selection-label">Seed Type</div>
              <input
                type="text"
                readOnly
                value={selectedSeedType?.seedType ? (selectedSeedType?.seedType?.name + ' (Nº ' + selectedSeedType?.seedType?.number + ')') : ''}
                className={'seed-selection-display'} />
              <button
                onClick={navigateToSeedTypeSelection}
                className={!selectedSeedType ? 'seed-selection-button red-border' : 'seed-selection-button'}><i className='fa fa-list'></i></button>
            </div>
            <div className="produce-image-upload-container">
              <div className="produce-image-upload-label">Produce Image</div>
              {localLoading ? <Loading fullScreen={false} inline={true} /> : <>
                {!photoData && <div
                  className='produce-image-upload-display'
                  onClick={clickFileInput}
                >
                  <input ref={inputRef} type='file' id='produce-image-upload' onChange={onPictureUpload} style={{ display: 'none' }} /><Button onClick={clickFileInput} className='take-button' icon={'fa fa-image'} position={'fixed'}></Button></div>}
                {photoData && <><div
                  className='saved-image'
                  onClick={onClickRetake}
                  style={{
                    background: `url(${photoData}) no-repeat center center/contain`,
                  }}
                ><button onClick={onClickRetake} className='retake-button'><i className="fas fa-redo"></i></button></div></>}
              </>}
            </div>
            <div className="appetizer-input-container">
              <div className="appetizer-input-label">Appetizer Text</div>
              <input
                type="text"
                name='appetizerText'
                value={modelState.appetizerText.value}
                onChange={onChange} className={(!modelState.appetizerText.isValid && modelState.appetizerText.touched) ? 'appetizer-input red-border' : 'appetizer-input'} />
            </div>
            <div className="description-input-container">
              <div className="description-input-label">Produce Description</div>
              <textarea
                rows={5}
                name='produceDescription'
                value={modelState.produceDescription.value}
                onChange={onChange}
                className={(!modelState.produceDescription.isValid && modelState.produceDescription.touched) ? 'description-input red-border' : 'description-input'}></textarea>
            </div>
          </div>
        </div>
        <StatusBarButton
            className='seed-card-btn'
            statusSlot={1}
            label='Print'
            type='inline'
            icon='fas fa-print'
            clickHandler={onPrintLabel}
            disabled={!modelState?.labelPrinterTemplateId?.value}
          />
          <StatusBarButton
            statusSlot={3}
            label='Abort'
            clickHandler={() => navigate('/management/produce-types/list')} icon='fas fa-ban' type='inline' />
          <StatusBarButton
            className='seed-card-btn'
            label='Confirm'
            statusSlot={5}
            icon='fas fa-check'
            type='inline'
            reversed={true}
            clickHandler={onSave}
            disabled={!modelStateIsValid() || !selectedSeedType?.seedType}
          />
        </div>
        </>
  }

  return loading ? <Loading fullScreen={true} /> : renderView();
};
export default Create;