import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import Button from '../layout/Button';
import confirm from 'reactstrap-confirm';
import { TemplateUpload } from './TemplateUpload';
import Loading from '../ui-elements/Loading';

const TemplateEditorCard = ({ template, editOn, deleteHandler, updateHandler }) => {

  const [loading, setLoading] = useState(false);

  const initialState = {
    id: {
      value: template.id,
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: '',
    },
    name: {
      value: template.name ?? '',
      touched: false,
      validate: function (text) {
        return text?.length >= 3;
      },
      isValid: !!template?.name,
      placeholder: 'Template name ...',
    },
    description: {
      value: template.description ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: 'Template description ...',
    },
    template: {
      value: template?.template ?? '',
      touched: false,
      validate: () => true,
      isValid: true,
      placeholder: '',
    },
  };

  const [edit, setEdit] = useState(editOn);
  const [templateState, setTemplateState] = useState(initialState);

  useEffect(() => {
    if (template) {
      const templateStateInit = Object.entries(template).reduce((acc, [key, value]) => {
        if (key in templateState === false) return acc;
        return {
          ...acc,
          [key]: { ...acc[key], value, isValid: acc[key]?.validate(value) },
        };
      }, initialState);
      setTemplateState(templateStateInit);
    }
  }, [template]);

  const onChange = (event) => {
    event.persist();

    const key = event.currentTarget.name;
    const value = event.currentTarget.value;
    setTemplateState((prev) => ({
      ...prev,
      [key]: {
        ...templateState[key],
        value,
        isValid: templateState[key].validate(value),
        touched: true,
      },
    }));
  };

  const activateEditState = () => {
    setEdit(true);
  };

  const reset = () => {
    setTemplateState(initialState);
    setEdit(false);
  };

  const createRequestBody = () => {
    const updatedTemplateProperties = Object.entries(templateState).reduce((acc, [key, valueObject]) => {
      return { ...acc, [key]: valueObject.value };
    }, {});
    const updatedTemplate = {
      ...template,
      ...updatedTemplateProperties,
    };
    return updatedTemplate;
  };

  const handleUpdate = async () => {
    setLoading(true);
    let updatedTemplate = createRequestBody();
    await updateHandler(updatedTemplate);
    setEdit(false);
    setLoading(false);
  };

  const blockSaveButton = () => {
    let updatedTemplate = createRequestBody();
    if(updatedTemplate?.name === template?.name && updatedTemplate.description === template?.description && updatedTemplate.fileName === template?.fileName && updatedTemplate?.template === template?.template) {
      return true;
    }
    if(!areAllValuesValid()) {
      return true;
    }

    return false;
  }

  const areAllValuesValid = () => {
    for (const key in templateState) {
        if (templateState.hasOwnProperty(key)) {
            const field = templateState[key];
            if (!field.validate(field.value)) {
                return false;
            }
        }
    }
    return true;
}

  const handleDelete = async () => {
    let result = await confirm({
      title: (
        <>
          <strong>DELETE Template</strong>
        </>
      ),
      message: 'Are you sure you want to delete this template?',
      confirmText: 'DELETE',
      confirmColor: 'success',
      cancelColor: 'muted',
      centered: true,
    });

    if (result) {
      setLoading(true);
      await deleteHandler(template.id);
      setLoading(false);
    }
  };

  const templateChangeHandler = (data, fileName) => {
    setTemplateState((prev) => ({
      ...prev,
      fileName: {
        value: fileName,
        validate: () => true,
        isValid: true,
        touched: true,
      },
      template: {
        ...initialState.template,
        value: data,
        isValid: true,
        touched: true,
      },
    }));
  };

  const templateResetHandler = () => {
    setTemplateState((prev) => ({
      ...prev,
      template: {
        ...template,
        value: null,
        isValid: true,
        touched: true,
      },
    }));
  };

  return (loading ? <Loading fullScreen={true} /> :
    <Card className='seed-card'>
      <CardHeader>
        <Fragment>
          <input
            className={
              templateState.name.isValid || !templateState.name.touched
                ? 'h4-input seed-property-input-field header-input'
                : 'h4-input seed-property-input-field header-input invalid-field'
            }
            type='text'
            minLength='3'
            value={templateState.name.value}
            onChange={onChange}
            name='name'
            id='name'
            placeholder={templateState.name.placeholder}
            disabled={edit === false}
          />
          {!templateState.name.isValid && templateState.name.touched && <p className="template-name-error-color" >Name must have 3 characters or more.</p>}
          {/* <p><i className="fas fa-check-double" style={{color: 'forestgreen', margin: 'auto'}}></i></p> */}
        </Fragment>
      </CardHeader>
      <CardBody className='seed-card-body'>
        <textarea
          rows='3'
          name='description'
          className={
            templateState.description.isValid || !templateState.description.touched
              ? 'seed-property-input-field italic header-input'
              : 'seed-property-input-field italic header-input invalid-field'
          }
          minLength='3'
          value={templateState.description.value}
          placeholder={edit === false ? templateState.description.value : templateState.description.placeholder}
          onChange={onChange}
          disabled={edit === false}
          // style={{ whiteSpace: 'pre-wrap' }}
        />
        <br />
        {edit ? (
          <TemplateUpload 
            changeHandler={templateChangeHandler} 
            resetHandler={templateResetHandler} 
            labelText={`Current template file: ${template.fileName}\nClick to replace with a new file.`}
            originalReuploadLabelText={`New template file: <fileName>\nClick to replace with a new file.`}
            originalTemplate={template}
            updatedTemplate={templateState}
            textSize='0.8em' />
        ) : (
          <CardFooter>
            <Fragment>
              <div>
                <p>
                  <strong>{template.fileName}</strong>
                </p>
              </div>
            </Fragment>
          </CardFooter>
        )}
      </CardBody>
      <div className='card-button seed-card-footer'>
        {edit ? (
          <Fragment>
            <Button className='seed-card-btn' label='Cancel' clickHandler={reset} />
            <Button className='seed-card-btn' label='Save' clickHandler={handleUpdate} disabled={blockSaveButton()} />
          </Fragment>
        ) : (
          <Fragment>
            <Button className='seed-card-btn' label={'Delete'} clickHandler={handleDelete} />
            <Button className='seed-card-btn' label={'Edit'} clickHandler={activateEditState} />
          </Fragment>
        )}
      </div>
    </Card>
  );
};

export default TemplateEditorCard;
