import React from 'react';

const HarvestingProcedureCard = ({ imagePath, text, onClickCallback }) => {
    
    return <div className='harvesting-procedure-card-body' onClick={onClickCallback}>
        <img src={imagePath} className='harvesting-procedure-card-image'/>
        <div className='harvesting-procedure-card-text'>
            {text}
        </div>
    </div>
}

export default HarvestingProcedureCard