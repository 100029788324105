import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import confirm from 'reactstrap-confirm';
import AuthContext from '../../contexts/auth/AuthContext';
import { HttpAgent } from '../../utility/HttpAgent';
import { USERS_BASE_URL, TENANTS_BASE_URL } from '../../constants/routes/api';
import Button from '../layout/Button';
import Loading from '../ui-elements/Loading';
import ProcessContext from '../../contexts/process/ProcessContext';
import { HELP_DESK_LINK } from '../../constants/helpDesk';

const TenantEditor = ({ tenant, containers, saveHandler, deleteHandler }) => {
  const { currentTenant } = useContext(AuthContext);
  const { notifyError } = useContext(ProcessContext);

  const initialState = {
    name: {
      value: tenant.name ?? '',
      touched: false,
      validate: function (text) {
        return text.length >= 3;
      },
      isValid: !!tenant?.name,
      placeholder: 'Tenant name ...',
    },
    tenantId: {
      value: tenant?.tenantId ?? '',
      touched: true,
      validate: function (text) {
        return true;
      },
      isValid: true,
      placeholder: '',
    },
  };

  const [tenantUsers, setTenantUsers] = useState([]);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [tenantState, setTenantState] = useState(initialState);

  const isStateValid = Object.values(tenantState)
    .map((entry) => entry.isValid)
    .every((item) => !!item);

  useEffect(() => {
    loadAllUsers(tenant?.tenantId);
  }, []);

  //  Activates the edit state.
  const activateEditState = () => {
    setEdit(true);
  };

  // Creates the request body from the tenant state for add and update tenant requests.
  const createRequestBody = () => {
    const updatedTenantProperties = Object.entries(tenantState).reduce((acc, [key, valueObject]) => {
      return { ...acc, [key]: valueObject.value };
    }, {});
    const updatedTenant = {
      ...tenant,
      ...updatedTenantProperties,
    };

    return updatedTenant;
  };

  //  Gets a user's role within the tenant.
  const getRolesForUserAsync = async (userId) => {
    let userRoleResult = await HttpAgent.get(`${TENANTS_BASE_URL}/${tenant?.tenantId}/users/${userId}/role`);

    return userRoleResult.data.roles;
  };

  // Handles what needs to happen when user presses delete and calls delete handler from props.
  const handleDelete = async () => {
    let result = await confirm({
      title: (
        <>
          <strong>DELETE TENANT</strong>
          <p>
            When you delete a tenant all related data (users, container, plants, produce, reports, pictures, weights and
            many more) will be deleted forever. This operation can not be undone.
          </p>
        </>
      ),
      message: 'Are you sure?',
      confirmText: 'DELETE',
      confirmColor: 'success',
      cancelColor: 'muted',
      centered: true,
    });

    if (result) {
      const newTenant = createRequestBody(tenant);
      await deleteHandler(newTenant);
      setEdit(false);
    }
  };

  //  Handles what needs to happen when user presses save and calls save handler from props.
  const handleSave = () => {
    if (!tenant?.tenantId) {
      return;
    }

    const body = createRequestBody();
    saveHandler(body).then(() => setEdit(false));
  };

  //  Loads all tenant users and tenant admins of this tenant.
  const loadAllUsers = async (tenantId) => {
    try {
      if (!tenantId) return;
      setLoading(true);
      let url = `api/v1/Tenants/${tenantId}/Users`;
      let result = await HttpAgent.get(url);
      let users = result.data;
      let getUsersRoleTasks = []
      for (const user of users) {
        ((currentUser) => {
          const task = getRolesForUserAsync(currentUser.id)
            .then((roles) => {
              currentUser.roles = roles;
            });

          getUsersRoleTasks.push(task);
        })(user);
      }

      await Promise.all(getUsersRoleTasks);
      setTenantUsers(users);
      setLoading(false);
    } catch (error) {
      if (error.message === 'Not found') {
        notifyError(<>We have encountered an issue while loading all users. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else {
        notifyError(<>We have encountered an issue on our side while loading all users. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.error(error);
    }
  };

  // Handles all on change events in the input fields.
  const onChange = (event) => {
    event.persist();

    const key = event.currentTarget.name;
    const value = event.currentTarget.value;
    setTenantState((prev) => ({
      ...prev,
      [key]: {
        ...tenantState[key],
        value,
        isValid: tenantState[key].validate(value),
        touched: true,
      },
    }));
  };

  // Quits edit state and resets tenant state.
  const reset = () => {
    setEdit(false);
    setTenantState(initialState);
  };

  // Truncates the text in the middle.
  const truncate = (email, maxLength = 30) => {
    if (!email) {
      return;
    }

    if (email.length <= maxLength) {
      return email;
    }

    const partLength = Math.floor((maxLength - 5) / 2);
    const start = email.substring(0, partLength);
    const end = email.substring(email.length - partLength);

    return `${start} ... ${end}`;
  };

  return loading ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      <Card className='seed-card'>
        <CardBody className='seed-card-body'>
          <Fragment>
            <input
              className={
                tenantState.name.isValid || !tenantState.name.touched
                  ? 'h4-input seed-property-input-field header-input'
                  : 'h4-input seed-property-input-field header-input invalid-field'
              }
              type='text'
              minLength='3'
              value={tenantState.name.value}
              onChange={onChange}
              name='name'
              id='name'
              placeholder={tenantState.name.placeholder}
              disabled={edit === false}
            />
            {!tenantState.name.isValid && tenantState.name.touched && <p>Name must have 3 characters or more.</p>}
            <div className='mt-4 container'>
              <div className='row'>
                <div className='col-6 container-column'>
                  {containers?.map((container, index) => (
                    <div className='mb-2' key={index}>
                      <i className='fas fa-dumpster'>&nbsp;&nbsp;</i>
                      <span>{truncate(container?.name, 20)}</span>
                    </div>
                  ))}
                </div>
                <div className='col-6 user-column'>
                  {tenantUsers.map((user, index) => (
                    <div key={index}>
                      {user.roles && user.roles.includes('TenantAdmin') ? (
                        <i className={'fas fa-user-tie'}>&nbsp;&nbsp;</i>
                      ) : (
                        <i className={'fas fa-user'}>&nbsp;&nbsp;</i>
                      )}
                      <span>{truncate(user?.email, 20)}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Fragment>
        </CardBody>
        <div className='card-button seed-card-footer'>
          {edit ? (
            <Fragment>
              <Button className='seed-card-btn' label='Cancel' clickHandler={reset} />
              <Button className='seed-card-btn' label='Save' clickHandler={handleSave} disabled={!isStateValid} />
            </Fragment>
          ) : (
            <Fragment>
              <Button
                className='seed-card-btn'
                label={'Delete'}
                clickHandler={handleDelete}
                disabled={tenant?.tenantId == currentTenant?.tenantId}
              />
              <Button className='seed-card-btn' label={'Edit'} clickHandler={activateEditState} />
            </Fragment>
          )}
        </div>
      </Card>
    </Fragment>
  );
};

export default TenantEditor;
