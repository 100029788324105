const HelpDeskIconButton = () => {
    const handleClick = () => {
        var btn = document.getElementById('lailo-widget-toggle-button');
        btn.click();
    }

    return(
        <div onClick={handleClick} className="help-desk-info-circle" >
            <span className="question-mark" >?</span>
        </div>
    )
}
export default HelpDeskIconButton;