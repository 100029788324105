import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import SelectableState from '../../../contexts/local/selectable/SelectableState';
import ProcessContext from '../../../contexts/process/ProcessContext';
import PanelSelector from '../transplanting/PanelSelector';
import PanelSlotSelection from '../harvesting/PanelSlotSelection';
import WeighingContainer from '../harvesting/WeighingContainer';
import EntitySelector from '../../ui-elements/EntitySelector';
import OccupiedTraySlotSelector from '../discarding/tray/OccupiedTraySlotSelector';

const Weighing = ({ forwardTo }) => {

  const { processFinished, occupancyArray, additionalProcessInformation, finishProcess } = useContext(ProcessContext);
  const location = useLocation();
  const navigate = useNavigate();
  const path = '/weighing';

  useEffect(() => {
    return () => {
      finishProcess();
    }
  }, []);

  useEffect(() => {
    if (processFinished) {
      navigate('/');
    }
  }, [processFinished, navigate]);

  const availableItems = () => {
    if (location.pathname === `${path}/tray`) {
      return occupancyArray.filter((item) => !item.isAvailable).map((item) => item.index);
    } else if (location.pathname === `${path}/panel`) {
      return occupancyArray
        .map((channel) =>
          channel.plantSlotBlocks.filter((block) => block.isOccupied).map((block) => block.startPlantSlotOrdinalNumber)
        )
        .flat();
    }
  };

  return (
    <div id='harvesting-container' className='process-container'>
      <Routes>
        <Route path={'/'} element={<EntitySelector forwardTo={path} center={true} />} />
        <Route
          path={`/tray`}
          element={
            <SelectableState availableItems={availableItems()}>
              <OccupiedTraySlotSelector forwardTo={`${path}/confirm-weight`} />
            </SelectableState>
          }
        />
        <Route
          path={`/panel`}
          element={
            <SelectableState availableItems={availableItems()}>
              <PanelSlotSelection forwardTo={`${path}/confirm-weight`} harvesting={false} />
            </SelectableState>
          }
        />
        <Route
          path={`/confirm-weight`}
          element={
            <div
              className={
                additionalProcessInformation &&
                  additionalProcessInformation.sourceEntity &&
                  additionalProcessInformation.sourceEntity.toLowerCase().startsWith('t')
                  ? 'tray-weighing-container'
                  : ''
              }
            >
              <WeighingContainer enablePrinting={false} processType='WEIGHING' />
            </div>
          }
        />
      </Routes>
    </div>
  );
};

export default Weighing;
