import { useContext, useReducer } from "react"
import TicketsReducer from "./TicketsReducer"
import TicketsContext from "./TicketsContext";
import { GET_SERVICEDESK_ISSUES } from "../types/contextTypes";
import ProcessContext from "../process/ProcessContext";
import { HELP_DESK_LINK } from "../../constants/helpDesk";
import ServiceDeskIssuesApiService from "../../services/apiServices/ServiceDeskIssuesApiService";
import FarmerAppApiErrorMapper from "../../utility/FarmerAppApiErrorMapper";

const TicketsProvider = (props) => {
    const initialState = {
        servicedeskIssues: []
    };

    const [state, dispatch] = useReducer(TicketsReducer, initialState);

    const { notifyError } = useContext(ProcessContext);

    const getServicedeskIssues = async (tenantId, containerId, userId) => {
        try {
            var servicedeskIssues = await ServiceDeskIssuesApiService.getServiceDeskIssues(tenantId, containerId, userId);
            setServicedeskIssues(servicedeskIssues.data.data);
        } catch (err) {
            const error = FarmerAppApiErrorMapper.mapFarmerAppError(err);
            if (error.statusCode === 400) {
                notifyError(<>We have encountered an issue while fetching the servicedesk issues. Please try again later, if the error still exists contact the support team {HELP_DESK_LINK}.</>);
                console.error(error);
            }
            else {
                notifyError(<>We have encountered an issue while getting the servicedesk issues. Please contact the support team {HELP_DESK_LINK}.</>);
                console.error(error);
            }
        }
    };

    const setServicedeskIssues = (servicedeskIssues) => {
        dispatch({
            type: GET_SERVICEDESK_ISSUES,
            payload: servicedeskIssues
        });
    };

    return(
        <TicketsContext.Provider
            value={{
                ...state,
                getServicedeskIssues
            }}
        >
            {props.children}
        </TicketsContext.Provider>
    )
};

export default TicketsProvider;