import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../../../contexts/auth/AuthContext';
import ProcessContext from "../../../contexts/process/ProcessContext";
import UIContext from '../../../contexts/ui/UIContext';
import { useParams, useNavigate } from 'react-router-dom';
import StatusBarButton from "../../layout/StatusBarButton";
import Loading from '../../ui-elements/Loading';
import DevicesApiService from "../../../services/apiServices/DevicesApiService";
import FarmhandInstallationsApiService from "../../../services/apiServices/FarmhandInstallationsApiService";
import { HELP_DESK_LINK } from '../../../constants/helpDesk';
import { separateOnCapitalLetters } from '../../../utility/PascalCaseSeparator';
import HarvestingProceduresContext from '../../../contexts/management/harvestingProcedures/HarvestingProceduresContext';
import HarvestingProceduresApiService from '../../../services/apiServices/HarvestingProceduresApiService';

const Detail = () => {
    const params = useParams();
    const { containerId } = params;
    const navigate = useNavigate();
    const { currentTenant, accessibleContainers } = useContext(AuthContext);
    const { notifySuccess, notifyWarning, notifyError } = useContext(ProcessContext);
    const { allHarvestingProcedures } = useContext(HarvestingProceduresContext);
    const [containerHarvestingProcedures, setContainerHarvestingProcedures] = useState();
    const { setInfo } = useContext(UIContext);
    const [loading, setLoading] = useState(false);
    const [currentContainer, setCurrentContainer] = useState(null);
    const [storageAdivceRooted, setStorageAdviceRooted] = useState(null);
    const [storageAdivceCut, setStorageAdviceCut] = useState(null);
    const [farmHandInstallationName, setFarmHandInstallationName] = useState("");
    const [weighingCameraEnabled, setWeighingCameraEnabled] = useState(false);

    useEffect(() => {
        const initialLoad = async () => {
            await load();
        };

        initialLoad();
    }, []);

    useEffect(() => {
        setInfo(`${currentContainer?.name}`);
    }, [currentContainer]);

    const load = async () => {
        setLoading(true);
        var container = accessibleContainers.find(c => c.id == containerId);
        setCurrentContainer(container);
        var harvestingProcedures = await HarvestingProceduresApiService.getManyHarvestingProcedures(currentTenant?.tenantId, container?.id);
        var rootedHarvestingProcedure = harvestingProcedures.data.data.find(hp => hp.type === "SingleHarvestRooted");
                if (rootedHarvestingProcedure) {
                    setStorageAdviceRooted(rootedHarvestingProcedure.storageAdvice);
                }

                var cutHarvestingProcedure = harvestingProcedures.data.data.find(hp => hp.type === "SingleHarvestCut" || hp.type === "MultiHarvestCut");
                if (cutHarvestingProcedure) {
                    setStorageAdviceCut(cutHarvestingProcedure.storageAdvice);
                }
        setContainerHarvestingProcedures(harvestingProcedures.data.data);
        if (container.weighingCameraId) {
            await DevicesApiService.getWeighingCameraState(container.tenantId, containerId, container.weighingCameraId)
                .then((response) => {
                    setWeighingCameraEnabled(response?.data.data == 1 ? true : false);
                });
        }
        if (container.farmHandInstallationId) {
            await FarmhandInstallationsApiService.getFarmHandInstallationById(container.farmHandInstallationId)
                .then((response) => {
                    setFarmHandInstallationName(response.data.data.name);
                })
                .catch((error) => {
                    console.error(error);
                    notifyError(<>We have encountered an issue on our side while loading farm hand. Please reload the page. If the error still exists, please contact {HELP_DESK_LINK}.</>);
                });
        }
        setLoading(false);
    }

    const containsCutProcedure = allHarvestingProcedures.some(hp => hp.type.includes("Cut"));
    const containsRootProcedure = allHarvestingProcedures.some(hp => hp.type.includes("Root"));
    
    const onHandleEdit = async () => {
        navigate(`/management/containers/${containerId}/edit`);
    };

    const copyTextToClipboard = (text) => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text).then(
            function () {
                notifySuccess('Copied to clipboard.');
            },
            function (err) {
                notifyWarning('Could not copy id to clipboard');
            }
        );
    };

    const fallbackCopyTextToClipboard = (text) => {
        var textArea = document.createElement('textarea');
        textArea.value = text ? text : '';

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            var successful = document.execCommand('copy');
            var msg = successful ? 'successful' : 'unsuccessful';
        } catch (err) { }

        document.body.removeChild(textArea);
    };

    return loading ? (
        <Loading fullScreen={true} />
    ) : (<>
        <div className='container-management-detail-container'>
            <div className="container-management-detail-card-container">
                <div className="container-management-detail-card">
                    <div className="container-management-details-general container-management-details-title">
                        General Information
                    </div>
                    <div className="container-management-details-name container-management-details-icon">
                        <i className="fas fa-signature"></i>
                    </div>
                    <div className="container-management-details-name container-management-details-label">
                        Name
                    </div>
                    <div className="container-management-details-name container-management-details-value">
                        {currentContainer?.name}
                    </div>
                    <div className="container-management-details-location container-management-details-icon">
                        <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="container-management-details-location container-management-details-label">
                        Location
                    </div>
                    <div className="container-management-details-location container-management-details-value">
                        {
                            currentContainer?.latitude && currentContainer?.longitude ?
                                (currentContainer?.street + " " + currentContainer?.number + ", " + currentContainer?.postalCode + " " + currentContainer?.city + ", " + currentContainer?.country)
                                : ""
                        }
                    </div>
                    <div className="container-management-details-description container-management-details-icon">
                        <i className="fas fa-comment-dots"></i>
                    </div>
                    <div className="container-management-details-description container-management-details-label">
                        Description
                    </div>
                    <div className="container-management-details-description container-management-details-value">
                        {currentContainer?.description}
                    </div>
                    <div className="container-management-details-devices container-management-details-title">
                        Devices
                    </div>
                    <div className="container-management-details-scale container-management-details-icon">
                        <i className="fas fa-balance-scale"></i>
                    </div>
                    <div className="container-management-details-scale container-management-details-label">
                        Scale
                    </div>
                    <div className="container-management-details-scale container-management-details-value">
                        {currentContainer?.scaleId}
                    </div>
                    <div onClick={() => copyTextToClipboard(currentContainer.scaleId)} className="container-management-details-scale-copy container-management-details-icon">
                        <i className='fas fa-copy'></i>
                    </div>
                    <div className="container-management-details-label-printer container-management-details-icon">
                        <i className="fas fa-print"></i>
                    </div>
                    <div className="container-management-details-label-printer container-management-details-label">
                        Label Printer
                    </div>
                    <div className="container-management-details-label-printer container-management-details-value">
                        {currentContainer?.labelPrinterId}
                    </div>
                    <div onClick={() => copyTextToClipboard(currentContainer.labelPrinterId)} className="container-management-details-label-printer-copy container-management-details-icon">
                        <i className='fas fa-copy'></i>
                    </div>
                    <div className="container-management-details-weighing-camera container-management-details-icon">
                        <i className="fas fa-camera"></i>
                    </div>
                    <div className="container-management-details-weighing-camera container-management-details-label">
                        Bird's Eye-View Camera
                    </div>
                    <div className="container-management-details-weighing-camera-active container-management-details-icon">
                        {weighingCameraEnabled ? <i className="fas fa-circle"></i> : <i className="far fa-circle"></i>}
                    </div>
                    <div className="container-management-details-weighing-camera container-management-details-value">
                        {currentContainer?.weighingCameraId}
                    </div>
                    <div onClick={() => copyTextToClipboard(currentContainer.weighingCameraId)} className="container-management-details-weighing-camera-copy container-management-details-icon">
                        <i className='fas fa-copy'></i>
                    </div>
                    <div className="container-management-details-arlo container-management-details-icon">
                        <i className="fas fa-video"></i>
                    </div>
                    <div className="container-management-details-arlo container-management-details-label">
                        Arlo Camera IDs
                    </div>
                    <div className="container-management-details-arlo container-management-details-value">
                        <div className="container-management-details-list">
                            {currentContainer?.cameras.map((item) => (
                                <div className="container-management-details-item">
                                    {item}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="container-management-details-miscellaneous container-management-details-title">
                        Miscellaneous
                    </div>
                    <div className="container-management-details-farmhand container-management-details-icon">
                        <i className="fas fa-tractor"></i>
                    </div>
                    <div className="container-management-details-farmhand container-management-details-label">
                        Farmhand ID
                    </div>
                    <div className="container-management-details-farmhand container-management-details-value">
                        {farmHandInstallationName}
                    </div>
                    <div className={containsRootProcedure ? "container-management-details-storage-advice-root container-management-details-icon" : "container-management-details-storage-advice-root container-management-details-icon disabled"}>
                        <i className="fas fa-archive"></i>
                    </div>
                    <div className={containsRootProcedure ? "container-management-details-storage-advice-root container-management-details-label" : "container-management-details-storage-advice-root container-management-details-label disabled"}>
                        Storage Advice Text for Root Produces
                    </div>
                    <div className={containsRootProcedure ? "container-management-details-storage-advice-root container-management-details-value" : "container-management-details-storage-advice-root container-management-details-value disabled"}>
                        {storageAdivceRooted}
                    </div>
                    <div className={containsCutProcedure ? "container-management-details-storage-advice-cut container-management-details-icon" : "container-management-details-storage-advice-cut container-management-details-icon disabled"}>
                        <i className="fas fa-archive"></i>
                    </div>
                    <div className={containsCutProcedure ? "container-management-details-storage-advice-cut container-management-details-label" : "container-management-details-storage-advice-cut container-management-details-label disabled"}>
                        Storage Advice Text for Cut Produces
                    </div>
                    <div className={containsCutProcedure ? "container-management-details-storage-advice-cut container-management-details-value" : "container-management-details-storage-advice-cut container-management-details-value disabled"}>
                        {storageAdivceCut}
                    </div>
                    <div className="container-management-details-harvesting-procedures container-management-details-icon">
                        <i className="fas fa-wheat-awn"></i>
                    </div>
                    <div className="container-management-details-harvesting-procedures container-management-details-label">
                        Harvesting Procedures
                    </div>
                    <div className="container-management-details-harvesting-procedures container-management-details-value">
                        <div className="container-management-details-list">
                            {containerHarvestingProcedures?.map((item, index) => (
                                <div className="container-management-details-item" key={index}>
                                    {separateOnCapitalLetters(item.type)}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <StatusBarButton
                label='Done'
                clickHandler={() => navigate('/management/containers/list')}
                statusSlot={3}
                icon='fair fai-circle-check'
                type='inline'
            />
            <StatusBarButton
                label='Edit'
                clickHandler={onHandleEdit}
                statusSlot={5}
                reversed={true}
                icon='fas fa-pen'
                type='inline'
            />
        </div>
    </>
    )
}

export default Detail;