import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../../../contexts/process/ProcessContext';
import UIContext from '../../../../contexts/ui/UIContext';
import StatusBarButton from '../../../layout/StatusBarButton';
import Loading from '../../../ui-elements/Loading';
import Tray from '../../Tray';
import SeedSlotDiscardConfirm from './SeedSlotDiscardConfirm';

const ConfirmPlantDiscarding = ({ forwardTo }) => {
  const {
    activeProcessId,
    occupancyArray,
    loadOccupancy,
    additionalProcessInformation,
    continueProcess,
    resetProcessState,
  } = useContext(ProcessContext);

  const [confirmed, setConfirmed] = useState(false);
  const { loading } = useContext(UIContext);
  const navigate = useNavigate();

  const load = async () => {
    await loadOccupancy(additionalProcessInformation.sourceEntity);
  };  

  useEffect(() => {
    load();
  }, []);

  const confirmSelection = async () => {
    setConfirmed(true);

    try {
      await continueProcess(activeProcessId, true);
      resetProcessState();
      navigate(forwardTo);
    } catch (error) {
      console.error(error);
    }
  };

  const reversedArray = [...occupancyArray].reverse();

  return loading ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      {!confirmed && (
        <Tray>
          {occupancyArray &&
            occupancyArray.length > 0 &&
            reversedArray.map((unit) => (
              <SeedSlotDiscardConfirm
                key={unit.index}
                displayName={unit.display !== 'available' ? unit.display : ''}
                index={unit.index}
                seedType={additionalProcessInformation.seedType}
              />
            ))}
        </Tray>
      )}

      <StatusBarButton
        label='Confirm'
        icon='fas fa-check'
        reversed={true}
        type='inline'
        clickHandler={confirmSelection}
        statusSlot={5}
      />
    </Fragment>
  );
};

export default ConfirmPlantDiscarding;
