import React, { Fragment, useContext, useEffect, useState } from 'react';
import UIContext from '../../contexts/ui/UIContext';

const Button = ({
  className,
  name,
  disabled,
  position,
  style,
  icon,
  label,
  clickHandler,
  type,
  inputType,
  reversed = false,
  dropdown,
  switchValue,
  dropdownSwitch,
  onText,
  offText,
  numberBadgeValue,
  size,
}) => {
  const { setLoadingElementName, loading, loadingElementName, addNotification } = useContext(UIContext);

  const handleClick = (e) => {

    if (disabled === true || !clickHandler || loading) return;
    ;
    if (name) setLoadingElementName(name);

    clickHandler(e);
  };

  const [shouldRenderLOading, setShouldRenderLOading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownIcon, setDropdownIcon] = useState('fas fa-chevron-right');

  const sizeClassName = size == 's' ? 'small' : '';

  useEffect(() => {
    setShouldRenderLOading(loading && loadingElementName === name);
  }, [loading, loadingElementName]);

  const renderDefault = () => (
    <Fragment>
      {icon && (
        <i
          className={`${shouldRenderLOading ? 'fas fa-spin fa-spinner' : icon} sweden-button-icon`}
        ></i>
      )}
      {!dropdown ? (
        <span className={sizeClassName}>{label}</span>
      ) : (
        !isOpen && <span className={sizeClassName}>{label}</span>
      )}
      {numberBadgeValue >= 0 && (
        <div className='number-badge'>
          <span>{numberBadgeValue}</span>
        </div>
      )}
    </Fragment>
  );

  const renderReversed = () => (
    <Fragment>
      {!dropdown ? (
        <span className={sizeClassName}>{label}</span>
      ) : (
        !isOpen && <span className={sizeClassName}>{label}</span>
      )}
      {icon && (
        <i
          className={`${shouldRenderLOading ? 'fas fa-spin fa-spinner' : icon} sweden-button-icon`}
          style={{ margin: '0 0 0 6px' }}
        ></i>
      )}
    </Fragment>
  );

  const test = (event) => {
    event.stopPropagation();
    setIsOpen(!isOpen);
    // setShow(true);
  };

  useEffect(() => {
    if (isOpen) {
      setDropdownIcon('fas fa-chevron-left');
    } else {
      setDropdownIcon('fas fa-chevron-right');
    }
  }, [isOpen]);

  useEffect(() => {
    setIsOpen(false);
  }, [switchValue]);


  return dropdown ? (
    <Fragment>
      <div
        className={`btn sweden-ui-input-button drop ${className ? className : ''}`}
        data-disabled={disabled}
        data-position={position ? position : 'static'}
        data-type={type}
        type={inputType}
        style={style}
        onClick={handleClick}
      >
        {/* {<div className={`dropdown-button`} onClick={test}>
        &nbsp;<i className='fas fa-chevron-up'></i>
      </div>}
      {isOpen && <div className='dropdown-items'>
          <div onClick={dropdownSwitch}>{switchValue ? `${onText}` : `${offText}`}</div>
        </div>} */}
        {reversed ? renderReversed() : renderDefault()}
      </div>
      {isOpen && (
        <div className='dropdown-items' data-disabled={disabled}>
          <div className='btn dropdown-item' data-disabled={disabled} onClick={dropdownSwitch}>
            <p className='item-text'>{switchValue ? `${onText}` : `${offText}`}</p>
          </div>
        </div>
      )}

      <div className={`dropdown-button`} onClick={test} data-disabled={disabled}>
        <i className={`${dropdownIcon}`}></i>
      </div>
    </Fragment>
  ) : (
    <button
      className={`btn sweden-ui-input-button ${className ? className : ''}`}
      data-disabled={disabled}
      data-position={position ? position : 'static'}
      data-type={type}
      type={inputType}
      style={style}
      onClick={handleClick}
    >
      {dropdown && (
        <div className='dropdown-button' onClick={test}>
          <i className='fas fa-chevron-up'></i>
        </div>
      )}
      {reversed ? renderReversed() : renderDefault()}
    </button>
  );
};

export default Button;
