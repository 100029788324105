import axiosClient from "../../http-common";

class TenantsApiService {
    
  getUserRoleForTenant(tenantId, userId) {
    return axiosClient.get(`/api/v1/tenants/${tenantId}/users/${userId}/role`);
  };

  updateUserRolesForTenant(tenantId, userId, roles) {
    let body = {
      data: {
        roles: roles,
      },
    };
    return axiosClient.put(`api/v1/tenants/${tenantId}/users/${userId}/role`, body);
  };

  addUserToTenant(tenantId, user) {
    let body = {
      data: user
    };
    return axiosClient.post(`api/v1/tenants/${tenantId}/users`, body);
  };

  deleteUserFromTenant(tenantId, userId) {
    return axiosClient.delete(`api/v1/Tenants/${tenantId}/Users/${userId}`)
  }
}

export default new TenantsApiService();