import React, { useState, useEffect } from 'react';
import { TENANT_ADMIN, OPERATOR, MARKETING } from '../../../constants/roles/roles';
import { isValidEmailAddress } from '../../../utility/Guard';
import Button from "../../layout/Button";

const Card = ({ cancelHandler, createHandler }) => {
    const initialUserState = {
        email: '',
        familyName: '',
        givenName: '',
        roles: [],
        id: null,
    };

    const [user, setUser] = useState(initialUserState);
    const [emailIsValid, setEmailIsValid] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);

    useEffect(() => {
        if (user?.email.length > 0 && !emailTouched) {
            setEmailTouched(true);
        }
        if (user?.email.length === 0 && emailTouched) {
            setEmailTouched(false);
        }
        if(isValidEmailAddress(user?.email)) {
            setEmailIsValid(true);
        }
        else {
            setEmailIsValid(false);
        }        
    },[user?.email]);   

    const changeRoles = (role) => {
        if (user.roles.includes(role)) {
            let newRoles = user.roles.filter(e => e !== role)
            setUser({
                ...user,
                roles: newRoles
            });

            return;
        }

        let newRoles = [...user.roles, role];
        setUser({
            ...user,
            roles: newRoles
        });
    };

    const handleCancel = () => {
        cancelHandler();
    };

    const handleCreate = () => {        
        createHandler(user);
    };

    const onChange = (event) => {
        event.persist();
        
        const value = event.currentTarget.value;
        setUser((prev) => ({
            ...prev,
            email: value
        }));
    };

    return <div className="tenant-user-management-card">
        <div className="tenant-user-management-card-header">
            <div className="tenant-user-management-card-name">
                New Tenant User
            </div>
        </div>
        <div className="tenant-user-management-card-email tenant-user-management-card-label">
            Email
        </div>       
        <input className={(!emailIsValid && emailTouched) ? `tenant-user-management-card-email-input red-border` :"tenant-user-management-card-email-input"} onChange={onChange}></input>
        {(!emailIsValid && emailTouched) && 
            <p className='tenant-user-management-card-error-message'>Please enter a valid email address.</p>
        }
        <div className="tenant-user-management-card-roles tenant-user-management-card-label">
            Roles
        </div>
        <div>
            <input type="checkbox" onChange={() => changeRoles(OPERATOR)} checked={user.roles?.includes(OPERATOR)} className="tenant-user-management-card-operator tenant-user-management-card-checkbox-value-enabled"></input>
            <div className="tenant-user-management-card-operator tenant-user-management-card-checkbox-label">
                Operator
            </div>
            <input type="checkbox" onChange={() => changeRoles(MARKETING)} checked={user.roles?.includes(MARKETING)} className="tenant-user-management-card-marketing tenant-user-management-card-checkbox-value-enabled"></input>
            <div className="tenant-user-management-card-marketing tenant-user-management-card-checkbox-label">
                Marketing
            </div>
            <input type="checkbox" onChange={() => changeRoles(TENANT_ADMIN)} checked={user.roles?.includes(TENANT_ADMIN)} className="tenant-user-management-card-tenant-admin tenant-user-management-card-checkbox-value-enabled"></input>
            <div className="tenant-user-management-card-tenant-admin tenant-user-management-card-checkbox-label">
                Tenant Admin
            </div>
        </div>
        <div>            
            <Button
                className='tenant-user-management-card-left tenant-user-management-card-button'
                label='Cancel'
                clickHandler={handleCancel}
            />           
            <Button 
                className='tenant-user-management-card-right tenant-user-management-card-button'
                label='Create'
                clickHandler={handleCreate}
                disabled={!emailIsValid || user?.roles.length < 1}
                />

        </div>
    </div >
}

export default Card;