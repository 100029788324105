import {
  ABORT_PROCESS,
  GET_AVAILABLE_PROCESSES,
  OCCUPANCY_LOADED,
  OCCUPANCY_UPDATED,
  PROCESS_UPDATE,
  REQUEST_ERROR,
  RESET, SET_REPORT_DATA,
  SET_SEEDTYPE,
  SET_SELECTION,
  START_PROCESS,
  MODIFY_PROCESS,
  UPDATE_DISPLAY_PREFIX,
  UPDATE_DISPLAY_VALUE,
  UPDATE_WEIGHT
} from '../types/contextTypes';
export default (state, action) => {
  switch (action.type) {
    case UPDATE_WEIGHT:
      return {
        ...state,
        currentWeight: action.payload,
      };
    case GET_AVAILABLE_PROCESSES:
      return {
        ...state,
        processes: action.payload.result.data.processes,
        error: null,
      };
    case OCCUPANCY_LOADED:
      return {
        ...state,
        occupancyArray: action.payload,
      };
    case OCCUPANCY_UPDATED:
      return {
        ...state,
        occupancyArray: action.payload,
      };
    case REQUEST_ERROR:
      return {
        ...state,
        processError: action.payload,
      };
    case MODIFY_PROCESS: {
      return {
        ...state,        
      }
    }
    case START_PROCESS:
      return {
        ...state,
        activeProcessId: action.payload.result.data.id,
        activeProcessType: action.payload.type,
        processFinished: false,
        error: null,
        processStarted: true,
      };
    case PROCESS_UPDATE:
      return {
        ...state,
        //activeProcessType: action.payload.activeProcessType,
        additionalProcessInformation: action.payload.additionalProcessInformation,
        backIsAllowed: action.payload.backIsAllowed,
        requestedInformationType: action.payload.requestedInformationType,
        processInfoMessage: action.payload.processInfoMessage,
        processFinished: action.payload.processFinished,
        error: null,
        activeProcessId: action.payload.processFinished ? null : state.activeProcessId,
        numPadDisplay: {
          ...state.numPadDisplay,
          prefix: null,
          value: '',
        },
      };
    case RESET:
    case ABORT_PROCESS:
      return {
        ...action.payload,
      };
    case SET_REPORT_DATA:
      return {
        ...state,
        reportData: action.payload,
      };
    case SET_SELECTION:
      return {
        ...state,
        selection: action.payload,
      };
    case SET_SEEDTYPE:
      return {
        ...state,
        selectedSeedType: action.payload,
      };
    case UPDATE_DISPLAY_PREFIX:
      return {
        ...state,
        numPadDisplay: {
          ...state.numPadDisplay,
          prefix: action.payload,
        },
      };
    case UPDATE_DISPLAY_VALUE:
      return {
        ...state,
        numPadDisplay: {
          ...state.numPadDisplay,
          value: action.payload,
        },
      };
    default:
      return state;
  }
};
