import axiosClient from "../../http-common";

class SeedVariantTypesApiService{
    addNewSeedVariantType(tenantId, containerId, seedTypeId, body) {
        return axiosClient.post(`Api/V1/Tenants/${tenantId}/Containers/${containerId}/SeedTypes/${seedTypeId}/SeedVariantTypes`, body);
    };

    deleteSeedVariantType(tenantId, containerId, seedTypeId, seedVariantTypeId) {
        return axiosClient.delete(`Api/V1/Tenants/${tenantId}/Containers/${containerId}/SeedTypes/${seedTypeId}/SeedVariantTypes/${seedVariantTypeId}`);
    };

    getSeedVariantType(tenantId, containerId, seedTypeId, seedVariantTypeId) {
        return axiosClient.get(`Api/V1/Tenants/${tenantId}/Containers/${containerId}/SeedTypes/${seedTypeId}/SeedVariantTypes/${seedVariantTypeId}`);
    };

    getSeedVariantTypes(tenantId, containerId, seedTypeId) {
        return axiosClient.get(`Api/V1/Tenants/${tenantId}/Containers/${containerId}/SeedTypes/${seedTypeId}/SeedVariantTypes`);
    };

    updateSeedVariantType(tenantId, containerId, seedTypeId, seedVariantTypeId, body) {
        return axiosClient.put(`Api/V1/Tenants/${tenantId}/Containers/${containerId}/SeedTypes/${seedTypeId}/SeedVariantTypes/${seedVariantTypeId}`, body);
    };

};

export default new SeedVariantTypesApiService();