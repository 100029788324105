// #region Libraries
import React, { useContext, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
// #endregion

// #region Contexts
import AuthContext from "../../../contexts/auth/AuthContext";
import HarvestingProceduresContext from "../../../contexts/management/harvestingProcedures/HarvestingProceduresContext";
// #endregion

// #region Components
import Button from "../../layout/Button";
import HarvestingProceduresApiService from "../../../services/apiServices/HarvestingProceduresApiService";
// #endregion

const PrintLabelModal = ({ show = false, onPrint, onCancel }) => {
  // #region Context properties
  const { allHarvestingProcedures } = useContext(HarvestingProceduresContext);
  const { accessibleContainers, currentTenant } = useContext(AuthContext);
  // #endregion

  // #region useStates
  const [containerId, setContainerId] = useState(accessibleContainers[0].id);
  const [harvestingProcedureId, setHarvestingProcedureId] = useState(allHarvestingProcedures.length > 0 ? allHarvestingProcedures[0].id : null);
  const [containerHarvestingProcedures, setContainerHarvestingProcedures] = useState(allHarvestingProcedures);
  // #endregion

  // #region Functions

  /**
   * Handles the cancel action and calls the onCancel prop function.
   */
  const handleCancel = () => {
    onCancel();
  }

  /**
   * Handles the print action by finding the selected container and harvesting procedure,
   * then calls the onPrint prop function with these as arguments.
   */
  const handlePrint = () => {
    const container = accessibleContainers.find(c => c.id === containerId);
    const harvestingProcedure = containerHarvestingProcedures.find(hp => hp.id === harvestingProcedureId);
    onPrint(container, harvestingProcedure);
  }

  /**
   * Handles the change event for the container selection.
   * @param {Event} event - The change event from the select input for containers.
   */
  const onContainerChange = async (event) => {
    event.persist();
    const printLabelInContainerWithId = event.currentTarget.value;
    setContainerId(printLabelInContainerWithId);
    var harvestingProcedures = await HarvestingProceduresApiService.getManyHarvestingProcedures(currentTenant?.tenantId, printLabelInContainerWithId);
    setContainerHarvestingProcedures(harvestingProcedures.data.data);
  }

  /**
   * Handles the change event for the harvesting procedure selection.
   * @param {Event} event - The change event from the select input for harvesting procedures.
   */
  const onHarvestingProcedureChange = (event) => {
    event.persist();
    const harvestingProcedureId = event.currentTarget.value;
    setHarvestingProcedureId(harvestingProcedureId);
  };

  /**
   * Separates a camelCase or PascalCase string into separate words.
   * @param {string} str - The string to separate.
   * @returns {string} - The separated string.
   */
  const separateOnCapitalLetters = (str) => {
    return str.replace(/([A-Z])/g, ' $1').trim();
  }
  // #endregion

  return (
    <Modal isOpen={show} className='modal-config print-label-modal'>
      <div className='pl-modal'>
        <div className='pl-modal-header-container'>
          <div className="pl-modal-header">Print Test Label</div>
        </div>
        <div className='container-label'>Container</div>
        <select className='container-select' name='containerId' onChange={onContainerChange} value={containerId}>
          {accessibleContainers?.map((container, index) => (
            <option key={`${container?.id}-${index}`} value={container?.id}>
              {container?.name}
            </option>
          ))}
        </select>
        <div className='procedure-label'>Harvesting Procedure</div>
        <select className='procedure-select' name='harvestingProcedureId' onChange={onHarvestingProcedureChange} value={harvestingProcedureId}>
          {containerHarvestingProcedures.length === 0 && <option key='noProcedures' value={null}></option>}
          {containerHarvestingProcedures?.map((harvestingProcedure, index) => (
            <option key={`${harvestingProcedure?.id}-${index}`} value={harvestingProcedure?.id}>
              {separateOnCapitalLetters(harvestingProcedure?.type)}
            </option>
          ))}
        </select>
        <div className='pl-buttons'>
          <Button label='Cancel' clickHandler={handleCancel} />
          <Button label='Print' clickHandler={handlePrint} />
        </div>
      </div>
    </Modal>
  );
}

export default PrintLabelModal;
