import React, { useContext, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import SelectableState from '../../../contexts/local/selectable/SelectableState';
import ProcessContext from '../../../contexts/process/ProcessContext';
import ConfirmSeedingSelection from './ConfirmSeedingSelection';
import EmptyTraySlotSelector from './EmptyTraySlotSelector';
import SeedTypeSelection from './SeedTypeSelection';
import TraySelection from './TraySelection';

const Seeding = () => {
  const { occupancyArray, finishProcess } = useContext(ProcessContext);

  const selectableItems = occupancyArray.filter((item) => item.isAvailable).map((item) => item.index);

  useEffect(() => {
    return () => {
      finishProcess();
    }
  },[]);

  return (
    <div id='seeding-container' className='process-container'>
      
      <Routes>
        <Route path={"/"} element={<TraySelection forwardTo={`/seeding/seed-type-selection`} />} />
        <Route path={`/seed-type-selection`} element={<SeedTypeSelection forwardTo={`/seeding/seed-slot-selection`} backwardTo={`/seeding`} />} />
        <Route path={`/seed-slot-selection`} element={
          <SelectableState availableItems={selectableItems}>
            <EmptyTraySlotSelector forwardTo={`/seeding/confirm-seed-slot-selection`} backwardTo={`/seeding/seed-type-selection`} />
          </SelectableState>
        } />
        <Route path={`/confirm-seed-slot-selection`} element={<ConfirmSeedingSelection backwardTo={`/seeding/seed-slot-selection`} />} />
      </Routes>
    </div>
  );
};

export default Seeding;
