import React from 'react';

const TransplantedTrayOverview = ({ traySelection, transplantingRelations }) => {
  const items = [...Array(200).keys()].reverse();

  const renderSlot = (slotNr) => {
    const relation = transplantingRelations.find(
      (rel) => rel.seedSlotOrdinalNumber === slotNr
    );
    const isSelected = !!relation;    
    
    const shoudBeMarked = traySelection.includes(slotNr);

    const classAdd = shoudBeMarked ? 'tray-item mark' : 'tray-item';

    return (
      <div key={slotNr} className={`${classAdd}`} data-selected={isSelected}>
        <p className='tray-item-text'>{(relation || shoudBeMarked) && slotNr + 1}</p>
      </div>
    );
  };

  return <div className='transplanted-tray'>{items.map((item) => renderSlot(item))}</div>;
};

export default TransplantedTrayOverview;
