import { REMOVE_SEQUENCE, RESET, SET_SELECTION_END, SET_SELECTION_START } from '../../types/contextTypes';

export default function (state, action) {

  switch (action.type) {
    case SET_SELECTION_START:
      return {
        ...state,
        lastSelectedNumber: action.payload,
        isSelecting: true,
      };
    case SET_SELECTION_END:
      return {
        ...state,
        selection: [...new Set([...state.selection, ...action.payload])],
        lastSelectedNumber: null,
        isSelecting: false,
      };
    case REMOVE_SEQUENCE:
      return {
        ...state,
        selection: state.selection.filter((item) => !action.payload.includes(item)),
        lastSelectedNumber: null,
        isSelecting: false,
      };
    case RESET:
      return {
        selection: [],
        lastSelectedNumber: null,
        isSelecting: false,
      };
    default:
      return state;
  }
}
