import React, { Fragment, useContext, useState, useCallback, useEffect } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';
import Button from '../../layout/Button';
import CategoryDropdown from '../../ReportFormElements/CategoryDropdown';
import CommentTextarea from '../../ReportFormElements/CommentTextarea';
import { PictureUpload } from './PictureUpload';

const ReportForm = ({ comment, setComment, category, categories, setCategory, photoData, setPhotoData }) => {
  const { additionalProcessInformation } = useContext(ProcessContext);
  const [dropDownOptions, setDropDownOptions] = useState(categories);
  const commentChangeHandler = (event) => {
    setComment(event.currentTarget.value);
  };

  const [streaming, setStreaming] = useState(false);

  const handleStreamChange = (state) => {
    if (streaming && state === false) {
      setPhotoData(null);
    }

    setStreaming(state);
  };

  const clearPhoto = () => {
    setPhotoData(null);
    // history.push("/");
  };

  const receivePhotoData = useCallback((parentData) => {
    if (!parentData?.photoData) {
      setPhotoData(null);
    } else {
      setPhotoData(parentData.photoData);
    }
  });

  const sortOptions = () => {
    let dropdownOptions = dropDownOptions;
    if (category) {
      dropdownOptions.sort((a, b) => a.localeCompare(b));
      dropdownOptions = dropdownOptions.filter((c) => c !== category);
      dropdownOptions.unshift(category);

      setDropDownOptions(dropdownOptions);
    }
  };

  useEffect(() => {
    if (dropDownOptions && category) {
      sortOptions();
    }

    return () => {};
  }, [category]);

  return (
    <Fragment>
      <div className='report-form-container'>
        <div className='report-form-area'>
          <div className='report-category-dropdown'>
            <CategoryDropdown
              label='Report Category'
              categories={dropDownOptions}
              activeCategory={category}
              setCategory={setCategory}
            />
          </div>
          <div className='report-comment'>
            <label>Comment:</label>
            <CommentTextarea changeHandler={commentChangeHandler} textDisplay={comment} />
          </div>
        </div>

        <div className='camera-output'>
          {photoData && (
            <Fragment>
              {/* <img id='saved-image' src={photoData} /> */}
              <div
                id='saved-image'
                style={{
                  background: `url(${photoData}) no-repeat center center/contain`,
                  height: '100%',
                  width: '100%',
                }}
              ></div>
              <Button icon='fas fa-redo' className='retake-btn' clickHandler={clearPhoto} />
            </Fragment>
          )}
          {!photoData && (
            <PictureUpload className='take-photo-btn' changeHandler={receivePhotoData} resetHandler={clearPhoto} />
          )}
        </div>
      </div>
    </Fragment>
  );
};

export default ReportForm;
