class FarmerAppApiErrorMapper {

  mapFarmerAppError(e) {
    if (!e) return;
    
    const statusCode = e.response?.status;
    const internalStatusCode = e.response?.data?.code;
    const errorMessage = e.response?.data?.message;
    const dataKeys = e?.response?.data?.data ? Object.keys(e?.response?.data?.data) : null;
    const failingPropertyName = dataKeys ? dataKeys[0] : '';
    const devErrorMessage = failingPropertyName != '' ? e.response?.data?.data[failingPropertyName] : null;

    const error = {
        statusCode,
        internalStatusCode,
        errorMessage,
        failingPropertyName,
        devErrorMessage
    };

    return error;
  }
}

export default new FarmerAppApiErrorMapper();