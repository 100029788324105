import React from 'react';

const CardBodyDetailRow = ({ label, value }) => {
  return (
    <div className='detail-row'>
      <div className='detail-label'>{ label }</div>
      <div className='detail-value'>{ value }</div>
    </div>
  );
};

export default CardBodyDetailRow;
