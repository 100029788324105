import React, { useState, useContext, Fragment, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Navbar } from 'react-bootstrap';
import Select, { components } from 'react-select';
import Loading from './ui-elements/Loading';

import { LoginMenu } from './api-authorization/LoginMenu';
import InfoPanel from './InfoPanel';
import ProcessContext from '../contexts/process/ProcessContext';
import AuthContext from '../contexts/auth/AuthContext';
import { TENANT_ADMIN, OPERATOR, MARKETING } from '../constants/roles/roles';
import HelpDeskIconButton from './layout/HelpDeskIconButton';
import Tickets from './layout/Tickets';

export const NavMenu = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const [currentPath, setCurrentPath] = useState(null);
  const [adminLinks, setAdminLinks] = useState([]);

  const toggleNavbar = () => {
    setCollapsed((prev) => !prev);
  };

  const {
    currentContainer,
    setCurrentContainer,
    currentTenant,
    userInformation,
    sessionInformation,
    setCurrentTenant,
    currentRoles,
    accessibleContainers,
    accessibleTenants,
    isGlobalAdmin,
    seedTypesLoading
  } = useContext(AuthContext);
  const { processStarted } = useContext(ProcessContext);

   // Whenever the role or global admin state changes, we need to reevaluate the content of the admin section.
   useEffect(() => {
    if (currentRoles || isGlobalAdmin) {
      setAdminLinks(getAdminLinks());
    }
  },[currentRoles, isGlobalAdmin]);

  const containerOptions = React.useMemo(() => {
    return accessibleContainers?.map((container) => ({
      label: container.name,
      value: container.id,
    }));
  }, [userInformation, accessibleContainers, currentTenant, currentContainer]);

  const tenantOptions = React.useMemo(() => {
    return accessibleTenants?.sort((a,b) => a.name < b.name)?.map((tenant) => ({
      label: tenant.name,
      value: tenant.tenantId,
    }));
  }, [userInformation, accessibleTenants]);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
    const initializeLailo = () => {
      if (window.Lailo) {
        window.Lailo.initHalfScreenWidget({
          botSecret: process.env.REACT_APP_LAILO_BOT_SECRET,
          language: "en-US",
          generalSettings:{
            initAvatar:false
          },
          colorSettings:{
            primary: "#464646",
            secondary: "#464646",
            inputFieldBackground: "#e4e4e4",
            inputFieldText: "#464646"
          }
        });
      }
    };

    if (window.Lailo) {
      initializeLailo();
    } else {
      window.onload = initializeLailo;
    }
    document.addEventListener('lailoInitialized', (e) => { console.log('Lailo successfully initialised', e)});
  }, []);

  useEffect(() => {
    setCurrentPath(window.location.pathname);
  }, [window.location.pathname]);

  const Separator = () => (
    <hr
      style={{
        height: 3,
        margin: 0,
        padding: 0,
        top: -12,
        backgroundColor: 'grey',
        
      }}
    />
  );

  const managementOptions = [
    {
      label: <span><i className='fa-solid fa-house icon-color' ></i>Containers</span>,
      value: '/management/containers/list',
      onClick: () => navigate('/management/containers/list'),
      role: [isGlobalAdmin, TENANT_ADMIN],
    },
    {
      label: <span><i className='fa-solid fa-print icon-color' ></i>Printer Templates</span>,
      value: '/templates',
      onClick: () => navigate('/templates'),
      role: [isGlobalAdmin, TENANT_ADMIN]
    },
    {
      label: <span><i className='fa-solid fa-seedling icon-color' ></i>Seed Types</span>,
      value: '/management/seed-types/list',
      onClick: () => navigate('/management/seed-types/list'),
      role: [isGlobalAdmin, TENANT_ADMIN, OPERATOR]
    },
    {
      label: <span><i className='fa-solid fa-gift icon-color' ></i>Produce Types</span>,
      value: '/management/produce-types/list',
      onClick: () => navigate('/management/produce-types/list'),
      role: [isGlobalAdmin, TENANT_ADMIN, OPERATOR, MARKETING]
    },
    {
      label: <Separator />,
      value: 'seperator',
      isDisabled: true,
      role: []
    },
    {
      label: <span><i className='fa-solid fa-building icon-color' ></i>Tenants</span>,
      value: '/tenantmanagement',
      onClick: () => navigate('/tenantmanagement'),
      role: [isGlobalAdmin]
    },
    {
      label: <span><i className='fa-solid fa-user icon-color' ></i>Tenant Users</span>,
      value: '/management/tenantUsers',
      onClick: () => {
        navigate('/management/tenantusers');
      },
      role: [isGlobalAdmin, TENANT_ADMIN]
    },
    {
      label: <span><i className='fa-solid fa-user-plus icon-color' ></i>Global Users</span>,
      value: '/globalusermanagement',
      onClick: () => {
        navigate('/globalusermanagement');
      },
      role: [isGlobalAdmin]
    }
  ];

  // Re-evaluates the auth status of the user and returns the valid array of management options.
  const getAdminLinks = () => {
    const options = [];
    managementOptions.map((option) => {
      if (isGlobalAdmin) {
        return options.push(option);
      }
      if (option.value === 'seperator') {
        return options.push(option);
      }
      if (currentRoles.some(item => option.role.includes(item))){
        return options.push(option);
      };
    });
    const indexOfSeperator = options.findIndex(o => o.value === 'seperator');
    if (indexOfSeperator === 0 || indexOfSeperator === options.length - 1) {
      options.splice(indexOfSeperator, 1);
    }
    return options;
  }

  const selectStyleLeft = {
    container: (provided) => ({
      ...provided,
      minWidth: '130px',
      backgroundColor: '#fffff',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#fffff',
      border: 'none',
      justifyContent: 'unset',
      fontWeight: 700,
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'none',
    }),
    indicatorContainer: (provided, state) => ({
      // padding: '8px 0',
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      // padding: '8px 0',
      display: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      flex: 'unset',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 'max-content',
      overflow: 'none'
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#4646460',
      backgroundColor: state.isFocused || state.isSelected ? '#ebebeb' : 'white'
    }),
  };

  const selectStyleRight = {
    container: (provided) => ({
      ...provided,
      minWidth: '130px',
      backgroundColor: '#fffff',
    }),
    control: (provided, state) => ({
      ...provided,
      backgroundColor: '#fffff',
      border: 'none',
      justifyContent: 'unset',
      fontWeight: 700,
    }),
    indicatorSeparator: (provided, state) => ({
      display: 'block',
    }),
    indicatorContainer: (provided, state) => ({
      // padding: '8px 0',
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      // padding: '8px 0',
      display: 'none',
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      flex: 'unset',
    }),
    menu: (provided, state) => ({
      ...provided,
      width: 'max-content',
      overflow: 'none',
      textAlign: 'left'
    }),
    option: (provided, state) => ({
      ...provided,
      color: '#4646460',
      backgroundColor: state.isFocused || state.isSelected ? '#ebebeb' : 'white',
      padding: state.isDisabled ? '0px 20px' : '8px 20px',
      margin: '2px 0px',
      height: state.isDisabled ? 10 : 40
    }),
  };

  useEffect(() => {
    if (accessibleContainers?.length > 0) {
      setCurrentContainer(currentContainer ?? accessibleContainers?.find(c => c.id === sessionInformation.lastSelectedContainerId));
    }
  }, [accessibleContainers]);

  const defaultContainerValue = React.useMemo(() => {
    return currentContainer
      ? containerOptions?.find((option) => option.value == currentContainer?.id)
      : containerOptions?.find((option) => option.value == containerOptions[0]);
  }, [currentContainer, containerOptions]);

  const defaultTenantValue = React.useMemo(() => {
    return currentTenant
      ? tenantOptions?.find((option) => option.value === currentTenant?.tenantId)
      : tenantOptions?.find((option) => option.value === tenantOptions[0]);
  }, [currentTenant, tenantOptions]);

  const defaultLinkName = {
    label: 'Management',
    value: '/',
  };

  return (seedTypesLoading ? <Loading fullScreen={true} /> :
    <header>
      <Navbar className='wide-navbar' bg='light' expand='sm'>
        <Container>
          <Navbar.Brand as={Link} to='/'>
            FarmerApp
          </Navbar.Brand>
          {/* TODO: 
            Custom colors. 
            Hard-set width 178px? 
          */}
          {(userInformation && accessibleTenants?.length > 0) && (
            <Select
              isSearchable={false}
              options={tenantOptions}
              styles={selectStyleLeft}
              isDisabled={processStarted || currentPath !== '/'}
              placeholder={'Select Tenant'}
              value={defaultTenantValue}
              noOptionsMessage={() => 'No Tenants Found'}
              onChange={async (option) =>
                await setCurrentTenant(accessibleTenants?.find((t) => t.tenantId == option.value))
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'whitesmoke',
                },
              })}
            />
          )}

          {(userInformation && accessibleContainers.length > 0) && (
            <Select
              options={containerOptions}
              isSearchable={false}
              styles={selectStyleLeft}
              isDisabled={processStarted || currentPath !== '/'}
              placeholder={'Select Container'}
              value={defaultContainerValue}
              noOptionsMessage={() => 'No Containers Found'}
              onChange={async (option) =>
                await setCurrentContainer(accessibleContainers?.find((c) => c.id == option.value))
              }
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: 'whitesmoke',
                },
              })}
            />
          )}
          <Navbar.Toggle onClick={toggleNavbar} className='mr-2' />
          {/* <Collapse className='d-sm-inline-flex flex-sm-row-reverse' isOpen={collapsed} navbar> */}
          <Navbar.Collapse className='justify-content-end'>
            <ul className='navbar-nav align-items-center'>
              {(
                <Fragment>
                  <Select
                    options={adminLinks}
                    isSearchable={false}
                    styles={selectStyleRight}
                    value={defaultLinkName}
                    onChange={(option) => option.onClick()}
                    placeholder='Administration'
                    maxMenuHeight={320}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: 'whitesmoke',
                      },
                    })}
                  />
                </Fragment>
              )}
              {(userInformation && accessibleContainers.length > 0) && <Tickets />}
              {(userInformation && accessibleContainers.length > 0) && <HelpDeskIconButton/>}
              <LoginMenu />
            </ul>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <InfoPanel />
    </header>
  );
};