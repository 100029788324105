import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import SelectableState from '../../../contexts/local/selectable/SelectableState';
import ProcessContext from '../../../contexts/process/ProcessContext';
import PanelSelection from '../discarding/panel/PanelSelection';
import OccupiedTraySlotSelector from '../discarding/tray/OccupiedTraySlotSelector';

const ReportOnPlants = ({ process, sourceFromProps }) => {
  const { sourceFromParams } = useParams();
  const source = !sourceFromProps ? sourceFromParams : sourceFromProps;
  
  const { occupancyArray } = useContext(ProcessContext);
  const [availableItems, setAvailableItems] = useState([]);
  useEffect(() => {
    if (source === 'tray') {
      setAvailableItems(occupancyArray.filter((item) => !item.isAvailable).map((item) => item.index));
    } else if (source === 'panel') {
      setAvailableItems(
        occupancyArray
          .map((channel) =>
            channel.plantSlotBlocks
              .filter((block) => block.isOccupied)
              .map((block) => block.startPlantSlotOrdinalNumber)
          )
          .flat()
      );
    }
  }, [occupancyArray, source]);

  return (
    <SelectableState availableItems={availableItems}>
      {source === 'panel' ? (
        <PanelSelection forwardTo={`/${process}/submit-report`} photo={process === 'photo'} />
      ) : (
        <OccupiedTraySlotSelector forwardTo={`/${process}/submit-report`} />
      )}
    </SelectableState>
  );
};

export default ReportOnPlants;
