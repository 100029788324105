import React, { Fragment, useContext } from 'react';
import SelectableContext from '../../../../contexts/local/selectable/SelectableContext';
import { Utilities } from '../../../../Utilities';

const PlantBlockSelectable = ({
  block,
  channelNr,
  index,
  disabled,
  hideContent,
  endOfLife,
  photo,
  harvesting,
  ...rest
}) => {
  const { determineIfSelected, determineIfBeingDeselected, handleSelection } = useContext(SelectableContext);

  const blockCode = `${channelNr + 1}/${index + 1}`;
  const slotSpan = block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber;  
  const isSelected = determineIfSelected(block.startPlantSlotOrdinalNumber) && !disabled;
  const isBeingDeselected = determineIfBeingDeselected(block.startPlantSlotOrdinalNumber) && !disabled;
  const hasCurrentPhoto = block.hasCurrentPhoto && photo;
  const handleClick = () => {
    if (disabled) return;
    if (harvesting == true && !block.produceTypeId) return;
    handleSelection(block.startPlantSlotOrdinalNumber);
  };

  return (
    
    <div
      className='channel-block'
      data-selected={isSelected}
      data-occupied={block.isOccupied}
      data-disabled={harvesting == true ? (disabled  || !block.produceTypeId) : disabled}
      data-being-deselected={isBeingDeselected}
      data-end-of-life={endOfLife}
      data-current-photo={hasCurrentPhoto}
      onClick={handleClick}
      {...rest}
    >
      {
        <Fragment>
          {slotSpan >= 4 && !hideContent && <i className={block.harvestCount >= 1 ? 'fab fa-pagelines plant-icon whitesmoke' : 'fas fa-seedling plant-icon whitesmoke'} style={{color: `${harvesting == true && (disabled  || !block.produceTypeId) ? 'whitesmoke' : ''}`}}></i>}
          {hasCurrentPhoto && <i className='fas fa-camera plant-icon-small'></i>}
          {block.isOccupied && (
            <span className='plant-name'>{Utilities.truncateText(block, 9, 7)}</span>
          )}
          {slotSpan >= 4 && <span className='block-code'>{blockCode}</span>}
        </Fragment>
      }
    </div>
  );
};

export default PlantBlockSelectable;