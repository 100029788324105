import React, { useContext } from 'react';
import SelectableContext from '../../../../contexts/local/selectable/SelectableContext';

const SeedSlotSelectable = ({ displayName, selectable, index, processName, isMultiharvestable, seedTypeIsMultiharvestable }) => {
    const { determineIfSelected, determineIfBeingDeselected, handleSelection } = useContext(SelectableContext);

    const isSelected = determineIfSelected(index);
    const isBeingDeselected = determineIfBeingDeselected(index);
    
    const row = Math.floor(index / 10);
    const col = index - (row * 10);
    const trayGridLabel = `${row + 1}/${col + 1}`;
  
    const handleClick = () => {
      if(!selectable) return;
      
      handleSelection(index);
    }

    const specialClassName = (() => {
      switch (processName) {
        case 'planting':
          return 'seed-slot-planting';
        case 'discarding':
          return 'seed-slot-discarding'
        default:
          return ''
      }
    })()
    const innerClassName = () => {
      return 'fas fa-seedling plant-icon';
    }
    return (
      <div
        key={index}
        className={`slot-unit ${specialClassName}`}
        data-selected={isSelected}
        data-selectable={selectable}
        data-being-deselected={isBeingDeselected}
        onClick={handleClick}
      >
        <i className={innerClassName()}></i>
        <span className='tray-name'>{displayName}</span>
        <span className="tray-grid-label">{trayGridLabel}</span>
      </div>
    );
}

export default SeedSlotSelectable
