class ProduceTypeRequest {
  constructor(currentTenant, selectedSeedType, modelState, photoData) {
      this.appetizerText = modelState.appetizerText.value;
      this.number = modelState.produceNumber.value;
      this.seedTypeId = selectedSeedType?.seedType?.id ?? null;
      this.gtin = modelState.gtin.value;
      this.labelPrinterTemplateId = modelState.labelPrinterTemplateId.value;
      this.classification = modelState.classification.value;
      this.description = modelState.produceDescription.value;
      this.base64EncodedImage = photoData;
      this.tenantId = currentTenant.tenantId;
      this.marketingName = modelState.marketingName.value;
  }
}

export default ProduceTypeRequest;