import React, { useContext, useEffect } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';
import Channel from '../discarding/panel/Channel';
import Panel from '../discarding/panel/Panel';
import PlantBlockWeighing from './PlantBlockWeighing';

const WeighingPanel = ({ setScaleTitle }) => {
  const { occupancyArray, additionalProcessInformation } = useContext(ProcessContext);

  const updateScaleTitle = () => {
    let elementBeingWeighed = null;
    let channelNr = -1;
    let positionOfSlotInChannel = -1;

    if (
      !occupancyArray ||
      !additionalProcessInformation ||
      additionalProcessInformation.plantSlotOrdinalNumber === undefined
    )
      return;

    for (let i = 0; i < occupancyArray.length; i++) {
      for (let j = 0; j < occupancyArray[i].plantSlotBlocks.length; j++) {
        if (
          occupancyArray[i].plantSlotBlocks[j].startPlantSlotOrdinalNumber ===
          additionalProcessInformation.plantSlotOrdinalNumber
        ) {
          elementBeingWeighed = occupancyArray[i].plantSlotBlocks[j];
          channelNr = i;
          positionOfSlotInChannel = j;
          break;
        }
        if (elementBeingWeighed) break;
      }
      if (elementBeingWeighed) break;
    }

    setScaleTitle(elementBeingWeighed ? `${channelNr + 1} / ${positionOfSlotInChannel + 1}` : '');
  };

  useEffect(() => {
    updateScaleTitle();
  }, [additionalProcessInformation.plantSlotOrdinalNumber, occupancyArray]);  

  const renderBlock = (block, channelNr, index) => {
    const toBeWeighed =
      block.startPlantSlotOrdinalNumber >= additionalProcessInformation.sourceFrom &&
      block.endPlantSlotOrdinalNumber <= additionalProcessInformation.sourceTo;

    const isInSelection = additionalProcessInformation?.selectedPlantSlotOrdinalNumbers.some(
      item => item.ordinalNumber === block.startPlantSlotOrdinalNumber
    );

    const isFinished = additionalProcessInformation.finishedPlantSlotOrdinalNumbers.some(number => number === block.startPlantSlotOrdinalNumber);

    const isSelected = additionalProcessInformation.plantSlotOrdinalNumber === block.startPlantSlotOrdinalNumber;

    return (
      <PlantBlockWeighing
        key={`${channelNr}-${block.startPlantSlotOrdinalNumber}`}
        index={index}
        block={block}
        channelNr={channelNr}
        isSelected={isSelected}
        isInSelection={isInSelection}
        toBeWeighed={toBeWeighed}
        isFinished={isFinished}
      />
    );
  };

  return (
    <div className='weighing-panel-container'>
      <Panel alignment='horizontal'>
        {occupancyArray.map((channel) => (
          <Channel key={channel.channelNumber} channel={channel}>
            {channel.plantSlotBlocks.map((block, index) => renderBlock(block, channel.channelNumber, index))}
          </Channel>
        ))}
      </Panel>
    </div>
  );
};

export default WeighingPanel;
