import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import heic2any from 'heic2any';
import Loading from '../../ui-elements/Loading';
import PrintLabelModal from './PrintLabelModal';
import SeedTypeSelection from './seedTypeSelection/List';
import StatusBarButton from '../../layout/StatusBarButton';
import AuthContext from '../../../contexts/auth/AuthContext';
import ProduceTypeContext from '../../../contexts/management/produceTypes/ProduceTypeContext';
import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';
import LabelPrinterTemplateApiService from '../../../services/apiServices/LabelPrinterTemplateApiService';
import UpdateProduceTypeRequest from '../../../models/ApiRequestModels/ProduceTypesController/UpdateProduceTypeRequest';
import {isValidEAN13GTIN} from '../../../utility/Guard';
import Button from '../../layout/Button';
import { ROLES } from '../../../constants/roles/roles';
import useFarmerAppPolicy from '../../../customHooks/useFarmerAppPolicy';
import { HELP_DESK_LINK } from '../../../constants/helpDesk';

const Edit = () => {
    const {produceTypeId} = useParams();
    const navigate = useNavigate();
    const operatorPolicy = useFarmerAppPolicy([ROLES.TENANT_ADMIN, ROLES.OPERATOR, ROLES.GLOBAL_ADMIN]);
    const tenantAdminPolicy = useFarmerAppPolicy([ROLES.TENANT_ADMIN, ROLES.GLOBAL_ADMIN]);
    const tenantMarketingPolicy = useFarmerAppPolicy([ROLES.TENANT_ADMIN, ROLES.MARKETING, ROLES.GLOBAL_ADMIN]);
    
    const { allProduceTypes, updateProduceType, getManyProduceTypes, loading } = useContext(ProduceTypeContext);
    const { currentTenant, currentContainer, accessibleSeedTypes, accessibleTemplates, currentUser } = useContext(AuthContext);
    const produceType = allProduceTypes.find(pt => pt.id === produceTypeId);
    const { setInfo } = useContext(UIContext);
    const { notifySuccess, notifyError } = useContext(ProcessContext);

    const getTemplate = () => {
        const template = accessibleTemplates?.find((template) => template?.id === produceType?.labelPrinterTemplateId);
        return template;
    };

    const getSeedType = () => {
        const seedType = accessibleSeedTypes?.find((seedType) => seedType?.id === produceType?.seedTypeId);
        return seedType;
    };

    const initialProduceTypeModelState = {
        tenantId: currentTenant.tenantId,
        marketingName: {
            value: produceType?.marketingName,
            touched: false,
            validate: function (text) {
                return text?.length >= 3;
            },
            isValid: true,
            placeholder: 'Marketing Name',
            label: 'Marketing Name'
        },
        produceNumber: {
            value: produceType?.number,
            touched: false,
            validate: function (text) {
                if (text?.length < 1) {
                    this.error = "Produce Number is mandatory.";
                    return false;
                }
                this.error = '';
                return true;
            },
            isValid: true,
            placeholder: '',
            error: ''
        },
        classification: {
            value: produceType?.classification,
            touched: false,
            validate: function (text) {
                return text?.length >= 3;
            },
            isValid: true,
            placeholder: '',
        },
        gtin: {
            value: produceType?.gtin,
            touched: false,
            validate: function (gtin) {
                if (gtin) {
                    return (isValidEAN13GTIN(gtin) && gtin?.length === 13) || gtin === '';
                }

                return true;
            },
            isValid: true,
            placeholder: '',
        },
        appetizerText: {
            value: produceType?.appetizerText,
            touched: false,
            validate: function (text) {
                return true;
            },
            isValid: true,
            placeholder: '',
        },
        produceDescription: {
            value: produceType?.description,
            touched: false,
            validate: function (text) {
                return true;
            },
            isValid: true,
            placeholder: '',
        },
        labelPrinterTemplateId: {
            value: getTemplate()?.id,
            touched: false,
            validate: function (id) {
                return id !== undefined && id !== null && id
            },
            isValid: true,
            placeholder: '',
        },
    };
    const initialSelectedSeedTypeState = {
        seedType: getSeedType(),
        touched: false
    };

    const initialImageState = {
        imageUrl: produceType?.imageUrl
    }

    const activeTemplates = accessibleTemplates.filter(t => t.isActive);

    const [localLoading, setLocalLoading] = useState(false);
    const [modelState, setModelState] = useState(initialProduceTypeModelState);
    const [photoData, setPhotoData] = useState(null);
    const [selectedSeedType, setSelectedSeedType] = useState(initialSelectedSeedTypeState);
    const [imageState, setImageState] = useState(initialImageState);
    const [showSeedTypeError, setShowSeedTypeError] = useState(false);
    const [showPrintLabelModal, setShowPrintLabelModal] = useState(false);
    const [showSeedTypeSelection, setShowSeedTypeSelection] = useState(false);

    useEffect(() => {
        setInfo('Edit Produce Type');
    }, []);

    useEffect(() => {
        setModelState(initialProduceTypeModelState);
        setImageState(initialImageState);
        setSelectedSeedType(initialSelectedSeedTypeState);
    }, [allProduceTypes, accessibleSeedTypes, loading])

    const inputRef = useRef(null);

    const clickFileInput = (e) => {
        e.stopPropagation();
        let input = document.getElementById('produce-image-upload');
        if (tenantAdminPolicy.isAllowed) {
            input?.click();
        }
    };

    const isPicture = (file) => {
        let fileName = file;
        const validImageTypes = ['.jpg', '.jpeg', 'png', '.heic', '.gif', '.svg', '.bmp', '.webp', '.tif', '.tiff'];

        for (let i = 0; i < validImageTypes?.length; i++) {
            if (fileName?.toLowerCase().endsWith(validImageTypes[i])) {
                return true;
            }
        }
        return false;
    };

    const modelStateIsValidAndChanged = () => {
        if (selectedSeedType?.seedType?.commonName != initialSelectedSeedTypeState?.seedType?.commonName) {
            return true;
        };

        const keys = Object.keys(modelState);
        const firstlyRendered = photoData === null;
        const imageTouched = !firstlyRendered && (photoData != produceType.imageUrl || photoData != null);

        const allAreValid = keys.every(key => {
            if (modelState[key].hasOwnProperty('isValid')) {
                return modelState[key].isValid;
            }
            return true;
        });
        const anyValuesHaveChanged = ( imageTouched || selectedSeedType.seedType !== initialSelectedSeedTypeState.seedType || keys.some(key => {
            return modelState[key].value != initialProduceTypeModelState[key].value;
        }));

        const anyValuesHaveBeenTouched = (imageTouched || keys.some(key => {
            if (modelState[key].hasOwnProperty('touched')) {
                return modelState[key].touched
            }
            return false;
        }));

        return allAreValid && anyValuesHaveChanged && anyValuesHaveBeenTouched;
    }

    const navigateToSeedTypeSelection = () => {
        setShowSeedTypeSelection(true);
    }

    const onCancelPrintLabel = () => {
        setShowPrintLabelModal(false);
    }

    const onChange = (event) => {
        event.persist();
        const key = event.currentTarget.name;
        const value = event.currentTarget.value;
        setModelState((prev) => ({
            ...prev,
            [key]: {
                ...modelState[key],
                value,
                isValid: modelState[key].validate(value),
                touched: true,
            },
        }));
    };

    const onClickRetake = () => {
        if (tenantMarketingPolicy.isForbidden()) return;

        setPhotoData('');
        var image = { imageUrl: null };
        setImageState(image);
    }

    const onPictureUpload = async (event) => {
        try {
            let img = event.target.files[0];
            setLocalLoading(true);

            if (!isPicture(img.name)) {
                throw new Error('Image is in an unknow format.');
            }

            let isOtherFormat = img.name.toLowerCase().endsWith('.heic');
            let data = await readImage(img);

            if (isOtherFormat) {
                let blob = new Blob([img], { type: 'image/heic' });
                await heic2any({ blob, toType: 'image/jpeg', quality: 1 }).then((conversionResult) => {
                    blob = conversionResult;
                });

                blob.lastModifiedDate = new Date();
                blob.name = img.name;
                data = await readImageAsBase64(blob);
                setLocalLoading(false);
            } else {
                data = await readImageAsBase64(img);
            }
            setPhotoData(data);
            setImageState(data);
        } catch (error) {
            notifyError("The image you tried to upload has the wrong type. Please provide another image and try again.");
        } finally {
            setLocalLoading(false);
        }
    };

    const onPrintLabel = async () => {
        setShowPrintLabelModal(true);
    }

    const onSave = async () => {
        if (!selectedSeedType.seedType) setShowSeedTypeError(true);
        for (let key in modelState) {
            if (modelState[key].hasOwnProperty('touched')) {
                modelState[key].touched = true;
            }
        }

        if (modelStateIsValidAndChanged()) {
            let imageOperation;
            if (produceType.imageUrl != null && imageState.imageUrl == null && photoData == null) {
                imageOperation = 2;
            }
            else if (produceType.imageUrl == null && photoData != null) {
                imageOperation = 0;
            }
            else if (produceType.imageUrl != null && imageState.imageUrl == null && photoData != null) {
                imageOperation = 1;
            }
            else {
                imageOperation = 3;
            }

            const updateProduceTypeRequest = new UpdateProduceTypeRequest(produceType.id, selectedSeedType, modelState, photoData, imageOperation);

            updateProduceType(produceType.id, updateProduceTypeRequest)
            .then(() => {
                notifySuccess("Successfully updated new produce type.");
                navigate('/management/produce-types/list');
                getManyProduceTypes();
            })
            .catch((e) => {
                if (e?.failingPropertyName === 'tenantId' || e?.failingPropertyName === 'labelPrinterTemplateId' || e?.statusCode === 500) {
                    notifyError("We have encountered an issue on our side while updating a produce type. Please contact support team support.farmer@diecityfarm.de.");
                }
                else if (e?.statusCode === 500) {
                    notifyError("We have encountered an issue on our side while updating a produce type. Please contact support team support.farmer@diecityfarm.de.");
                }
                else if (e?.internalStatusCode === 409) {
                    notifyError("A produce type for this seed type already exists. Please pick a different seed type. If you think this seed type should not already have a produce type attached, please contact support.farmer@diecityfarm.de")
                }
                else if (e?.failingPropertyName === 'gtin') {
                    notifyError("The gtin you have entered seems invalid. Please enter it again and take special care not to have any additional whitespaces in the input field. However, please report this behaviour to support.farmer@diecityfarm.de as this should have been prevented in the first place.");
                }
                else {
                    notifyError("We have encountered an issue on our side while updating a produce type. Please contact support team support.farmer@diecityfarm.de. No detailed error information possible.");
                }
                console.error("Error while updating a produce type: ", e);
            });
        }
    }

    const onSelectSeedType = (seedTypeId) => {
        const newSelectedSeedTypeState = {
            seedType: accessibleSeedTypes.find(seedType => seedType.id === seedTypeId),
            touched: true
        }

        setSelectedSeedType(newSelectedSeedTypeState);
        setShowSeedTypeError(false);
        setShowSeedTypeSelection(false);
    }

    const printLabel = useCallback((container, harvestingProcedure) => {
        const labelPrintingRequest = {
            labelPrinterTemplateId: modelState?.labelPrinterTemplateId?.value,
            printData: {
                produceBusinessKeyHash: 'testprint',
                produceHarvestingDate: new Date(),
                produceWeight: 0.00,
                produceTypeClassification: modelState.classification?.value,
                produceTypeGtin: modelState.gtin?.value ?? null,
                produceTypeMarketingName: modelState.marketingName?.value,
                produceTypeNumber: modelState.produceNumber?.value,
                produceStorageAdvice: harvestingProcedure?.storageAdvice
            }
        }
        
        LabelPrinterTemplateApiService.printLabel(currentTenant?.tenantId, container?.id, labelPrintingRequest)
            .then(() => {
                notifySuccess("Printed label.");
            })
            .catch((e) => {
                notifyError(<>We have encountered a problem when printing. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
                console.log("Error while printing a test label: ", e);
            });

    });

    const readImage = (img) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsArrayBuffer(img);
        });
    };

    const readImageAsBase64 = (img) => {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.onload = function () {
                resolve(reader.result);
            };
            reader.onerror = reject;
            reader.readAsDataURL(img);
        });
    };

    const renderView = () => {
        if (showSeedTypeSelection) {
            return <><SeedTypeSelection clickHandler={onSelectSeedType} /><StatusBarButton statusSlot={3} label='Abort' clickHandler={() => { setShowSeedTypeSelection(false); }} icon='fas fa-ban' type='inline' /></>
        }

        return !selectedSeedType?.seedType ? <Loading fullScreen={true} /> : <>
            <PrintLabelModal show={showPrintLabelModal} onCancel={onCancelPrintLabel} onPrint={printLabel} />
            <div className='edit-produce-type-container-wrapper'>
                <div className='edit-produce-type-container'>
                    <div className='edit-produce-type-card'>
                        <div className="marketing-name-input-container">
                            <div className={operatorPolicy.isAllowed() ? "marketing-name-input-label" : "marketing-name-input-label forbidden"}>Marketing Name</div>
                            {operatorPolicy.isAllowed() ?
                                <input
                                    type="text"
                                    name='marketingName'
                                    value={modelState.marketingName?.value}
                                    onChange={onChange}
                                    className={(!modelState.marketingName?.isValid && modelState.marketingName?.touched) ? 'marketing-name-input red-border' : 'marketing-name-input'}
                                />
                                :
                                <input
                                    type="text"
                                    name='marketingName'
                                    value={modelState.marketingName?.value}
                                    onChange={onChange}
                                    className={(!modelState.marketingName?.isValid && modelState.marketingName?.touched) ? 'marketing-name-input red-border forbidden-input' : 'marketing-name-input forbidden-input'}
                                    disabled
                                />
                            }
                            <p
                                className='marketing-name-error'
                                style={{ visibility: (!modelState.marketingName?.isValid && modelState.marketingName?.touched) ? 'visible' : 'hidden' }}>Marketing name should have three characters or more.</p>
                        </div>
                        <div className='classification-input-container'>
                            <div className={operatorPolicy.isAllowed() ? "classification-input-label" : "classification-input-label forbidden"}>Classification</div>
                            {operatorPolicy.isAllowed() ?
                                <input
                                    type="text"
                                    name='classification'
                                    value={modelState.classification.value}
                                    onChange={onChange}
                                    className={(!modelState.classification.isValid && modelState.classification?.touched) ? 'classification-input red-border' : 'classification-input'}
                                />
                                :
                                <input
                                    type="text"
                                    name='classification'
                                    value={modelState.classification.value}
                                    onChange={onChange}
                                    className={(!modelState.classification.isValid && modelState.classification?.touched) ? 'classification-input red-border forbidden-input' : 'classification-input forbidden-input'}
                                    disabled
                                />
                            }
                            <p
                                className='classification-error'
                                style={{ visibility: (!modelState.classification?.isValid && modelState.classification?.touched) ? 'visible' : 'hidden' }}>Classification should have three characters or more.</p>
                        </div>
                        <div className='produce-number-and-gtin-input-container'>
                            <div className={operatorPolicy.isAllowed() ? "produce-number-input-label" : "produce-number-input-label forbidden"}>Produce Number</div>
                            {operatorPolicy.isAllowed() ?
                                <input
                                    type="text"
                                    name='produceNumber'
                                    value={modelState.produceNumber.value}
                                    onChange={onChange}
                                    className={(!modelState.produceNumber.isValid && modelState.produceNumber?.touched) ? 'produce-number-input red-border' : 'produce-number-input'}
                                />
                                :
                                <input
                                    type="text"
                                    name='produceNumber'
                                    value={modelState.produceNumber.value}
                                    onChange={onChange}
                                    className={(!modelState.produceNumber.isValid && modelState.produceNumber?.touched) ? 'produce-number-input red-border forbidden-input' : 'produce-number-input forbidden-input'}
                                    disabled
                                />
                            }
                            <p
                                className='produce-number-error'
                                style={{ visibility: (!modelState.produceNumber?.isValid && modelState.produceNumber?.touched) ? 'visible' : 'hidden' }}>Produce number is mandatory.</p>
                            <div className={operatorPolicy.isAllowed() ? "gtin-input-label" : "gtin-input-label forbidden"}>GTIN</div>
                            {operatorPolicy.isAllowed() ?
                                <input
                                    type="text"
                                    name='gtin'
                                    value={modelState.gtin.value}
                                    onChange={onChange}
                                    className={(!modelState.gtin.isValid && modelState.gtin?.touched) ? 'gtin-input red-border' : 'gtin-input'}
                                />
                                :
                                <input
                                    type="text"
                                    name='gtin'
                                    value={modelState.gtin.value}
                                    onChange={onChange}
                                    className={(!modelState.gtin.isValid && modelState.gtin?.touched) ? 'gtin-input red-border forbidden-input' : 'gtin-input forbidden-input'}
                                    disabled
                                />
                            }
                            <p
                                className='gtin-error'
                                style={{ visibility: (!modelState.gtin?.isValid && modelState.gtin?.touched) ? 'visible' : 'hidden' }}>GTIN must be a valid EAN13 GTIN.</p>
                        </div>
                        <div className={'template-container'}>
                            <div className={tenantAdminPolicy.isForbidden() ? 'template-label-forbidden' : 'template-label'}>Label Printer Template</div>
                            {tenantAdminPolicy.isAllowed() ?
                                <select
                                    name='labelPrinterTemplateId'
                                    className={tenantAdminPolicy.isForbidden() ? 'template-select-forbidden' : 'template-select'}
                                    onChange={onChange}
                                    value={modelState?.labelPrinterTemplateId?.value}
                                >
                                    {<option key={`empty-option`} disabled value='' style={{ display: 'none' }}></option>}
                                    {activeTemplates?.map((template, index) => (
                                            <option key={`${template?.templateId}-${index}`} value={template?.id} >
                                                {template?.name}
                                            </option>
                                    ))}
                                </select>
                                :
                                <select
                                    name='labelPrinterTemplateId'
                                    className={tenantAdminPolicy.isForbidden() ? 'template-select-forbidden' : 'template-select'}
                                    onChange={onChange}
                                    value={modelState?.labelPrinterTemplateId?.value}
                                    disabled
                                >
                                    {<option key={`empty-option`} disabled value='' style={{ display: 'none' }}></option>}
                                    {activeTemplates?.map((template, index) => (
                                            <option key={`${template?.templateId}-${index}`} value={template?.id} >
                                                {template?.name}
                                            </option>
                                    ))}
                                </select>
                            }
                        </div>
                        <div className="seed-selection-container">
                            <div className={tenantAdminPolicy.isForbidden() ? 'seed-selection-label-forbidden' : 'seed-selection-label'}>Seed Type</div>
                            <input
                                type="text"
                                readOnly
                                value={selectedSeedType?.seedType ? (selectedSeedType?.seedType?.name + ' (Nº ' + selectedSeedType?.seedType?.number + ')') : ''}
                                className={tenantAdminPolicy.isForbidden() ? 'seed-selection-display-forbidden' : 'seed-selection-display'} />
                            {tenantAdminPolicy.isAllowed() ?
                                <button
                                    onClick={navigateToSeedTypeSelection}
                                    className={tenantAdminPolicy.isForbidden() ? `seed-selection-button-forbidden ${!selectedSeedType ? 'red-border' : ''}` : `seed-selection-button ${!selectedSeedType ? 'red-border' : ''}`}
                                ><i className='fa fa-list'></i></button>
                                :
                                <button
                                    onClick={navigateToSeedTypeSelection}
                                    className={tenantAdminPolicy.isForbidden() ? `seed-selection-button-forbidden ${!selectedSeedType ? 'red-border' : ''}` : `seed-selection-button ${!selectedSeedType ? 'red-border' : ''}`}
                                    disabled
                                ><i className='fa fa-list'></i></button>
                            }
                        </div>
                        <div className="produce-image-upload-container">
                            <div className={tenantMarketingPolicy.isForbidden() ? 'produce-image-upload-label-forbidden' : 'produce-image-upload-label'}>Produce Image</div>
                            {localLoading ? <Loading fullScreen={false} inline={true} /> : <>
                                {(!photoData && !imageState.imageUrl) && (tenantMarketingPolicy.isAllowed() ?
                                    <div
                                        className={'produce-image-upload-display-forbidden'}
                                        onClick={clickFileInput}
                                    >
                                        <input ref={inputRef} type='file' id='produce-image-upload' onChange={onPictureUpload} style={{ display: 'none' }} />
                                        <Button onClick={clickFileInput} className='take-button' icon={'fa fa-image'} position={'fixed'}></Button></div>
                                    :
                                    <div
                                        className={'produce-image-upload-display-forbidden'}
                                        onClick={clickFileInput}
                                        disabled
                                    >
                                        <input ref={inputRef} type='file' id='produce-image-upload' onChange={onPictureUpload} style={{ display: 'none' }} disabled />
                                        <Button onClick={clickFileInput} className='take-button' icon={'fa fa-image'} position={'fixed'} disabled></Button>
                                    </div>)}
                                {photoData && (tenantMarketingPolicy.isForbidden() ? <>
                                    <div
                                        className={'saved-image'}
                                        onClick={onClickRetake}
                                        style={{
                                            background: `url(${photoData}) no-repeat center center/contain`,
                                        }}
                                    >
                                        <button onClick={onClickRetake} className='retake-button' style={{ display: 'inline-block' }}><i className="fas fa-redo"></i></button></div></>
                                    :
                                    <>
                                        <div
                                            className={'saved-image-forbidden'}
                                            onClick={onClickRetake}
                                            disabled
                                            style={{
                                                background: `url(${photoData}) no-repeat center center/contain`,
                                            }}
                                        >
                                            <button onClick={onClickRetake} className='retake-button' disabled style={{ display: 'inline-block' }}><i className="fas fa-redo"></i></button>
                                        </div>
                                    </>)}
                                {(!photoData && imageState.imageUrl) && (tenantMarketingPolicy.isForbidden() ?
                                    <>
                                        <div
                                            className={'saved-image'}
                                            onClick={onClickRetake}
                                            style={{
                                                background: `url(${imageState.imageUrl}) no-repeat center center/contain`,
                                            }}
                                        >
                                            <button onClick={onClickRetake} className='retake-button' style={{ display: 'inline-block' }}><i className="fas fa-redo"></i></button>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div
                                            className={'saved-image-forbidden'}
                                            onClick={onClickRetake}
                                            disabled
                                            style={{
                                                background: `url(${imageState.imageUrl}) no-repeat center center/contain`,
                                            }}
                                        >
                                            <button onClick={onClickRetake} className='retake-button' style={{ display: 'inline-block' }}><i className="fas fa-redo"></i></button>
                                        </div>
                                    </>
                                )}
                            </>}
                        </div>
                        <div className="appetizer-input-container">
                            <div className={operatorPolicy.isAllowed() ? "appetizer-input-label" : "appetizer-input-label forbidden"}>Appetizer Text</div>
                            {operatorPolicy.isAllowed() ?
                                <input
                                    type="text"
                                    name='appetizerText'
                                    value={modelState.appetizerText.value}
                                    onChange={onChange} className={(!modelState.appetizerText.isValid && modelState.appetizerText.touched) ? 'appetizer-input red-border' : 'appetizer-input'}
                                />
                                :
                                <input
                                    type="text"
                                    name='appetizerText'
                                    value={modelState.appetizerText.value}
                                    onChange={onChange} className={(!modelState.appetizerText.isValid && modelState.appetizerText.touched) ? 'appetizer-input red-border forbidden-input' : 'appetizer-input forbidden-input'}
                                    disabled
                                />
                            }
                        </div>
                        <div className="description-input-container">
                            <div className={operatorPolicy.isAllowed() ? "description-input-label" : "description-input-label forbidden"}>Produce Description</div>
                            {operatorPolicy.isAllowed() ?
                                <textarea
                                    rows={5}
                                    name='produceDescription'
                                    value={modelState.produceDescription.value}
                                    onChange={onChange}
                                    className={(!modelState.produceDescription.isValid && modelState.produceDescription.touched) ? 'description-input red-border' : 'description-input'}
                                >
                                </textarea>
                                :
                                <textarea
                                    rows={5}
                                    name='produceDescription'
                                    value={modelState.produceDescription.value}
                                    onChange={onChange}
                                    className={(!modelState.produceDescription.isValid && modelState.produceDescription.touched) ? 'description-input red-border forbidden-input' : 'description-input forbidden-input'}
                                    disabled
                                >
                                </textarea>
                            }
                        </div>
                    </div>
                </div>
                <StatusBarButton
                    className='seed-card-btn'
                    statusSlot={1}
                    label='Print'
                    type='inline'
                    icon='fas fa-print'
                    clickHandler={onPrintLabel}
                    disabled={!modelState?.labelPrinterTemplateId?.value}
                />
                <StatusBarButton
                    statusSlot={3}
                    label='Abort'
                    clickHandler={() => { navigate('/management/produce-types/list'); }} icon='fas fa-ban' type='inline' />
                <StatusBarButton
                    className='seed-card-btn'
                    label='Confirm'
                    statusSlot={5}
                    icon='fas fa-check'
                    type='inline'
                    reversed={true}
                    clickHandler={onSave}
                    disabled={!modelStateIsValidAndChanged() || !selectedSeedType?.seedType}
                />
            </div>
        </>
    }

    return loading || accessibleSeedTypes?.length < 1 ? <Loading fullScreen={true} /> : renderView();
};
export default Edit;