import React, { useEffect, useState, useContext } from 'react';
import AuthContext from '../../../contexts/auth/AuthContext';
import UIContext from '../../../contexts/ui/UIContext';
import ProcessContext from '../../../contexts/process/ProcessContext';
import Card from './Card';
import Create from './Create';
import Loading from '../../ui-elements/Loading';
import StatusBarButton from '../../layout/StatusBarButton';
import { useNavigate } from 'react-router-dom';
import UsersApiService from '../../../services/apiServices/UsersApiService';
import TenantsApiService from '../../../services/apiServices/TenantsApiService';
import { HELP_DESK_LINK } from '../../../constants/helpDesk';

const List = () => {
    const [adding, setAdding] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tenantUsers, setTenantUsers] = useState(null);
    const { currentTenant } = useContext(AuthContext);
    const { setInfo } = useContext(UIContext);
    const { notifySuccess, notifyError } = useContext(ProcessContext);
    const navigate = useNavigate();

    useEffect(() => {
        setInfo('Tenant User Management');

        const initialLoad = async () => {
            await load();
        };

        initialLoad();
    }, []);

    const toggleAdding = () => {
        setAdding(!adding);
    };

    const load = async () => {
        try {
            setLoading(true);
            const users = (await UsersApiService.getUsersOfTenant(currentTenant.tenantId)).data.data;
            let getRolesTasks = [];
            let newUsers = [];
            for (const user of users) {
                let newUser = user;
                const getRoleTask = TenantsApiService.getUserRoleForTenant(currentTenant.tenantId, user.id).then((roleResult) => {
                    newUser.roles = roleResult.data.data.roles;
                    newUsers.push(newUser);
                });
                getRolesTasks.push(getRoleTask);
            }
            await Promise.all(getRolesTasks);
            setTenantUsers(
                newUsers.sort(function (a, b) {
                    if (!a.familyName && !b.familyName) {
                        return 0;
                    }
                    else if (!a.familyName) {
                        return -1;
                    }
                    else if (!b.familyName) {
                        return 1;
                    }
                    else {
                        let x = a.familyName.toUpperCase(),
                            y = b.familyName.toUpperCase();
                        return x == y ? 0 : x > y ? 1 : -1; 
                    }
                })
            );
        } catch (err) {
            console.error('Error while getting user ids for tenant: ', err);
        } finally {
            setLoading(false);
        }
    };

    const createUser = async (user) => {
        setLoading(true);
        user.email = user.email.replace(/\s+/g, '');
        try {
            const existingUser = await _getUserOrUndefinedAsync(user.email);
            if (existingUser) {
                let body = {
                    userId: existingUser.id,
                    roles: user.roles
                };
                await TenantsApiService.addUserToTenant(currentTenant.tenantId, body);
            }
            else {
                const newUserId = await _createTenantUserAsync(user);
                let body = {
                    userId: newUserId,
                    roles: user.roles
                };
                await TenantsApiService.addUserToTenant(currentTenant.tenantId, body);
            }
            notifySuccess('Successfully added user to tenant.');
        } catch (error) {
            if (error.message === 'Not found') {
                notifyError(<>We have encountered an issue while saving the new tenant user. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
            }
            else {
                notifyError(<>We have encountered an issue on our side while saving the new tenant user. Please contact the support team {HELP_DESK_LINK}.</>);
            }
        }
        finally {
            toggleAdding();
            setLoading(false);
            load();
        }
    };

    const _getUserOrUndefinedAsync = async (adminEmail) => {
        try {
            const result = await UsersApiService.getUserBySearchTerm(adminEmail);
            return result?.data.data;
        } catch (err) {
            return undefined;
        }
    };

    const _createTenantUserAsync = async (tenantUser) => {
        const body = {
            email: tenantUser.email,
            isGlobalAdmin: false,
        };

        const addUserResult = await UsersApiService.addUser(body);
        return addUserResult?.data.data.id;
    };

    const deleteHandler = async (userId) => {
        setLoading(true);
        TenantsApiService.deleteUserFromTenant(currentTenant.tenantId, userId)
            .then(() => {
                notifySuccess("User was successfully deleted.")
            })
            .catch((error) => {
                if (error.message === 'Not found') {
                    notifyError(<>We have encountered an issue while deleting the user. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
                }
                else {
                    notifyError(<>We have encountered an issue on our side while deleting the tenant. Please contact the support team {HELP_DESK_LINK}.</>);
                }
                console.log(error.message);
            })
            .finally(() => {
                setLoading(false);
                load();
            });
    };

    return loading ? <Loading fullScreen /> : (
        <div className="tenant-user-management-list">
            {!adding && (
                <div className="tenant-user-management-adding" onClick={toggleAdding}>
                    <i className="fas fa-plus"></i>
                </div>)}
            {adding && (
                <Create
                    cancelHandler={toggleAdding}
                    createHandler={createUser}
                />
            )}
            {tenantUsers?.length > 0 &&
                tenantUsers?.map((user) => (
                    <Card
                        tenantUser={user}
                        handleDelete={deleteHandler}
                        key={user.id}
                    />))}
            <StatusBarButton
                label='Done'
                clickHandler={() => navigate('/')}
                statusSlot={3}
                icon='fair fai-circle-check'
                type='inline'
            />
        </div>
    );
};

export default List;