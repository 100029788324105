class UpdateProduceTypeRequest {
    constructor(produceTypeId, selectedSeedType, modelState, photoData, imageOperation) {
        this.appetizerText = modelState.appetizerText.value;
        this.number = modelState.produceNumber.value;
        this.seedTypeId = selectedSeedType?.seedType?.id ?? null;
        this.gtin = modelState.gtin.value;
        this.labelPrinterTemplateId = modelState.labelPrinterTemplateId.value;
        this.classification = modelState.classification.value;
        this.description = modelState.produceDescription.value;
        this.base64EncodedImage = photoData;
        this.produceTypeId = produceTypeId;
        this.marketingName = modelState.marketingName.value;
        this.imageOperation = imageOperation;
    }
  }
  
  export default UpdateProduceTypeRequest;