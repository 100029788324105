import React, { Fragment, useContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ProcessContext from '../../contexts/process/ProcessContext';
import StatusBarButton from '../layout/StatusBarButton';
import NumberPad from './NumberPad';

const EntitySelector = ({ forwardTo, allPanels, center }) => {
  const { continueProcess, activeProcessId, notifyError } = useContext(ProcessContext);
  const [activeCard, setActiveCard] = useState(null);
  const [display, setDisplay] = useState({
    prefix: '',
    value: '',
  });
  const location = useLocation();
  const centerClass = center === true ? 'selector-center' : ''

  const style = {
    alignSelf: 'auto'
  };

  const navigate = useNavigate();

  const handleClick = async (prefix) => {
    if (prefix == 'a_p') {
      setActiveCard(prefix);
      setDisplay((prev) => ({ ...prev, prefix }));
      await continueProcess(activeProcessId, prefix);
      navigate(forwardTo + '/' + 'submit-report');
    } else {
      setActiveCard(prefix);
      setDisplay((prev) => ({ ...prev, prefix }));
    }
  };

  const finishSelection = async () => {
    const entityName = `${display.prefix}${display.value}`;
    try {
      await continueProcess(activeProcessId, entityName);
      const target = determineTarget(display.prefix);
      if (target === "destination-selection") {
        const selectionType = display.prefix;
        navigate(forwardTo + '/' + target, {state: {selectionType}});
      }
      else{
        navigate(forwardTo + '/' + target);
      }
    } catch (error) {
      if (/p(?=[0-9])/i.test(error.message)) {
        notifyError(`You tried to enter an invalid panel. Please select a panel between 1 and ${process.env.REACT_APP_MAX_PANEL_NUMBER}`);
      }
      if (/t(?=[0-9])/i.test(error.message)) {
        notifyError(`You tried to enter an invalid tray. Please select a tray between 1 and ${process.env.REACT_APP_MAX_TRAY_NUMBER}`);
      }
      console.error(error);
    }
  };

  const setValue = (value) => {
    setDisplay((prev) => ({ ...prev, value }));
  };

  const determineTarget = (prefix) => {
    if (location.pathname === "/repositioning") {
      return "destination-selection";
    };

    switch (prefix) {
      case 't':
        return 'tray';
        break;
      case 'p':
        return 'panel';
      case 'a_p':
        return 'submit-report';
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <div className={`entity-selector ${centerClass}`} style={{ overflowY: !!activeCard ? 'unset' : 'hidden' }}>
        <div className='entity-cards-container' data-shrink={!!display.prefix}>
          <div className='entity-card' data-selected={activeCard === 't'} onClick={() => handleClick('t')}>
            <i className='fas fa-th'></i>
            <span>Tray</span>
          </div>
          <div className='entity-card' data-selected={activeCard === 'p'} onClick={() => handleClick('p')}>
            <i className='fas fa-grip-lines-vertical'></i>
            <span>Panel</span>
          </div>
          {allPanels && (
            <div className='entity-card' data-selected={activeCard === 'a_p'} onClick={() => handleClick('a_p')}>
              <i className='fas fa-layer-group'></i>
              <span>All Panels</span>
            </div>
          )}
        </div>
        <div className='numpad-container' data-visible={!!display.prefix}>
          <NumberPad disabled={display.prefix === ''} prefix={''} value={display.value} setValue={setValue} />
        </div>
      </div>
      {display.prefix && display.value && (
        <StatusBarButton
          label='Continue'
          icon='fas fa-chevron-right'
          reversed={true}
          clickHandler={finishSelection}
          statusSlot={5}
        />
      )}
    </Fragment>
  );
};

export default EntitySelector;
