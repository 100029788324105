import{ useContext } from 'react';
import AuthContext from '../contexts/auth/AuthContext';

const useFarmerAppPolicy = (allowedRoles) => {

  const { currentRoles, isGlobalAdmin } = useContext(AuthContext);
  
  const isAllowed = () => {   

    if (isGlobalAdmin) {
      return true;
    }
    return allowedRoles.some(r => currentRoles.includes(r));
  }

  const isForbidden = () => {
    if (isGlobalAdmin) {
      return false;
    }

    return !isAllowed();
  }

  return {
    isForbidden,
    isAllowed,
    allowedRoles
  }
}

export default useFarmerAppPolicy;