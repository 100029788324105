import React, { useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import SelectableState from '../../../contexts/local/selectable/SelectableState';
import WallSelector from '../../layout/WallSelector';
import OccupiedTraySlotSelector from '../discarding/tray/OccupiedTraySlotSelector';
import WallSlotSelector from '../repositioning/WallSlotSelector';
import TraySelection from '../seeding/TraySelection';
import ConfirmTransplanting from './ConfirmTransplanting';
import NumPlantsPerChannel from './NumPlantsPerChannel';
import PanelSelector from './PanelSelector';
import PanelSlotSelector from './PanelSlotSelector';
import ProcessContext from '../../../contexts/process/ProcessContext';

const Transplanting = () => {
  const { processFinished, occupancyArray, additionalProcessInformation, finishProcess } = useContext(ProcessContext);

  const [availableItems, setAvailableItems] = useState([]);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    return () => {
      finishProcess();
    }
  },[]);

  /* If any process information changes, rerender and map occupancy accordingly */
  useEffect(() => {
    if (location.pathname === `/transplanting/tray-slot-selection`) {
      setAvailableItems(occupancyArray.filter((item) => !item.isAvailable).map((item) => item.index));
    } 
    else if (location.pathname === `/transplanting/choose-panel-slots`) {
      setAvailableItems(
        additionalProcessInformation?.virtualOccupancy
          ?.map((channel) =>
            channel.filter((block) => block.isOccupiable).map((block) => block.startPlantSlotOrdinalNumber)
          )
          .flat() ?? []
      );
    }
  }, [occupancyArray, additionalProcessInformation]);

  /* If the process is finished, after wall slot selection we will not redirect to panel selection, but to main screen */
  useEffect(() => {
  if (processFinished && location.pathname === `/transplanting/choose-destination-panel`) {
    navigate('/');
  }
}, [processFinished, location.pathname]);

  return (
    <div id='transplanting-container' className='process-container'>
      <Routes>
        <Route path={'/'} element={<TraySelection forwardTo={`/transplanting/tray-slot-selection`} />} />
        <Route
          path={`/tray-slot-selection`}
          element={
            <SelectableState availableItems={availableItems}>
              <OccupiedTraySlotSelector forwardTo={`/transplanting/choose-destination-panel`} />
            </SelectableState>
          }
        />
        <Route
          path={`/choose-destination-panel`}
          element={<PanelSelector forwardTo={`/transplanting/choose-max-number-of-plants-per-channel`} />}
        />
        <Route
          path={`/choose-max-number-of-plants-per-channel`}
          element={<NumPlantsPerChannel forwardTo={`/transplanting/choose-panel-slots`} />}
        />
        <Route
          path={`/choose-panel-slots`}
          element={
            <SelectableState availableItems={availableItems}>
              <PanelSlotSelector forwardTo={`/transplanting/confirm-transplanting`} />
            </SelectableState>
          }
        />
        <Route path={`/confirm-transplanting`} element={<ConfirmTransplanting />} />
        <Route path={`/choose-wall`} element={<WallSelector forwardTo={`/transplanting/choose-wall-slot`} />} />
        <Route
          path={`/choose-wall-slot`}
          element={<WallSlotSelector forwardTo={`/transplanting/choose-destination-panel`} />}
        />
      </Routes>
    </div>
  );
};

export default Transplanting;
