import {
  DELETE_SEEDTYPE,
  DELETE_SEEDVARIANTTYPE, SAVE_NEW_SEEDTYPE, SET_ALL_SEEDTYPES, SET_LABEL_PRINTER_TEMPLATE_NAMES, UPDATE_SEEDTYPE
} from '../types/contextTypes';

export default (state, action) => { 
  switch(action.type) {
    case SET_LABEL_PRINTER_TEMPLATE_NAMES:
      return {
        ...state,
        labelPrinterTemplateNames: action.payload,
      }
    case SET_ALL_SEEDTYPES:
      return {
        ...state,
        allSeedTypes: action.payload,
      }
    case SAVE_NEW_SEEDTYPE:
      return {
        ...state,
        allSeedTypes: [ action.payload, ...state.allSeedTypes ]
      }
    case UPDATE_SEEDTYPE:
      return {
        ...state,
        allSeedTypes: state.allSeedTypes.map(seedType => seedType.id === action.payload.id ? action.payload : seedType)
      }
    case DELETE_SEEDVARIANTTYPE:
      return {
        ...state,
        allSeedTypes: state.allSeedTypes.filter(seedType => seedType.id !== action.payload.id)
      }
    case DELETE_SEEDTYPE:
      return {
        ...state,
        allSeedTypes: state.allSeedTypes.filter(seedType => seedType.varietyNumber !== action.payload)
      }
    default:
      return state;
    }
}