import { Route, Routes } from "react-router-dom";
import List from '../../components/management/seedTypes/List';
import SeedVarietyTypeList from "../../components/seed-type-management/SeedVarietyTypeList";
import Create from "../../components/management/seedTypes/Create";

const SeedTypeRouting = () => {
    return (
        <div id="seed-type-management" className="seed-type-management-container process-container">
            <Routes>
                <Route path={'/list'} element={<List />} />
                <Route path={'/create'} element={<Create />} />
                <Route path={`/:seedTypeId/seed-variant-types/list`} element={<div id='seed-management' className='seed-management-container process-container'><SeedVarietyTypeList /></div>} />
            </Routes>
        </div>
    );
};

export default SeedTypeRouting;