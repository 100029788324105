const Card = ({ seedType, clickHandler }) => {

    const selectSeedType = () => {
        clickHandler(seedType.id);
    };

    return (!seedType.outdated ? (
        <div className='seed-type-card' onClick={selectSeedType}>
            <div className='seed-type-card-header' >
                <div className='seed-type-header-name'>
                    {seedType.name}
                </div>
                <div className='seed-type-header-number'>
                    Nº {seedType.number}
                </div>
                <div className='seed-type-property-paragraph' >
                    {seedType.scientificName}
                </div>
            </div>
            <div className='seed-type-card-body'>
                <div className='seed-type-body-common-name-title' >
                    Common Name
                </div>
                <div className='seed-type-body-common-name' >
                    {seedType.commonName}
                </div>
                <div className='seed-type-body-created-title' >
                    Created
                </div>
                <div className='seed-type-body-created' >
                    {new Date(seedType.creationDate).toLocaleString('de-DE').replace(',', '')}
                </div>
            </div>
        </div>
    ) : (
        <div className='seed-type-card-outdated' onClick={selectSeedType}>
            <div className='seed-type-card-header-outdated' >
                <div className='seed-type-header-name'>
                    {seedType.name}
                </div>
                <div className='seed-type-header-number'>
                    Nº {seedType.number}
                </div>
                <div className='seed-type-property-paragraph' >
                    {seedType.scientificName}
                </div>
            </div>
            <div className='seed-type-card-body-outdated'>
                <div className='seed-type-body-common-name-title' >
                    Common Name
                </div>
                <div className='seed-type-body-common-name' >
                    {seedType.commonName}
                </div>
                <div className='seed-type-body-created-title' >
                    Created
                </div>
                <div className='seed-type-body-created' >
                    {new Date(seedType.creationDate).toLocaleString('de-DE').replace(',', '')}
                </div>
                <div className='seed-type-body-deleted-title' >
                    Deleted
                </div>
                <div className='seed-type-body-deleted' >
                    {new Date(seedType.outdated).toLocaleString('de-DE').replace(',', '')}
                </div>
            </div>
        </div>
    )
    );
};

export default Card;