import React, { useContext, useEffect } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';

const WeighingTray = ({ setScaleTitle }) => {
    const { occupancyArray, additionalProcessInformation } = useContext(ProcessContext);
    const items = [...Array(200).keys()].reverse();

    const traySlotItems =
        additionalProcessInformation?.selectedPlantSlotOrdinalNumbers;

    const nextTraySlotItem =
        additionalProcessInformation?.plantSlotOrdinalNumber;

    const renderSlot = (slotNr) => {
        const isSelected = slotNr == nextTraySlotItem;

        const shoudBeMarked = traySlotItems.includes(slotNr);

        const classAdd = shoudBeMarked ? 'tray-item mark' : 'tray-item';

        return (
            <div key={slotNr} className={`${classAdd}`} data-selected={isSelected}>
                <p className='tray-item-text'>{(shoudBeMarked) && slotNr + 1}</p>
            </div>
        );
    };

    return (
        <div className='weighing-tray-container'>
            <div className='transplanted-tray'>{items.map((item) => renderSlot(item))}</div>
        </div>
    );
};

export default WeighingTray;
