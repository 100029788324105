import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import UIContext from '../../contexts/ui/UIContext';
import { HttpAgent } from '../../utility/HttpAgent';
import Button from '../layout/Button';

import { USERS_BASE_URL } from '../../constants/routes/api';

const AddGlobalUser = ({ cancelHandler, saveHandler }) => {
  const { setInfo, addNotification } = useContext(UIContext);

  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const user = {
      email: '',
      role: 1
    }

  const initialState = {
    email: {
      value: user.email ?? '',
      touched: false,
      validate: function (text) {
        return re.test(text);
      },
      isValid: false,
      placeholder: '',
    },
    role: {
      value: user.role ?? 0,
      touched: true,
      validate: function (role) {
        return true;
      },
      isValid: true,
      placeholder: '',
    },
  };

  const [userState, setUserState] = useState(initialState);
  const [role, setRole] = useState(3);

  useEffect(() => {
    setInfo('Add New Global Admin');

    return () => setInfo('Select a process');
  }, []);

  const isStateValid = Object.values(userState)
    .map((entry) => entry.isValid)
    .every((isValid) => isValid);

  const onChange = (event) => {
    event.persist();
    
    const key = event.currentTarget.name;
    const value = event.currentTarget.value;
    setUserState((prev) => ({
      ...prev,
      [key]: {
        ...userState[key],
        value,
        isValid: userState[key]?.validate(value),
        touched: true,
      },
    }));
  };

  const createUser = () => {
    const userProperties = Object.entries(userState).reduce((acc, [key, valueObject]) => {
      return { ...acc, [key]: valueObject.value };
    }, {});

    return userProperties;
  };

  const saveNewUser = async (newUser) => {
    try {   
      newUser.role = true;   
      const userCreationRequest = {
        data: {
          email: newUser.email
        }
      }

      const globalAdminAssignRequest = {
        data: {
          isGlobalAdmin: true
        }
      }
      
      const userCreationResult = await HttpAgent.post(`api/v1/users`, userCreationRequest);
      await HttpAgent.put(`${USERS_BASE_URL}/${userCreationResult.data.id}/role`, globalAdminAssignRequest);

    } catch (error) {
      addNotification(error.message, 'error');
    } finally {
      // await setAccessibleTenants();
    }
  };

  const handleSave = async () => {
    await saveHandler(createUser());
    addNotification("Successfully added global admin.", 'success');
    reset();
    setInfo('Global User Management');
    
  };

  const reset = () => {
    setUserState(initialState);
  }; 

  useEffect(() => {
  }, [userState]);

  const renderLabel = () => {
    return role === 2 ? 'Tenant Admin' : 'Tenant User';
  };
  
  return (
    <Card className='seed-card'>
      <CardHeader className='seed-card-body'>
        <input
          className='h4-input seed-property-input-field header-input'
          type='text'
          minLength='3'
          value='Add Global Admin'
          onChange={onChange}
          name='name'
          id='name'
          placeholder=''
          disabled={true}
        />
      </CardHeader>
      <CardBody>
        <Fragment>
          <div className='card-body-input-row'>
            <label className='input-label' htmlFor='email'>Email&nbsp;</label>
            <input
              // type='textarea'
              type='text'
              // wrap='hard'
              name='email'
              className={
                userState.email.isValid || !userState.email.touched
                  ? 'seed-property-input-field italic mb-4'
                  : 'seed-property-input-field italic invalid-field mb-4'
              }
              minLength='3'
              value={userState.email.value}
              placeholder={userState.email.placeholder}
              onChange={onChange}
              disabled={false}
              // style={{ whiteSpace: 'pre-wrap' }}
            />
            <Button
              clickHandler={() => {}}
              label={'Global Admin'}
              disabled={true}
              icon={"fas fa-globe"}
            />
          </div>
        </Fragment>
      </CardBody>
      <CardFooter>
      <div className='card-button seed-card-footer'>
        <Fragment>
          <Button className='seed-card-btn' label='Cancel' clickHandler={cancelHandler} />
          <Button className='seed-card-btn' label='Create' clickHandler={handleSave} disabled={!isStateValid} />
        </Fragment>
      </div>
      </CardFooter>
      
    </Card>
  );
};
export default AddGlobalUser;
