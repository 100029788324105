import React, { Fragment, useContext, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';

import BooleanComponent from '../../layout/BooleanComponent';
import StatusBarButton from '../../layout/StatusBarButton';
import TransplantingOverview from './overview/TransplantingOverview';
import Loading from '../../ui-elements/Loading';


const ConfirmTransplanting = () => {
  const { activeProcessId, continueProcess, additionalProcessInformation, processFinished } = useContext(ProcessContext);
  const { loading } = useContext(UIContext);

  const navigate = useNavigate();

  const [confirmed, setConfirmed] = useState(false);

  const confirmTransplanting = useCallback(async () => {
    try {
      setConfirmed(true);
      await continueProcess(activeProcessId, true);
      navigate('/');

    } catch (error) {
      console.error(error);
    }
  }, [activeProcessId, continueProcess]);

  return loading ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      {/* {!confirmed ??  */}
        <TransplantingOverview />
        {/* } */}
      <StatusBarButton
        label='Confirm'
        icon='fas fa-check'
        reversed={true}
        clickHandler={confirmTransplanting}
        statusSlot={5}
      />
    </Fragment>
  );
};

export default ConfirmTransplanting;
