export class Utilities {
    static truncateText(block, limit = 16, charsToDisplay = 14) {
      let text = block?.seedName ?? block.occupancy;
      let realLimit = block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber + 1;
      let realCharsToDisplay = realLimit - 2;
      
      if (text.length >= realLimit) {
        return text.substring(0, realCharsToDisplay) + '...';
      }
  
      return text;
    };

    static formatDate(date) {
      if(typeof date === 'string') {
        date = new Date(date);
      }

      return date.toLocaleString('de-DE', { year: 'numeric', month: '2-digit', day: '2-digit' });
    }
}