import PropTypes from 'prop-types';
import React, { useState } from 'react';
import Overlay from './Overlay';
import useLongPress from '../../customHooks/useLongPress';

const Tooltip = ({ frontElement, triggeredBy, clickHandlerOnLongPress, children }) => {
  const [show, setShow] = useState(false);

  const longPressHandler = () => {
    setShow(true);
  }

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const longPressEvent = useLongPress(longPressHandler, clickHandlerOnLongPress, defaultOptions);

  const triggerHandler = (() => {
    switch (triggeredBy) {
      case 'hover':
        return { onMouseEnter: () => setShow(true) };
      case 'click':
        return { onClick: () => setShow(true) };
      case 'longpress':        
        return longPressEvent;
      default:
        return { onClick: () => setShow(true) };
    }
  })();

  return (
    <div className='tooltip-container'>
      <Overlay open={show} closeHandler={() => setShow(false)} />
      <div className='tooltip-front' {...triggerHandler}>
        {frontElement}
      </div>
      <div
        className='tooltip-body'
        style={{
          display: show ? 'flex' : 'none',
        }}
      >
        {children}
      </div>
    </div>
  );
};

Tooltip.propTypes = {
    frontElement: PropTypes.object.isRequired,
    triggeredBy: PropTypes.string,
}

export default Tooltip;
