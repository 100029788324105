import React, { Fragment, useContext, useState } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';
import StatusBarButton from '../../layout/StatusBarButton';
import NumberPad from '../../ui-elements/NumberPad';
import Loading from '../../ui-elements/Loading';
import UIContext from '../../../contexts/ui/UIContext';

import { useNavigate } from 'react-router-dom';

const TraySelection = ({ forwardTo }) => {
  const { loading } = useContext(UIContext);
  const navigate = useNavigate();
  const { continueProcess, activeProcessId, notifyError } = useContext(ProcessContext);

  const [display, setDisplay] = useState({
      prefix: 't',
      value: ''
  });

  const proceedToSeedTypeSelection = async () => {
    try {
      await continueProcess(activeProcessId, display.prefix + display.value);
      navigate(forwardTo);
      
    } catch (error) {
      if (/t(?=[0-9])/i.test(error.message)) {
        notifyError(`You tried to enter an invalid tray. Please select a tray between 1 and ${process.env.REACT_APP_MAX_TRAY_NUMBER}`);
      }
      console.error(error);
    }
  };

  const setValue = (value) => {
      setDisplay(prev => ({ ...prev, value }))
  }

  return (loading ? 
    <Loading fullScreen /> :
    <Fragment>
      <NumberPad prefix={display.prefix} value={display.value} setValue={setValue} />
      {display.value && (
        <StatusBarButton
          type='inline'
          label='Continue'
          icon='fas fa-chevron-right'
          reversed={true}
          statusSlot={5}
          clickHandler={proceedToSeedTypeSelection}
        />
      )}
    </Fragment>
  );
};

export default TraySelection;
