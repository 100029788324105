import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { Route, Routes } from 'react-router-dom';
import SelectableState from '../../../contexts/local/selectable/SelectableState';
import ProcessContext from '../../../contexts/process/ProcessContext';
import EntitySelector from '../../ui-elements/EntitySelector';
import PanelSelector from '../transplanting/PanelSelector';
import ConfirmPanelDiscarding from './panel/ConfirmPanelDiscarding';
import PanelSelection from './panel/PanelSelection';
import ConfirmPlantDiscarding from './tray/ConfirmPlantDiscarding';
import OccupiedTraySlotSelector from './tray/OccupiedTraySlotSelector';

const Discarding = () => {
  const location = useLocation();
  const path = '/discarding';

  const { occupancyArray, additionalProcessInformation, finishProcess } = useContext(ProcessContext);
  const [availableItems, setAvailableItems] = useState([]);


  useEffect(() => {
    return () => {
      finishProcess();
    }
  }, []);

  useEffect(() => {
    if (location.pathname === path + '/tray') {
      setAvailableItems(occupancyArray.filter((item) => !item.isAvailable).map((item) => item.index));
    } else if (location.pathname === path + '/panel') {
      if (additionalProcessInformation.endOfLife === true) {
        setAvailableItems(
          occupancyArray
            .map((channel) =>
              channel.plantSlotBlocks
                .filter((block) => block.isOccupied && block.harvestCount !== 0)
                .map((block) => block.startPlantSlotOrdinalNumber)
            )
            .flat()
        );
      }
      else {
        setAvailableItems(
          occupancyArray
            .map((channel) =>
              channel.plantSlotBlocks
                .filter((block) => block.isOccupied)
                .map((block) => block.startPlantSlotOrdinalNumber)
            )
            .flat()
        );
      }
    }
  }, [occupancyArray, location.pathname]);

  return (
    <div
      id='discarding-container'
      className='process-container'
      style={{ height: location.pathname === path ? 'unset' : '100%' }}
    >
      <Routes>
        <Route
          path={'/'}
          element={
            !additionalProcessInformation?.endOfLife ? (
              <EntitySelector forwardTo={path} />
            ) : (
              <PanelSelector forwardTo={path + '/panel'} />
            )
          }
        />

        {/* Tray Discarding Section */}
        <Route
          path={`/tray`}
          element={
            <SelectableState availableItems={availableItems}>
              <OccupiedTraySlotSelector forwardTo={`${path}/confirm-tray-slot-discarding`} />
            </SelectableState>
          }
        />
        <Route path={`/confirm-tray-slot-discarding`} element={<ConfirmPlantDiscarding forwardTo={'/'} />} />

        {/* Panel Discarding Section */}
        <Route
          path={`/panel`}
          element={
            <SelectableState availableItems={availableItems}>
              <PanelSelection forwardTo={`${path}/panel/confirm-panel-discarding`} />
            </SelectableState>
          }
        />
        <Route path={`/panel/confirm-panel-discarding`} element={<ConfirmPanelDiscarding forwardTo={'/'} />} />
      </Routes>
    </div>
  );
};

export default Discarding;
