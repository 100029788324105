import { 
  GET_MANY_HARVESTING_PROCEDURES, SET_LOADING
} from "../../types/contextTypes";

export default (state, action) => {
  switch(action.type){      
      case GET_MANY_HARVESTING_PROCEDURES:
          return{
              ...state,
              allHarvestingProcedures: action.payload
          }; 
      case SET_LOADING:
        return {
          ...state,
          loading: action.payload
        }     
      default:
          return state;
  };
};