import {
  CLEAR_INFO,
  CLEAR_NOTIFICATION,
  CLEAR_ERROR,
  SET_INFO,
  SET_IS_SELECTING,
  SET_LOADING,
  SET_NOTIFICATION,
  SET_SLOT_SELECTION_END,
  SET_SLOT_SELECTION_START,
  SET_LOADING_ELEMENT_NAME,
  CLEAR_INFORMATION,
  SET_PERSISTANT_NOTIFICATION,
  CLEAR_PERSISTANT_NOTIFICATION,
} from '../types/contextTypes';
export default (state, action) => {
  switch (action.type) {
    case SET_INFO:
      return {
        ...state,
        info: action.payload,
      };
    case CLEAR_INFO:
      return {
        ...state,
        info: '',
      };
    case SET_NOTIFICATION:
      if (action.payload.type === 'error-stay') {
        return {
          ...state,
          error: action.payload.text,
          errorBoundary: action.payload.errorBoundary
        }
      }
      if (action.payload.type === 'info-stay') {
        return {
          ...state,
          information: action.payload.text
        }
      }
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case CLEAR_ERROR:
      return {
        ...state,
        error: null,
        errorBoundary: null
      };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((item) => item.id !== action.payload),
      };
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
        loadingElementName: action.payload === false ? '' : state.loadingElementName,
      };
    case SET_LOADING_ELEMENT_NAME:
      return {
        ...state,
        loadingElementName: action.payload,
      };
    default:
      break;
  }
};
