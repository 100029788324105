import React from 'react';

const PlantBlockDiscardConfirm = ({ block, channelNr, isSelected, index, ...rest }) => {
    const blockCode = `${channelNr + 1}/${index + 1}`;
    const slotSpan = block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber;
  
    const getIcon = () => {
      if(isSelected) return <i className='fas fa-times plant-icon'></i>;

      if(block.isOccupied) return <i className='fas fa-seedling plant-icon'></i>;

      return null;
    }

    return (
      <div
        className='channel-block discarding-confirm'
        data-selected={isSelected}
        data-occupied={block.isOccupied}
        {...rest}
      >
        {getIcon()}
        {block.isOccupied && !isSelected && <span className='plant-name'>{block.seedName}</span>}
        {slotSpan >= 5 && <span className='block-code'>{blockCode}</span>}
      </div>
    );
}

export default PlantBlockDiscardConfirm
