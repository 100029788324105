import React, { Fragment, useContext, useEffect } from 'react';
import { useNavigate, Route, Routes, useLocation } from 'react-router-dom';
import PanelSelector from '../transplanting/PanelSelector';
import ReportingOnPlants from '../reporting/ReportOnPlants';
import SubmitPhoto from './SubmitPhoto';
import ProcessContext from '../../../contexts/process/ProcessContext';

const Photographing = () => {
  const { processFinished, finishProcess } = useContext(ProcessContext);

  const path = '/photo';
  const location = useLocation();
  const navigate = useNavigate();
  const style = location.pathname === path + '/panel/plant-selection' ? {
    height: location.pathname === path ? 'unset' : '100%',
    display: 'flex',
    justifyContent: 'center'
  } : {
    height: location.pathname === path ? 'unset' : '100%',
    display: 'flex',
  };

  useEffect(() => {
    return () => {
      finishProcess();
    }
  },[]);

  useEffect(() => {
    if (processFinished) {
      navigate("/", { replace: true });
    }
  }, [processFinished, navigate]);

  return (
    <div id='photographing-container' className='process-container' style={style}>
      <Routes>
        <Route path={'/'} element={<PanelSelector forwardTo={`${path}/panel/plant-selection`} />} />
        <Route path={`/panel/plant-selection`} element={<ReportingOnPlants process="photo" sourceFromProps='panel' />} />
        <Route path={`/submit-report`} element={
          <Fragment>
            <div className="submit">{!processFinished ? <SubmitPhoto /> : null}</div>
          </Fragment>
        }/>
      </Routes>
    </div>
  );
};

export default Photographing;

