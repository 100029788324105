import React from 'react';

const Loading = ({ fullScreen, inline, displayText = '', label = '' }) => {

  return (
    <div className={`loading-container ${inline ? 'inline' : ''}`}>
      {label && <div>{label}</div>}<div className={`loader ${fullScreen ? 'fullScreen' : ''} `}></div>
      {displayText && <div className='loader-text'><h2 className='loader-text-animation'>{displayText}</h2></div>}
    </div>
  );
};
export default Loading;
