import axiosClient from "../../http-common";

class ProduceTypesApiService{
    addProduceType(tenantId, newProduceType){
        return axiosClient.post(`/api/v1/tenants/${tenantId}/produceTypes`, newProduceType);
    };
    
    deleteProduceType(tenantId, produceTypeId){
        return axiosClient.delete(`/api/v1/tenants/${tenantId}/produceTypes/${produceTypeId}`);
    };

    getManyProduceTypes(tenantId){
        return axiosClient.get(`/api/v1/tenants/${tenantId}/produceTypes`);
    };

    getProduceType(tenantId, produceTypeId){
        return axiosClient.get(`/api/v1/tenants/${tenantId}/produceTypes/${produceTypeId}`);
    };

    updateProduceType(tenantId, produceTypeId, updateProduceType){
        return axiosClient.put(`/api/v1/tenants/${tenantId}/produceTypes/${produceTypeId}`, updateProduceType);
    };
};

export default new ProduceTypesApiService();