import React, { useState, useContext, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardFooter } from 'reactstrap';
import UIContext from '../../contexts/ui/UIContext';
import { HttpAgent } from '../../utility/HttpAgent';
import Button from '../layout/Button';
import StatusBarButton from '../layout/StatusBarButton';
import ProcessContext from '../../contexts/process/ProcessContext';
import { HELP_DESK_LINK } from '../../constants/helpDesk';

const ChangePassword = () => {
  const navigate = useNavigate();
  const { setInfo, addNotification } = useContext(UIContext);
  const { notifyError } = useContext(ProcessContext);

  const emptyPwState = {
    value: '',
    touched: false,
  };

  const [oldPw, setOldPw] = useState({
    value: '',
    touched: false,
  });

  const [newPw, setNewPw] = useState({
    value: '',
    touched: false,
  });

  const [confirmPw, setConfirmPw] = useState({
    value: '',
    touched: false,
  });

  const [pwError, setPwError] = useState({
    type: '',
    message: 'Enter a password',
    error: false,
  });

  useEffect(() => {
    setInfo('Change Password');
  }, [setInfo]);

  // useEffect(() => {    
  //   setIsValid(stateIsValid());
  // }, [newPw.value]);  
  
  const reset = () => {
    setOldPw(emptyPwState);
    setNewPw(emptyPwState);
    setConfirmPw(emptyPwState);
  };  

  const handleSave = async () => {
    let url = 'api/v1/authorization/password';
    let oldBase64 = btoa(oldPw.value);
    let newBase64 = btoa(newPw.value);
    let confirmBase64 = btoa(newPw.value);

    
    let body = {
      old: oldBase64,
      new: newBase64,
      confirm: confirmBase64,
    };

    await HttpAgent.put(url, body)
    .then(() => {
      addNotification("Password successfully changed.", "success");
      navigate('/');
    })
    .catch((error) => {
      handleError(error);
    })
  };

  const handleError = (error) => {
    if (error.message.includes("401")) {
      notifyError(<>We have encountered an issue while changing the password. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
    }
    else{
      notifyError(<>We have encountered an issue on our side while changing the password. Please contact the support team {HELP_DESK_LINK}.</>);
    }
    
    setPwError({
      type: '',
      message: error.response?.data?.data?.cognitoMessage,
      error: true,
    })
  };  

  const hasMoreThanEight = () => {
    return newPw?.value?.length >= 8;
  };

  const hasUppercaseLetter = () => {
    const regExUppercase = /^(.*)([A-Z])(.*)$/;   
    return regExUppercase.test(newPw.value);
  };

  const hasLowercaseLetter = () => {
    const regExLowercase = /^(.*)([a-z])(.*)$/;    
    return regExLowercase.test(newPw.value);
  };

  const hasDigit = () => {
    const regExDigit = /^(.*)([0-9])(.*)$/;       
    return regExDigit.test(newPw.value);
  };

  const hasSpecial = () => {
    const regExSpecial = /^(.*)([\^$*.[\]{}()?"!@#%&/\\,><´:])(.*)$/;

    return regExSpecial.test(newPw.value);
  };

  const stateIsValid = () => {
    return hasDigit() && hasLowercaseLetter() && hasUppercaseLetter() && hasMoreThanEight() && hasSpecial() && isSame()
  };  

  const isSame = () => {
    return confirmPw.value === newPw.value && confirmPw.touched;
  };

  const changeOldHandler = async (event) => {
    event.persist();
    let newValue = { ...newPw, value: event.target.value, touched: true };
    setOldPw(newValue);
  };

  const changeNewHandler = async (event) => {
    event.persist();    
    let newValue = { ...oldPw, value: event.target.value, touched: true };
    setNewPw(newValue);
  };
  
  const changeConfirmHandler = async (event) => {
    event.persist();    
    let newValue = { ...confirmPw, value: event.target.value, touched: true };
    setConfirmPw(newValue);
  };

  return (
    <div className='change-pw-container seed-management-container process-container'>
      <div className='seed-list-overview'>
        <Card className='seed-card'>
          <CardBody className='mb-0'>
            <form>
            <input type="text" name="username" id="username" autoComplete="username" style={{ display: 'none' }} />
            <div className='card-body-input-row mb-2'>
              <label htmlFor='old'>Old Password&nbsp;</label>
              <br />
              <input name='old' id='old' type='password' value={oldPw.value} onChange={changeOldHandler} style={{"width": "100%"}} autoComplete='current-password'></input>
            </div>
            <div className='card-body-input-row mt-8'>
              <label htmlFor='new'>New Password&nbsp;</label>
              <br />
              <input name='new' id='new' type='password' value={newPw.value} onChange={changeNewHandler} style={{"width": "100%"}} autoComplete='new-password'></input>
            </div>
            <div className='card-body-input-row mt-2'>
              <label htmlFor='confirm'>Confirm New Password&nbsp;</label>
              <br />
              <input
                name='confirm'
                id='confirm'
                type='password'
                value={confirmPw.value}
                onChange={changeConfirmHandler}
                style={{"width": "100%"}}
                autoComplete='new-password'
              ></input>
            </div>
            </form>
            <div className='card-body-input-row mt-4 mb-0' style={{"fontSize": '0.8rem'}}>
              <p>
                <span className={hasMoreThanEight() ? 'green-text' : 'red-text'}>
                  {hasMoreThanEight() ? <i className='fas fa-check'></i> : <i className='fas fa-times'></i>}&nbsp;Password must
                  have 8 characters or more
                </span>
                <br />

                <span className={hasUppercaseLetter() ? 'green-text' : 'red-text'}>
                  {hasUppercaseLetter() ? <i className='fas fa-check'></i> : <i className='fas fa-times'></i>}&nbsp;Password
                  must have at least one uppercase letter
                </span>
                <br/>
                <span className={hasLowercaseLetter() ? 'green-text' : 'red-text'}>
                  {hasLowercaseLetter() ? <i className='fas fa-check'></i> : <i className='fas fa-times'></i>}&nbsp;Password must
                  have at least one lowercase letter
                </span>
                <br />
                <span className={hasDigit() ? 'green-text' : 'red-text'}>
                  {hasDigit() ? <i className='fas fa-check'></i> : <i className='fas fa-times'></i>}&nbsp;Password must
                  have at least one digit
                </span>
                <br />
                <span className={hasSpecial() ? 'green-text' : 'red-text'}>
                  {hasSpecial() ? <i className='fas fa-check'></i> : <i className='fas fa-times'></i>}&nbsp;Password must
                  have at least one special character (@$!%*?=&amp;) 
                </span>
                <br/>
                <span className={isSame() ? 'green-text' : 'red-text'}>
                  {isSame() ? <i className='fas fa-check'></i> : <i className='fas fa-times'></i>}&nbsp;New password and confirm password must match 
                </span>
              </p>
            </div>
            {pwError.error && <p style = {{"border": "2px solid red", "backgroundColor": "whitesmoke", "fontWeight": "700"}} className='invalid'>{pwError.message}</p>}
          </CardBody>
          <CardFooter>
            <div className='card-button seed-card-footer'>
              <Fragment>
                <Button className='seed-card-btn' label='Clear All' clickHandler={reset} />
                <Button className='seed-card-btn' label='Change' clickHandler={handleSave} disabled={!stateIsValid()} />
              </Fragment>
            </div>
          </CardFooter>
        </Card>
        <StatusBarButton
          label='Abort'
          clickHandler={() => navigate('/')}
          statusSlot={3}
          icon='fas fa-ban'
          type='inline'
        />
      </div>
    </div>
  );
};

export default ChangePassword;
