import React, { useEffect, useState, useContext, Fragment } from 'react';
import UIContext from '../../contexts/ui/UIContext';
import { HttpAgent } from '../../utility/HttpAgent';
import GlobalUserEditor from './EditGlobalUser';
import { Card } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import StatusBarButton from '../layout/StatusBarButton';
import AddGlobalUser from './AddGlobalUser';
import { USERS_BASE_URL } from '../../constants/routes/api';
import AuthContext from '../../contexts/auth/AuthContext';
import Loading from '../ui-elements/Loading';
import ProcessContext from '../../contexts/process/ProcessContext';
import { HELP_DESK_LINK } from '../../constants/helpDesk';

const GlobalUserManager = () => {
  const [globalUsers, setGlobalUsers] = useState(null);
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const { setInfo, addNotification } = useContext(UIContext);
  const { updateGlobalAdminRole } = useContext(AuthContext);
  const { notifyError } = useContext(ProcessContext);

  const navigate = useNavigate();

  // Sets the information and gets user data.
  useEffect(() => {
    setInfo('Global User Management');
    load();
  }, []);

  // Changes a user's role.
  const changeRole = (user) => {
    updateGlobalAdminRole(user.id, !user?.isGlobalAdmin).then(async (result) => {
      await load();
      setLoading(false);
    });
  };

  // Calls the "Get all users" api endpoint.
  const getAllUsers = async () => {
    try {
      const result = await HttpAgent.get(USERS_BASE_URL);
      return result?.data;
    } catch (err) {
      console.error('Error while getting all users. ', err);
    }
  };

  useEffect(() => { }, [globalUsers]);

  // Gets all users.
  const load = () => {
    setLoading(true);
    getAllUsers().then((listOfUsers) => {
      setGlobalUsers(listOfUsers?.map((result) => result));
    })
      .catch((err) => console.error('Error while getting user. ', err))
      .then(() => {
        setLoading(false);
      })
      .catch((err) => console.error('Error while getting users. ', err));;
  };

  // Toggles the adding state.
  const toggleAdding = () => {
    setAdding(!adding);
  };

  const saveNewUser = async (newUser) => {
    try {
      const userCreationRequest = {
        data: {
          email: newUser.email,
          isGlobalAdmin: true
        },
      };
      await HttpAgent.post(`api/v1/users`, userCreationRequest);
    } catch (error) {
      if (error.message.includes("401")) {
        notifyError(<>We have encountered an issue while creating the new global user. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else {
        notifyError(<>We have encountered an issue on our side while creating the new global user. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.error(error);
    } finally {
      setAdding(false);
      await load();
    }
  };

  return loading ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      <div id='seed-management' className='seed-management-container process-container'>
        <div className='seed-list-overview'>
          {!adding && (
            <Card className='seed-card' onClick={toggleAdding}>
              <h1 className='add-seed-symbol'>
                <i className='fas fa-plus'></i>
              </h1>
            </Card>
          )}
          {adding && <AddGlobalUser cancelHandler={toggleAdding} saveHandler={saveNewUser} />}
          {globalUsers?.length > 0 &&
            globalUsers.map((user) => (
              <GlobalUserEditor
                key={user.id}
                user={user}
                changeRoleHandler={changeRole}
                disableRoleChange={true}
                reloadHandler={load}
              />
            ))}
        </div>
      </div>
      <StatusBarButton
        label='Done'
        clickHandler={() => navigate('/')}
        statusSlot={3}
        icon='fair fai-circle-check'
        type='inline'
      />
    </Fragment>
  );
};

export default GlobalUserManager;
