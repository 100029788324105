import React from "react";
import { Modal } from "reactstrap";
import Button from "../../layout/Button";

const ConfirmDeletionModal = ({ show = false, email, onConfirm, onCancel }) => {

    const handleCancel = () => {
        onCancel();
    }

    const handleConfirm = () => {
        onConfirm();
    }

    return (
        <Modal isOpen={show} className='modal-config confirm-deletion-modal'>
            <div className='confirm-deletion-modal-container'>
                <div className='confirm-deletion-modal-header-container'>
                    <div className="confirm-deletion-modal-header">DELETE USER?</div>
                </div>
                <div className="confirm-deletion-modal-body">
                    You are about to delete the user with the email address {email}
                    <br />
                    <br />
                    Are you absolutely sure, that you want to delete this user?
                </div>
                <Button label='No' className="confirm-deletion-modal-cancel-button" clickHandler={handleCancel} />
                <Button label='Yes' className="confirm-deletion-modal-confirm-button" clickHandler={handleConfirm} />
            </div>
        </Modal>
    );
}

export default ConfirmDeletionModal;