import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card } from 'reactstrap';
import UIContext from '../../contexts/ui/UIContext';
import StatusBarButton from '../layout/StatusBarButton';
import TemplateEditorCard from './TemplateEditorCard';
import TemplateAddingCard from './TemplateAddingCard';
import SeedTypeManagementContext from '../../contexts/seed-type-management/SeedTypeManagementContext';
import AuthContext from '../../contexts/auth/AuthContext';
import { HttpAgent } from '../../utility/HttpAgent';
import ProcessContext from '../../contexts/process/ProcessContext';
import { HELP_DESK_LINK } from '../../constants/helpDesk';

const TemplateManagement = () => {
  const navigate = useNavigate();

  const { loadLabelPrinterTemplateNames } = useContext(SeedTypeManagementContext);
  const { currentTenant, loadAndSetAccessibleTemplatesAsync, accessibleTemplates } = useContext(AuthContext);
  const { setInfo, addNotification } = useContext(UIContext);
  const {notifyError} = useContext(ProcessContext);
  const [adding, setAdding] = useState(false);

  const addHandler = () => setAdding(true);

  const deleteHandler = async (templateId) => {
    try {

      let url = `api/v1/Tenants/${currentTenant?.tenantId}/LabelPrinterTemplates/${templateId}`;
      await HttpAgent.delete(url);     
      addNotification('Successfully deleted template.', 'success');
    } catch (error) {
      if (error.message === 'Conflict') {
        notifyError("The label printer template is still in use and can't be deleted.")
      }
      else if (error.message === 'Not found') {
        notifyError(<>We have encountered an issue while deleting the label printer template. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else {
        notifyError(<>We have encountered an issue on our side while deleting the label printer template. Please contact support team {HELP_DESK_LINK}.</>);
      }
      console.error(error);
    } finally {
      await loadAndSetAccessibleTemplatesAsync(currentTenant);
    }
  };

  const createTemplateSender = async (newTemplate) => {
    try {
      let body = {
        id: null,
        ...newTemplate
      };
      
      let url = `api/v1/Tenants/${currentTenant.tenantId}/LabelPrinterTemplates`;
      await HttpAgent.post(url, body);
      addNotification('Successfully added new template.', 'success');
    } catch (error) {
      if (error.message === 'Tenant not found.') {
        notifyError(<>We have encountered an issue while creating the new label printer template. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else{
        notifyError(<>We have encountered an issue on our side while creating the new label printer template. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.error(error);
    } finally {
      setAdding(false);
      await loadAndSetAccessibleTemplatesAsync(currentTenant);
    }
  };

  const handleUpdate = async (updatedTemplate) => {
    if (!updatedTemplate) return;
    
    try {
      let body = {
        id: updatedTemplate.id,
        name: updatedTemplate.name,
        description: updatedTemplate.description,
        template: updatedTemplate.template,
        fileName: updatedTemplate.fileName
      };
      let url = `api/v1/Tenants/${currentTenant?.tenantId}/LabelPrinterTemplates/${updatedTemplate.id}`
      await HttpAgent.put(url, body);     

      addNotification('Successfully updated template.', 'success');
    } catch (error) {
      if (error.message === 'Not found.') {
        notifyError(<>We have encountered an issue while updating the label printer template. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else{
        notifyError(<>We have encountered an issue on our side while updating the label printer template. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.error(error);
    } finally {
      await loadAndSetAccessibleTemplatesAsync(currentTenant);
    }
  };

  const cancelHandler = () => setAdding(false);

  useEffect(() => {
    setInfo('Label Printer Template Management');
  }, []);
  
  return (
    <Fragment>      
      <div className='seed-management-container process-container'>
        <div className='seed-list-overview'>
          {!adding && (
            <Card className='seed-card' onClick={addHandler}>
              <h1 className='add-seed-symbol'>
                <i className='fas fa-plus'></i>
              </h1>
            </Card>
          )}
          {adding && (
            <TemplateAddingCard
              template={null}
              editOn={true}
              createTemplateHandler={createTemplateSender}
              cancelHandler={cancelHandler}
            />
          )}

          {accessibleTemplates &&
            accessibleTemplates?.filter((template) => template.isActive).map((template) => (
              <TemplateEditorCard
                key={template.id}
                template={template}
                editOn={false}
                deleteHandler={deleteHandler}
                updateHandler={handleUpdate}
              />
            ))}
        </div>
      </div>

      <StatusBarButton
        icon='fair fai-circle-check'
        label='Done'
        type='inline'
        clickHandler={() => navigate('/')}
        statusSlot={3}
      />
    </Fragment>
  );
};

export default TemplateManagement;
