import axiosClient from "../../http-common";

class UsersApiService {
    
  getUsersOfTenant(tenantId) {
    return axiosClient.get(`/api/v1/tenants/${tenantId}/users`);
  };

  getUser(userId) {
    return axiosClient.get(`/api/v1/users/${userId}`);
  };

  getUserBySearchTerm(searchTerm) {
    return axiosClient.get(`/api/v1/users/${searchTerm}`);
  };

  addUser(user) {
    let body = {
        data: user
    };
    return axiosClient.post(`/api/v1/users`, body);
  }
}

export default new UsersApiService();