import axiosClient from "../../http-common";

class ProcessesApiService {
    continueProcess(tenantId, containerId, processId, body, header){
        return axiosClient.put(`/api/v1/tenants/${tenantId}/containers/${containerId}/processes/${processId}/processsteps/next`, body, header);
    }

    revertProcess(tenantId, containerId, processId, header){
        return axiosClient.put(`/api/v1/tenants/${tenantId}/containers/${containerId}/processes/${processId}/processsteps/back`, header);
    }
}
export default new ProcessesApiService();