import React from 'react';
import { Card, CardBody, CardFooter, CardHeader } from 'reactstrap';
import CardBodyDetailRow from '../layout/Card/CardBodyDetailRow';

const SeedTypeCard = ({
  seedType,
  seedVariantTypes,
  clickHandler,
  seedTypeToEdit,
}) => {
  const classNameAddendum = seedType == seedTypeToEdit ? 'highlight' : '';

  return (   
    <Card className='seed-card' onClick={clickHandler}>
      <CardHeader className='seed-card-header' style={{ textAlign: 'center' }}>
        <h4 className='main-title'>{seedType.name}&nbsp;Nº {seedType.number}</h4>
        <h6 className='meta-title'>{seedType.scientificName}</h6>
      </CardHeader>
      <CardBody>
        <CardBodyDetailRow
          label='Manufacturer'
          value={seedVariantTypes.manufacturer}
        />
        <CardBodyDetailRow
          label='LOT Number'
          value={seedVariantTypes.lot}
        />
        <CardBodyDetailRow
          label='Seeds Left'
          value={seedVariantTypes.count ?? 'n/a'}
        />
      </CardBody>
      <CardFooter>
        <h5 className='footer-title'>{seedType.manufacturer}</h5>
      </CardFooter>
    </Card>
  );
};

export default SeedTypeCard;
