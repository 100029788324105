import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Auth } from 'aws-amplify';

/**
 * Starts a SignalR connection and retries in case of failure.
 * Retries up to the specified maxRetries, then waits for a defined interval before starting over.
 *
 * @param {object} connection - The SignalR connection instance.
 */
const startSignalRConnection = async (connection) => {
    const maxRetries = parseInt(process.env.REACT_APP_SIGNALR_MAX_RETRIES || '5', 10); // Maximum retries from env
    const retryInterval = parseInt(process.env.REACT_APP_SIGNALR_RETRY_INTERVAL || '30000', 10); // Retry interval in ms (30 seconds default)

    let retries = 0;

    const start = async () => {
        try {
            const match = connection.baseUrl.match(/\/hubs\/v1\/([^?]+)/);
            let hub = match[1];
            console.log(`Attempting to start SignalR connection to ${hub}...`);
            await connection.start();
            console.log(`Connected to ${hub}`);
        } catch (err) {
            retries++;
            console.error(`Failed to connect to ${connection.baseUrl}:`, err);

            if (retries < maxRetries) {
                const retryDelay = Math.min(1000 * retries, 10000); // Exponential backoff up to 10 seconds
                console.log(`Retrying connection in ${retryDelay}ms... (attempt ${retries}/${maxRetries})`);

                await new Promise(resolve => setTimeout(resolve, retryDelay)); // Delay before retrying
                await start(); // Retry starting the connection
            } else {
                console.error(`Max retries reached. Waiting ${retryInterval}ms before retrying...`);
                await new Promise(resolve => setTimeout(resolve, retryInterval)); // Wait before restarting retries
                retries = 0; // Reset retry count
                await start(); // Restart the connection attempt
            }
        }
    };

    await start();

    connection.onclose(async () => {
        console.warn(`Connection to ${connection.baseUrl} closed. Retrying...`);
        retries = 0; // Reset retries when connection closes
        await start();
    });
};

/**
 * Context for managing SignalR connections and providing SignalR methods to components.
 */
const SignalRContext = createContext();

/**
 * SignalRProvider component initializes SignalR connections for the given hubs and provides
 * a context for interacting with SignalR methods.
 *
 * @param {object} props - Component properties.
 * @param {React.ReactNode} props.children - Child components.
 * @param {Array} props.hubConfiguration - Array of hub configurations used for SignalR.
 */
export const SignalRProvider = ({ children, hubConfiguration }) => {
    const connectionsRef = useRef({}); // Holds SignalR connection instances by hub name
    const initializedRef = useRef(false); // Tracks if the connections have been initialized
    const [accessToken, setAccessToken] = useState(null); // Stores the access token
    const [isReady, setIsReady] = useState(false); // Tracks readiness of access token and hubConfiguration

    /**
     * Fetches the access token from AWS Amplify and sets it in the state.
     */
    useEffect(() => {
        const fetchAccessToken = async () => {
            try {
                const userAuthSession = await Auth.currentSession();
                const accessTokenRaw = userAuthSession.getAccessToken();
                setAccessToken(accessTokenRaw?.jwtToken); // Store the token
            } catch (err) {
                console.error('Failed to get access token:', err);
            }
        };

        fetchAccessToken(); // Fetch the token when the component mounts
    }, []);

    /**
     * Sets the readiness flag when both the access token and hub configuration are available.
     */
    useEffect(() => {
        if (accessToken && hubConfiguration && hubConfiguration.length > 0) {
            setIsReady(true); // Ready when both token and configuration are set
        }
    }, [accessToken, hubConfiguration]);

    /**
     * Disconnects all existing connections.
     */
    const stopAllConnections = async () => {
        for (const hubName in connectionsRef.current) {
            if (connectionsRef.current[hubName]?.current) {
                console.log(`Stopping connection for hub ${hubName}...`);
                await connectionsRef.current[hubName].current.stop(); // Stop each connection
            }
        }
        initializedRef.current = false; // Reset initialization status
    };

    /**
     * Initializes SignalR connections when the provider is ready, or when tenant/container changes.
     */
    useEffect(() => {
        if (isReady) {
            const initializeSignalR = async () => {
                console.log("Initializing SignalR connections...");

                // Clean up any existing connections when hubConfiguration changes
                await stopAllConnections();

                for (const hub of hubConfiguration) {
                    const createConnection = () => {
                        return new HubConnectionBuilder()
                            .withUrl(process.env.REACT_APP_FARMER_API_URL + hub.url, {
                                accessTokenFactory: () => accessToken, // Provide the token when needed
                            })
                            .configureLogging(LogLevel.Information)
                            .withAutomaticReconnect([0, 2000, 10000, 30000]) // Custom reconnect intervals
                            .build();
                    };

                    const connection = createConnection();
                    connectionsRef.current[hub.name] = { current: connection };

                    console.log(`Starting connection for hub ${hub.name}...`);
                    await startSignalRConnection(connection); // Start the connection with retry logic
                }
            };

            initializeSignalR();
            initializedRef.current = true;
        }

        return () => {
            stopAllConnections(); // Cleanup connections when component unmounts or when dependencies change
        };
    }, [isReady, hubConfiguration, accessToken]);

    /**
     * Registers a client-side function that listens for events from the server via SignalR.
     */
    const registerClientFunction = (hubName, functionName, callback) => {
        const connection = connectionsRef.current[hubName];
        if (connection && connection.current) {
            connection.current.on(functionName, callback);
        }
    };

    /**
     * Invokes a method on the SignalR server.
     */
    const invokeServerMethod = async (hubName, methodName, ...args) => {
        const connection = connectionsRef.current[hubName];
        if (connection && connection.current) {
            try {
                await connection.current.invoke(methodName, ...args);
            } catch (err) {
                console.error(`Error invoking ${methodName}:`, err.toString());
            }
        }
    };

    const signalRState = {
        registerClientFunction,
        invokeServerMethod,
        isReady
    };

    return (
        <SignalRContext.Provider value={signalRState}>
            {children}
        </SignalRContext.Provider>
    );
};

/**
 * Custom hook to access SignalR context.
 *
 * @returns {object} SignalR context value.
 */
export const useSignalRContext = () => useContext(SignalRContext);
