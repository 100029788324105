import React, { Fragment, useContext, useEffect, useState, useRef, useCallback } from 'react';
import UIContext from '../../contexts/ui/UIContext';
import Button from '../layout/Button';
import ProcessContext from '../../contexts/process/ProcessContext';

export const TemplateUpload = ({ changeHandler, labelText, originalReuploadLabelText, originalTemplate, updatedTemplate, textSize='1em' }) => {
  const { addNotification } = useContext(UIContext);
  const {notifyError} = useContext(ProcessContext);
  const inputRef = useRef(null);
  const [validXml, setValidXml] = useState(false);
  const [reuploadLabelText, setReuploadLabelText] = useState(originalReuploadLabelText)
  useEffect(() => {
    if(updatedTemplate?.fileName?.value) {
      updateReuploadLabelText(updatedTemplate?.fileName);
    }
  },[updatedTemplate?.fileName?.value])
  // Reads the image as byte array.
  const readFile = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsText(file);
    });
  };

  const updateReuploadLabelText = (newFileName) => {
    newFileName = updatedTemplate?.fileName?.value;
    setReuploadLabelText(reuploadLabelText.replace('<fileName>', updatedTemplate?.fileName?.value));
  }

  const validateXML = (data) => {
    var oParser = new DOMParser();
    var oDOM = oParser.parseFromString(data, 'application/xml');
    let errors = oDOM.getElementsByTagName('parsererror');
    if (errors.length) {
      throw new Error('File does not contain valid XML.');
    }
  };  

  // Handles click on the file upload button and "clicks" on the input element.
  const clickFileInput = () => {
    let input = document.getElementById('poca');
    input.click();
  };
  
  // Called if a file is uploaded.
  const onInputChange = async (event) => {
    try {
      let file = event.target.files[0];

      // // Now we need to extract the geodata or let the user know if there isn't any.
      let data = await readFile(file);
      let fileName = file.name;
      validateXML(data);
      setReuploadLabelText(originalReuploadLabelText);
      setValidXml(true);
      
      await changeHandler(data, fileName);

    } catch (error) {
      setValidXml(false);
      notifyError("The label printer template you tried to upload has the wrong type. Please provide a .tl file and try again.");
      console.error(error);
      changeHandler(null);
    } finally {
      //setLoading(false);
      // info!
    }
  };

  return (
    <div className='file-select'>
      <Button        
        className='upload-photo-btn'
        icon={'fas fa-file-code'}
        label={validXml ? reuploadLabelText : labelText}
        clickHandler={clickFileInput}
        style={{'marginTop':'20px', 'fontSize': `${textSize}`}}
      />
      <input ref={inputRef} type='file' id='poca' accept=".tl" onChange={onInputChange} style={{display: 'none'}} />
    </div>
  );
};
