import axiosClient from "../../http-common";

class SeedTypesApiService{
    addNewSeedType(tenantId, containerId, newSeedType) {
        return axiosClient.post(`/api/v1/tenants/${tenantId}/containers/${containerId}/seedtypes`, newSeedType);
    };

    deleteSeedType(tenantId, containerId, seedTypeId){
        return axiosClient.delete(`/api/v1/tenants/${tenantId}/containers/${containerId}/seedtypes/${seedTypeId}`);
    };

    getAllSeedTypes(tenantId, containerId, includeOutdated, include) {
        const queryParams = includeOutdated ? `?IncludeOutdated=${includeOutdated}` + (include ? `&include=${include}` : '') : include ? `?include=${include}` : '';
        return axiosClient.get(`/api/v1/tenants/${tenantId}/containers/${containerId}/seedtypes${queryParams}`)
    };

    getSeedType(tenantId, containerId, seedTypeId, includeOutdated) {
        const queryParams = includeOutdated ? `?IncludeOutdated=${includeOutdated}` : '';
        return axiosClient.get(`/api/v1/tenants/${tenantId}/containers/${containerId}/seedtypes/${seedTypeId}${queryParams}`);
    };

    updateSeedType(tenantId, seedTypeId, editedSeedType) {
        return axiosClient.put(`/api/v1/tenants/${tenantId}/seedtypes/${seedTypeId}`, editedSeedType);
    };
};

export default new SeedTypesApiService();