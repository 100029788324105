import { useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { TENANT_ADMIN } from '../../../constants/roles/roles';
import AuthContext from '../../../contexts/auth/AuthContext';
import UIContext from "../../../contexts/ui/UIContext";
import Card from './Card';
import StatusBarButton from "../../layout/StatusBarButton";

const List = () => {
    const { currentRoles, isGlobalAdmin, currentContainer, accessibleSeedTypes } = useContext(AuthContext);
    const { setInfo } = useContext(UIContext);
    const navigate = useNavigate();

    useEffect(() => {
        setInfo('Seed Type Management');
    }, []);

    const handleCreate = () => {
        navigate("/management/seed-types/create");
    };

    return <>
        <div className='seed-type-list'>
            {(isGlobalAdmin || currentRoles.includes(TENANT_ADMIN)) &&
                <div className='seed-type-create' onClick={handleCreate}>
                    <div className='seed-type-create-icon'>
                        <i className='fas fa-plus'></i>
                    </div>
                </div>}
            {accessibleSeedTypes?.filter((seedType) => !seedType.outdated).length > 0 &&
                currentContainer &&
                accessibleSeedTypes?.filter((seedType) => !seedType.outdated).sort((a, b) => {
                    var nameA = a.name.toUpperCase();
                    var nameB = b.name.toUpperCase();
                    if (nameA < nameB) {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                }).map((seedType) => <Card seedType={seedType} key={seedType.seedTypeId} />)}
        </div>
        <StatusBarButton
            label='Done'
            clickHandler={() => navigate('/')}
            statusSlot={3}
            icon='fair fai-circle-check'
            type='inline'
        />
    </>
}

export default List;