import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../../../contexts/process/ProcessContext';
import UIContext from '../../../../contexts/ui/UIContext';
import StatusBarButton from '../../../layout/StatusBarButton';
import Loading from '../../../ui-elements/Loading';
import Channel from './Channel';
import Panel from './Panel';
import PlantBlockDiscardConfirm from './PlantBlockDiscardConfirm';
import SelectableContext from '../../../../contexts/local/selectable/SelectableContext';

const ConfirmPanelDiscarding = ({ forwardTo }) => {
  const { occupancyArray, activeProcessId, continueProcess, resetProcessState, additionalProcessInformation } =
    useContext(ProcessContext);
  const { loading } = useContext(UIContext);

  const [confirmed, setConfirmed] = useState(false);

  const navigate = useNavigate();
  const confirmDiscarding = async () => {
    setConfirmed(true);

    try {
      await continueProcess(activeProcessId, true);
      resetProcessState();
      navigate(forwardTo);
    } catch (error) {
      console.error(error);
    }
  };
  return loading && confirmed ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      <Panel>
        {occupancyArray.map((channel) => (
          <Channel key={channel.channelNumber} channel={channel}>
            {channel.plantSlotBlocks.map((block, index) => (
              <PlantBlockDiscardConfirm
                key={`${channel.channelNumber}-${block.startPlantSlotOrdinalNumber}-${block.endPlantSlotOrdinalNumber}`}
                block={block}
                channelNr={channel.channelNumber}
                index={index}
                disabled={!block.isOccupied}
                // TODO: Modify logic to determine if block is selected
                isSelected={
                  additionalProcessInformation?.sourceSelection?.includes(block.startPlantSlotOrdinalNumber) &&
                  block.isOccupied
                }
                style={{
                  height: block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber + 1 + '%',
                }}
              />
            ))}
          </Channel>
        ))}
      </Panel>
      <StatusBarButton
        label='Confirm'
        icon='fas fa-check'
        reversed={true}
        clickHandler={confirmDiscarding}
        statusSlot={5}
      />
    </Fragment>
  );
};

export default ConfirmPanelDiscarding;
