import { Route, Routes, useNavigate } from "react-router-dom";
import Create from "../../components/management/containers/Create";
import Detail from '../../components/management/containers/Detail';
import ContainerList from '../../components/management/containers/List';
import Edit from "../../components/management/containers/Edit";

const ContainerRouting = () => {
    return(
        <Routes>
              <Route path={'/list'} element={<ContainerList />} />
                <Route path={'/create'} element={<Create />} />
                <Route path={'/:containerId/edit'} element={<Edit />} />                
                <Route path={'/:containerId/details'} element={<Detail />} />
            </Routes>
    );
};

export default ContainerRouting;