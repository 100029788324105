import React, { Fragment } from 'react'
import Button from '../layout/Button'

const NumberPadNumbersContainer = ({ disabled, clickHandler }) => {
    return (
        <Fragment>
        {[[1, 2, 3, 4, 5],[ 6, 7, 8, 9, 0]].map((group, index) => (
          <div key={index} className="numpad-btn-group">
            {group.map(num => (
              <Button
                key={num}
                label={num}
                disabled={disabled}
                clickHandler={() => clickHandler(num)}
              />
            ))}
          </div>
        ))}
        </Fragment>
    )
}

export default NumberPadNumbersContainer
