import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import Button from '../layout/Button';
import confirm from 'reactstrap-confirm';
import AuthContext from '../../contexts/auth/AuthContext';
import { HttpAgent } from '../../utility/HttpAgent';
import UIContext from '../../contexts/ui/UIContext';
import ProcessContext from '../../contexts/process/ProcessContext';
import { HELP_DESK_LINK } from '../../constants/helpDesk';

const GlobalUserEditor = ({ user, changeRoleHandler, disableRoleChange, reloadHandler }) => {
  const { userInformation } = useContext(AuthContext);
  const { addNotification } = useContext(UIContext);
  const {  notifyError } = useContext(ProcessContext);

  const initialState = {
    email: {
      value: user.email ?? '',
      touched: false,
      validate: function (text) {
        return true;
      },
      isValid: true,
      placeholder: 'Email ...',
    },
    id: {
      value: user.id ?? '',
      touched: true,
      validate: function (text) {
        return true;
      },
      isValid: true,
      placeholder: '',
    },
    isGlobalAdmin: {
      value: user.isGlobalAdmin ?? 0,
      touched: true,
      validate: function (text) {
        return true;
      },
      isValid: true,
      placeholder: '',
    },
    familyName: {
      value: user.familyName ?? 'Not yet provided',
      touched: true,
      validate: function (text) {
        return true;
      },
      isValid: true,
      placeholder: '',
    },
    givenName: {
      value: user.givenName ?? 'Not yet provided',
      touched: true,
      validate: function (text) {
        return true;
      },
      isValid: true,
      placeholder: '',
    },
  };

  const [edit, setEdit] = useState(false);
  const [userState, setUserState] = useState(initialState);

  const isStateValid = Object.values(userState)
    .map((entry) => entry.isValid)
    .every((item) => !!item);

  useEffect(() => {
    if (user) {
      const userStateInit = Object.entries(user).reduce((acc, [key, value]) => {
        if (key in userState === false) return acc;

        return {
          ...acc,
          [key]: { ...acc[key], value, isValid: acc[key]?.validate(value) },
        };
      }, initialState);

      setUserState(userStateInit);
    }
  }, [user]);

  const onChange = (event) => {
    event.persist();

    const key = event.currentTarget.name;
    const value = event.currentTarget.value;
    setUserState((prev) => ({
      ...prev,
      [key]: {
        ...userState[key],
        value,
        isValid: userState[key].validate(value),
        touched: true,
      },
    }));
  };

  const onCheckboxChange = (event) => {
    event.persist();

    const key = event.currentTarget.name;
    setUserState((prev) => ({
      ...prev,
      [key]: {
        ...userState[key],
        value: !userState[key].value,
      },
    }));
  };

  const activateEditState = () => {
    setEdit(true);
  };

  const reset = () => {
    setUserState(initialState);
    setEdit(false);
  };

  const createRequestBody = () => {
    const updatedUserProperties = Object.entries(userState).reduce((acc, [key, valueObject]) => {
      return { ...acc, [key]: valueObject.value };
    }, {});
    const updatedUser = {
      ...user,
      ...updatedUserProperties,
    };

    return updatedUser;
  };

  const handleSave = async () => {
    // await updateUserRole(user.isGlobalAdmin)
    // setEdit(false);
  };

  const handleDelete = async () => {
    let result = await confirm({
      title: (
        <>
          <strong>DELETE USER</strong>
        </>
      ),
      message: 'Are you sure?',
      confirmText: 'DELETE',
      confirmColor: 'success',
      cancelColor: 'muted',
      centered: true,
    });

    if (result) {
      await deleteUser(user);
    }
  };

  const deleteUser = async (user) => {
    let url = `api/v1/Users/${user.id}`;

    try {
      let result = await HttpAgent.delete(url);
      addNotification('Successfully deleted user.', 'success');
    } catch (error) {
      if (error.message === 'Not found') {
        notifyError(<>We have encountered an issue while deleting the user. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else{
        notifyError(<>We have encountered an issue on our side while deleting the user. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.error(error);
    } finally {
      await reloadHandler();
    }
  };

  const updateTenant = async (tenant) => {
    let result = await HttpAgent.put(`api/v1/Tenants/${tenant.tenantId}`, tenant);
  };

  const changeGlobalAdminStatus = async () => {
    changeRoleHandler(user);
  };

  const combineNames = () => {
    if (!userState.familyName.value || !userState.givenName.value) {
      return 'No name provided yet';
    }

    return `${userState.familyName.value}, ${userState.givenName.value}`;
  }

  return (
    <Card className='seed-card'>
      <CardHeader className='seed-card-body'>
        <Fragment>
          <input
            className={
              userState.familyName.isValid || !userState.familyName.touched
                ? 'h4-input seed-property-input-field header-input'
                : 'h4-input seed-property-input-field header-input invalid-field'
            }
            type='text'
            minLength='3'
            value={combineNames()}
            onChange={onChange}
            name='name'
            id='name'
            placeholder={userState.familyName.placeholder}
            disabled={true}
          />
        </Fragment>
      </CardHeader>
      <CardBody>
        <Fragment>
          <div className='card-body-input-row'>
            <label className='input-label' htmlFor='email'>
              Email&nbsp;
            </label>
            <input
              className={
                userState.email.isValid || !userState.email.touched
                  ? 'seed-property-input-field mb-4'
                  : 'seed-property-input-field invalid-field mb-4'
              }
              type='text'
              minLength='3'
              value={userState.email.value}
              onChange={onChange}
              name='email'
              id='email'
              placeholder={userState.familyName.placeholder}
              disabled={true}
            />
            <Button
              clickHandler={changeGlobalAdminStatus}
              label={user?.isGlobalAdmin ? 'Global Admin' : 'Regular User'}
              icon={user?.isGlobalAdmin ? 'fas fa-globe' : 'far fa-user'}
              disabled={disableRoleChange && (userInformation?.id === user?.id)}
            />
          </div>
        </Fragment>
      </CardBody>
      <CardFooter>
        <div className='card-button seed-card-footer'>
          {edit ? (
            <Fragment>
              <Button className='seed-card-btn' label='Cancel' clickHandler={reset} />
              <Button className='seed-card-btn' label='Save' clickHandler={handleSave} disabled={!isStateValid} />
            </Fragment>
          ) : (
            <Fragment>
              <Button className='seed-card-btn' label={'Delete User'} clickHandler={handleDelete} disabled={userInformation?.id === user?.id} />
              {/* {!edit && (
              <Button
                className='seed-card-btn'
                label={'Users'}
                clickHandler={usersClickHandler}
              />
            )} */}
              {/* <Button className='seed-card-btn' label={'Edit'} clickHandler={activateEditState} /> */}
            </Fragment>
          )}
        </div>
      </CardFooter>
    </Card>
  );
};

export default GlobalUserEditor;
