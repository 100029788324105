import React from 'react';

const NumberPadDisplay = ({ displayValue }) => {

  return (
    
      <input className='sweden-ui-number-pad-display' type='text' readOnly value={displayValue} />
  );
};

export default NumberPadDisplay;
