import React, { createContext } from 'react';

const AuthContext = createContext({
  currentUser: null,
  isAuthenticated: false,
  loading: true,
  setCurrentUser: (user) => {},
  signIn: () => {},
  signOut: () => {},
  reset: () => {}
});

export default AuthContext;