import { useEffect, useState } from 'react';
import { HttpAgent } from '../utility/HttpAgent';

const useWeighingCamera = (tenantId, containerId, cameraId) => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(false);
  const [cameraSettings, setCameraSettings] = useState(null);

  useEffect(() => {
    return () => reset();
  },[]);

  const switchState = async (futureState) => {
    try {
      setLoading(true);
      setError(null);
      await HttpAgent.put(`api/v1/tenants/${tenantId}/Containers/${containerId}/Devices/${cameraId}/State`, {
        data: futureState,
      });
      setState(futureState);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };  

  const reset = () => {
    setLoading(false);
    setError(null);
    setCameraSettings(null);
    setState(null);
  }

  const startAnew = () => {
    setLoading(false);
    setError(null);
  }

  const _getState = async () => {
    const currentCameraState = await HttpAgent.get(
      `api/v1/tenants/${tenantId}/containers/${containerId}/devices/${cameraId}/state`
    );
    setState(currentCameraState?.data);
    if (currentCameraState?.data === 1) {
      return true;
    }

    return false;
  };

  //useEffect(_getState, []);

  const weighingCamera = {
    loading,
    error,
    state,
    cameraSettings,
    switchState,
    startAnew,
    _getState
  };

  return weighingCamera;
};
export default useWeighingCamera;
