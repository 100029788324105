import React from 'react';

const Channel = ({ children }) => {
  return (
    <div className='channel'>
      {children}
    </div>
  );
};

export default Channel;
