import React, { useContext } from 'react';
import ProcessContext from '../../../../contexts/process/ProcessContext';
import TransplantedPanelOverview from './TransplantedPanelOverview';
import TransplantedTrayOverview from './TransplantedTrayOverview';

const TransplantingOverview = () => {
  const { additionalProcessInformation } = useContext(ProcessContext);

  const FALLBACK_SELECTION = [];

  const traySlotItems =
    additionalProcessInformation?.selectedSeedSlotOrdinalNumbers ?? FALLBACK_SELECTION;

  const panelSlotItems =
    additionalProcessInformation.selectedPlantSlotOrdinalNumbers ?? FALLBACK_SELECTION;

  const transplantingRelations =
    JSON.parse(additionalProcessInformation?.transplantingRelations) ?? FALLBACK_SELECTION;

  return (
    <div className='transplanting-overview'>
      <TransplantedTrayOverview
        traySelection={traySlotItems}
        transplantingRelations={transplantingRelations}
      />
      <TransplantedPanelOverview
        panel={additionalProcessInformation?.virtualOccupancy ?? FALLBACK_SELECTION}
        panelSelection={panelSlotItems}
        transplantingRelations={transplantingRelations}
      />
    </div>
  );
};

export default TransplantingOverview;
