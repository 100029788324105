import React, { useRef, useEffect } from "react";
const AutoComplete = ({ locationPicked, currentLocation }) => {
    const autoCompleteRef = useRef();
    const inputRef = useRef();

    useEffect(() => {
        autoCompleteRef.current = new window.google.maps.places.Autocomplete(
            inputRef.current,
            {
                types: ["address"],
            }
        );

        autoCompleteRef.current.addListener("place_changed", async function () {
            const place = await autoCompleteRef.current.getPlace();
            locationPicked(place);
        });
    }, []);

    const resetSearch = () => {
        (document.getElementById('location-search')).value = '';
    };

    const switchIcon = (switchToDefault) => {
        (document.getElementById('location-search-default')).hidden = !switchToDefault;
        (document.getElementById('location-search-close')).hidden = switchToDefault;
        (document.getElementById('location-search-reset')).hidden = switchToDefault;
    }

    return (
        <div className='location-container'>
            <div id='location-search-default' className='location-search-close'><i className="fas fa-search location-search-icon"></i></div>
            <div id='location-search-close' className='location-search-close' hidden={true}><i className="fas fa-arrow-left location-search-icon"></i></div>
            <input id='location-search' className='location-search-input' ref={inputRef} defaultValue={currentLocation} onBlur={() => { switchIcon(true) }} onFocus={() => { switchIcon(false) }} />
            <div id='location-search-reset' className='location-search-reset' onClick={resetSearch} onMouseDown={(event) => event.preventDefault()} hidden={true}><i className="fas fa-times location-search-icon"></i></div>
        </div>
    );
};
export default AutoComplete;
