import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardBody, CardHeader } from 'reactstrap';
import UIContext from '../../contexts/ui/UIContext';
import Button from '../layout/Button';

const AddTenant = ({cancelHandler, saveHandler}) => {
  const { setInfo } = useContext(UIContext);  

  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const initialState = {
    name: {
      value: '',
      touched: false,
      validate: function (text) {
        return text?.length >= 3;
      },
      isValid: false,
      placeholder: '',
    },
    adminEmail: {
      value: '',
      touched: false,
      validate: function (text) {
        return re.test(text);
      },
      isValid: false,
      placeholder: ''
    }
  };

  const [tenantState, setTenantState] = useState(initialState);


  useEffect(() => {
    setInfo('Add New Tenant');

    return () => setInfo('Select a process');
  }, []);  


  const isStateValid = Object.values(tenantState)
    .map((entry) => entry.isValid)
    .every((isValid) => isValid);

  const onChange = (event) => {
    event.persist();
    const key = event.currentTarget.name;
    const value = event.currentTarget.value;
    setTenantState((prev) => ({
      ...prev,
      [key]: {
        ...tenantState[key],
        value,
        isValid: tenantState[key]?.validate(value),
        touched: true,
      },
    }));
  };

  const createTenant = () => {
    const tenantProperties = Object.entries(tenantState).reduce((acc, [key, valueObject]) => {
      return { ...acc, [key]: valueObject.value };
    }, {});

    return tenantProperties;
  };  

  const handleSave = async () => {
    await saveHandler(createTenant());
  };

  const reset = () => {
    setTenantState(initialState);
    cancelHandler();
  };  

  return (
    <Card className='seed-card'>  
    <CardHeader>
    <input
          className='h4-input seed-property-input-field header-input'
          type='text'
          minLength='3'
          value='Enter new tenant information'
          onChange={onChange}
          name='name'
          id='name'
          placeholder=''
          disabled={true}
        />
      </CardHeader>    
      <CardBody className='seed-card-body'>
        <div className='card-body-input-row add-row'>
          <label className='input-label thin' htmlFor='name'>
            Company Name
          </label>
          <input
            className={
              tenantState.name.isValid || !tenantState.name.touched
                ? 'seed-property-input-field'
                : 'seed-property-input-field invalid-field'
            }
            type='text'
            minLength='3'
            value={tenantState.name.value}
            onChange={onChange}
            name='name'
            id='name'
            placeholder={tenantState.name.placeholder}
          />
          {!tenantState.name.isValid && tenantState.name.touched && <p>Name must have 3 characters or more.</p>}
        </div>
        <div className='card-body-input-row add-row mt-4'>
          <label className='input-label thin' htmlFor='admin-email'>
            Tenant Admin Email Address
          </label>
          <input
            type='email'
            id='admin-email'
            name='adminEmail'
            className={
              tenantState?.adminEmail.isValid || !tenantState.adminEmail.touched
                ? 'seed-property-input-field'
                : 'seed-property-input-field invalid-field'
            }
            minLength='3'
            value={tenantState.adminEmail.value}
            placeholder={tenantState.adminEmail.placeholder}
            onChange={onChange}
          />
          {!tenantState.adminEmail.isValid && tenantState.adminEmail.touched && (
            <p>Admin Email must be a valid email address.</p>
          )}
        </div>        
      </CardBody>
      <div className='card-button seed-card-footer'>
        <Fragment>
          <Button className='seed-card-btn' label='Cancel' clickHandler={reset} />
          <Button className='seed-card-btn' label='Create' clickHandler={handleSave} disabled={!isStateValid} />
        </Fragment>
      </div>      
    </Card>
  );
};
export default AddTenant;
