import React, { Fragment, useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../../contexts/process/ProcessContext';
import StatusBarButton from '../../layout/StatusBarButton';
import ReportForm from './ReportForm';
import Loading from '../../ui-elements/Loading';
import UIContext from '../../../contexts/ui/UIContext';
import AuthContext from '../../../contexts/auth/AuthContext';
import { TENANT_ADMIN } from '../../../constants/roles/roles';

const ReportFormContainer = () => {
  const { activeProcessId, continueProcess, additionalProcessInformation } =
    useContext(ProcessContext);
  const { currentRoles, userInformation } = useContext(AuthContext);
  const { loading } = useContext(UIContext);  

  const [comment, setComment] = useState('');  
  const [ category, setCategory ] = useState(null);
  const [photoData, setPhotoData] = useState(null);

  const navigate = useNavigate();

  const initialCategory = () => {
    if(currentRoles.includes(TENANT_ADMIN)) {
      return additionalProcessInformation?.reportCategories.find(x => x === 'Plant Evaluation');
    }
    
    return additionalProcessInformation?.reportCategories[0];
  }

  useEffect(() => {
    const categoryValue = initialCategory();
    setCategory(categoryValue);
  }, []);

  const submitReport = async () => {
    try {
      let reportData = {
        comment,
        photoData,
        type: category,
        userId: userInformation?.userId
      };
      await continueProcess(activeProcessId, reportData);        
      navigate("/");
    } catch (error) {
      console.error(error);
    }
  };
  
  if (!additionalProcessInformation) return null;

  return ( loading ? 
    <Loading fullScreen /> :
    <Fragment>
      <ReportForm
        comment={comment}
        setComment={setComment}
        category={category}
        categories={additionalProcessInformation.reportCategories}
        setCategory={setCategory}
        photoData={photoData}
        setPhotoData={setPhotoData}
      />
      <StatusBarButton label='Confirm' icon='fas fa-check' type='inline' reversed={true} clickHandler={submitReport} statusSlot={5} />
    </Fragment>
  );
};

export default ReportFormContainer;
