import React, { Fragment, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../contexts/process/ProcessContext';
import NumberPad from '../ui-elements/NumberPad';
import StatusBarButton from './StatusBarButton';

const WallSelector = ({ forwardTo }) => {
  const navigate = useNavigate();

    const { continueProcess, activeProcessId, processFinished } = useContext(ProcessContext);
  
    const [display, setDisplay] = useState({
        prefix: 'w',
        value: ''
    });
  
    const proceedToSeedTypeSelection = async () => {
      try {
        await continueProcess(activeProcessId, display.prefix + display.value);
      navigate(forwardTo);
      } catch (error) {
        console.error("Error in proceed to seed type selection: ", error);
      }
    };
  
    const setValue = (value) => {
        setDisplay(prev => ({ ...prev, value }))
    }
  
    return (
      <Fragment>
        <NumberPad prefix={display.prefix} value={display.value} setValue={setValue} />
        {display.value && (
          <StatusBarButton
            type='inline'
            label='Continue'
            icon='fas fa-chevron-right'
            reversed={true}
            statusSlot={5}
            clickHandler={proceedToSeedTypeSelection}
          />
        )}
      </Fragment>
    );
}

export default WallSelector
