import { useContext, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Card } from 'reactstrap';

import AuthContext from "../../../contexts/auth/AuthContext";
import ProduceTypeContext from "../../../contexts/management/produceTypes/ProduceTypeContext";
import UIContext from "../../../contexts/ui/UIContext";
import StatusBarButton from "../../layout/StatusBarButton";
import ProduceTypeCard from "./Card";
import Loading from "../../ui-elements/Loading";
import { TENANT_ADMIN } from '../../../constants/roles/roles';

const List = () => {

    const { currentRoles, currentTenant, isGlobalAdmin } = useContext(AuthContext);
    const { allProduceTypes, getManyProduceTypes, loading } = useContext(ProduceTypeContext);
    const { setInfo } = useContext(UIContext);
    const navigate = useNavigate();

    useEffect(() => {
        setInfo('Produce Type Management');
    }, []);

    const addProduceTypeClickHandler = () => {
        navigate('/management/produce-types/create');
    };

    return currentTenant
        && loading ? <Loading fullScreen={true} /> : (
        <div className="produce-type-list">
            {(isGlobalAdmin || currentRoles.includes(TENANT_ADMIN))
                && <Card className='produce-type-card' onClick={addProduceTypeClickHandler}>
                    <h1 className='add-produce-type-symbol'>
                        <i className='fas fa-plus'></i>
                    </h1>
                </Card>}
            {allProduceTypes?.sort((a, b) => {
                return a.marketingName.toLowerCase() > b.marketingName.toLowerCase() ? 1 : -1;
            })
                .map((produceType, index) => {
                    return (
                        <ProduceTypeCard
                            key={produceType?.id}
                            produceType={produceType}
                        />
                    );
                })}
            <StatusBarButton
                label='Done'
                clickHandler={() => navigate('/')}
                statusSlot={3}
                icon='fair fai-circle-check'
                type='inline'
            />
        </div>
    )
};

export default List;