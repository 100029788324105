import React, { useContext, useCallback } from "react";
import StatusBarButton from "./StatusBarButton";
import ProcessContext from '../../contexts/process/ProcessContext';
import { HELP_DESK_LINK } from '../../constants/helpDesk';
import { useNavigate } from 'react-router-dom';
import useControlBrowserNavigation from "../../customHooks/useControlBrowserNavigation";

/**
 * BackButton component handles the back navigation functionality.
 * It interacts with the process context to revert the current process step
 * and navigates to a specified route upon success.
 * @param {object} props - The props for the component.
 * @param {string} props.navigateTo - The route to navigate to on back action.
 * @param {boolean} props.disabled - Flag to disable the back button.
 */
const BackButton = ({ navigateTo, disabled, ...rest }) => {
  const { activeProcessId, revertProcess, notifyError, backIsAllowed } = useContext(ProcessContext);
  const navigate = useNavigate();

  const enableBackButton = backIsAllowed && !disabled;

 /**
 * Callback function to notify the user when browser navigation is being blocked.
 */
const onPreventedNav = () => {
  notifyError("You cannot use the browser's back button right now. Please use available UI buttons to navigate away from this page.");
};

/**
 * Custom hook to control browser navigation, in this case, to block it.
 * @param {Function} onPreventedNav - Callback function to execute when navigation is blocked.
 */
useControlBrowserNavigation(onPreventedNav);


  /**
 * Function to revert the active process and navigate to a specified route.
 * @param {string} activeProcessId - The ID of the active process to revert.
 */
const revert = (activeProcessId) => {
  revertProcess(activeProcessId)
    .then(() => {
      navigate(navigateTo);
    })
    .catch(() => {
      notifyError(
        <>
          We have encountered an issue while going back one step. Could you please abort the process, reload FarmerApp and try again? 
          If the error still exists, please contact <br/>{HELP_DESK_LINK}.
        </>
      );
    });
};


  /**
  * Function to revert process and navigate to the specified route.
  * @param {string} activeProcessId - The ID of the active process to revert.
  */
  const handleBackAction = useCallback((event) => {
    if (event) event.stopPropagation();
    revert(activeProcessId);
  }, [activeProcessId, navigate]);

  return enableBackButton ? (
    <>
      <StatusBarButton
        statusSlot={1}
        clickHandler={handleBackAction}
        disabled={!enableBackButton}
        label={' Back'}
        icon={'fas fa-chevron-left'}
        {...rest}
      />
    </>
  ) : null;
};

export default BackButton;
