import React, { useContext, Fragment } from 'react';
import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';
import Button from '../../layout/Button';
import Tooltip from '../../layout/Tooltip';
import SeedTypeCard from '../../seed-type-management/SeedTypeCard';
import Loading from '../../ui-elements/Loading';
import { useNavigate } from 'react-router-dom';
import BackButton from '../../layout/BackButton';


const SeedTypeSelection = ({forwardTo, backwardTo}) => {
  const { additionalProcessInformation, continueProcess, activeProcessId } = useContext(ProcessContext);
  const { loading } = useContext(UIContext);
  const navigate = useNavigate();

    const sendSeedTypeName = async (seedVariantTypes) => {
    try {
        await continueProcess(activeProcessId, seedVariantTypes.seedVariantTypeIdRef);
      navigate(forwardTo);

        } catch (error) {
            console.error(error);
        }
    };

  const groupSeedTypesByVarietyNumber = () => {
    const grouped = additionalProcessInformation.availableSeedTypes.reduce((acc, item) => {
      if (acc[item.varietyNumber]) {
        acc[item.varietyNumber].push(item);
      } else {
        acc[item.varietyNumber] = [item];
      }

      return acc;
    }, {});

    return grouped;
  };

  const createButtonGroup = (key, seedType, seedVariantTypes) => {
    
    const createLabel = (seedType) =>
      seedType.name +
      '\n' +
      new Date(seedType.creationDate).toLocaleDateString('de-DE', {
        year: '2-digit',
        month: '2-digit',
        day: '2-digit',
      });
    
      return (
      <Fragment>
        {seedVariantTypes.length > 0 && <Tooltip
          key={key + '-tooltip'}
          frontElement={
            <Button 
              key={key + '-frontButton'}             
              icon='fas fa-leaf'
              className='seed-button'
              label={seedType.name}
              numberBadgeValue={parseInt(seedVariantTypes.length)}
              disabled={seedType?.seedVariantTypes?.length == 0}
            />
          }
          triggeredBy='click'
        >
          <div className='seed-group-body'>
            {seedVariantTypes.map((seedVariantType, index) => (              
              <SeedTypeCard
                key={index + '-variant'}
                className='seed-button'
                seedType={seedType}
                seedVariantTypes={seedVariantType}
                clickHandler={async () => await sendSeedTypeName(seedVariantType)}
              />
            ))}
          </div>
        </Tooltip>}
      </Fragment>
    );
  };

  return loading ? (
    <Loading fullScreen />
  ) : (
    <div className='seed-selection-container'>
      <div className='seed-selection-content'>        
        {additionalProcessInformation?.availableSeedTypes?.map((seedType) =>
          createButtonGroup(seedType?.id, seedType, seedType?.seedVariantTypes)
        )}
      </div>
      <BackButton disabled={false} navigateTo={backwardTo}/>
    </div>
  );
};

export default SeedTypeSelection;