import { RESET, SET_CURRENT_TENANT, SET_CURRENT_USER, SET_CURRENT_CONTAINER, SET_CURRENT_ROLES, SET_ACCESSIBLE_CONTAINERS, SIGN_IN, SIGN_OUT, UPDATE_ACCESSIBLE_TENANTS, UPDATE_USER_CONTEXT, SET_ACCESSIBLE_SEEDTYPES, SET_ACCESSIBLE_TEMPLATES, SET_NOTIFICATION, SET_ACCESSIBLE_TENANTS, SET_LAST_SELECTED_CONTAINER, SET_LAST_SELECTED_TENANT, UPDATE_SESSION_INFORMATION, UPDATE_USER_INFORMATION, SET_LOADING, LOADING_SEEDTYPES } from '../types/contextTypes';

export default (state, action) => {
  switch (action.type) {
    case SET_ACCESSIBLE_TENANTS: {
      return {
        ...state,
        accessibleTenants: action.payload
      }
    }
    case UPDATE_SESSION_INFORMATION: {
      return {
        ...state,
        sessionInformation: action.payload
      }
    }
    case UPDATE_USER_INFORMATION: {
      return {
        ...state,
        userInformation: action.payload
      }
    }
    case SET_CURRENT_USER: {
      return {
        ...state,
        currentUser: action.payload,
        isAuthenticated: false, ///////// ERROR ERROR ERROR!
        loading: false
      };
    }
    case SET_CURRENT_TENANT: {
      return {
        ...state,
        currentTenant: action.payload.newCurrentTenant,
        accessibleContainers: action.payload.newContainers,
        accessibleSeedTypes: action.payload.newSeedTypes,
        accessibleTemplates: action.payload.newTemplates,
        currentContainer: action.payload.newCurrentContainer,
        currentRoles: action.payload.newRoles,
        seedTypesLoading: false
      }
    }
    case SET_CURRENT_CONTAINER: {
      return {
        ...state,
        currentContainer: action.payload.newCurrentContainer,
        accessibleSeedTypes: action.payload.newSeedTypes,
        seedTypesLoading: false
      }
    }
    case SET_CURRENT_ROLES: {
      return {
        ...state,
        currentRoles: action.payload
      }
    }
    case UPDATE_ACCESSIBLE_TENANTS: {
      return {
        ...state,        
        accessibleTenants: action.payload
      }
    }
    case SET_ACCESSIBLE_CONTAINERS: {
      return {
        ...state,
        accessibleContainers: action.payload
      }
    }
    case SET_LAST_SELECTED_CONTAINER: {
      return {
        ...state,
        lastSelectedContainer: action.payload
      }
    }
    case SET_LAST_SELECTED_TENANT: {
      return {
        ...state,
        lastSelectedTenant: action.payload
      }
    }
    case UPDATE_USER_CONTEXT: {
      return {
        ...state,
        userInformation: action.payload.userInformation,
        currentTenant: action.payload.currentTenant,
        currentContainer: action.payload.currentContainer,
        currentRoles: action.payload.currentRoles,
        accessibleTenants: action.payload.accessibleTenants,
        accessibleContainers: action.payload.accessibleContainers,
        accessibleSeedTypes: action.payload.accessibleSeedTypes,
        accessibleTemplates: action.payload.accessibleTemplates,
        sessionInformation: action.payload.sessionInformation,
        isAuthenticated: action.payload.isAuthenticated,
        isGlobalAdmin: action.payload.isGlobalAdmin
      }
    }
    case SET_ACCESSIBLE_SEEDTYPES: {
      return {
        ...state,
        accessibleSeedTypes: action.payload,
        seedTypesLoading: false
      }
    }
    case SET_ACCESSIBLE_TEMPLATES: {
      return {
        ...state,
        accessibleTemplates: action.payload
      }
    }
    case SET_NOTIFICATION:
      return {
        ...state,
        notifications: [action.payload, ...state.notifications],
      };
    case RESET:
      return action.payload;
    case LOADING_SEEDTYPES:
      return {
        ...state,
        seedTypesLoading: true
      }
    default:
      return state;
  }
};
