import React, { useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import WallSelector from '../../layout/WallSelector';
import EntitySelector from '../../ui-elements/EntitySelector';
import NursingTable from './tray/NursingTable';
import WallSlotSelector from './WallSlotSelector';
import ProcessContext from '../../../contexts/process/ProcessContext';
import DestinationSelection from './DestinationSelection';

const Repositioning = ({path}) => {  
  const location = useLocation();
  const { finishProcess } = useContext(ProcessContext);

  useEffect(() => {
    return () => {
      finishProcess();
    }
  },[]);

  return (
    <div
      id='repositioning-container'
      className='process-container'
      style={{ margin: location.pathname !== '/repositioning' ? 'auto' : 'unset',
    alignSelf: location.pathname === '/repositioning' ? 'auto' : 'baseline' }}
    >
      <Routes>
        <Route path={'/'} element={<EntitySelector forwardTo={path} center={true} />} />
        <Route path={'/destination-selection'} element={<DestinationSelection forwardTo={path} />} />
        <Route path={`/tray`} element={<NursingTable />} />
        <Route path={`/panel`} element={<WallSelector forwardTo={`${path}/select-wall-slot`} />} />
        <Route path={`/select-wall-slot`} element={<WallSlotSelector  forwardTo={`/`}/>} />
      </Routes>
    </div>
  );
};

export default Repositioning;
