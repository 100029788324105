// #region Libraries
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import confirm from 'reactstrap-confirm';
// #endregion

// #region Components
import Button from '../layout/Button';
import Loading from '../ui-elements/Loading';
import LabeledInputField from '../layout/LabeledInputField';
// #endregion

// #region Contexts
import SeedTypeManagementContext from '../../contexts/seed-type-management/SeedTypeManagementContext';
import AuthContext from '../../contexts/auth/AuthContext';
import ProcessContext from '../../contexts/process/ProcessContext';
// #endregion

/**
 * The SeedVariantTypeEditor component allows users to edit or create new seed variant types.
 * It integrates with the SeedTypeManagementContext for seed type operations and AuthContext for authentication-related data.
 * The component enables users to specify details for a seed variant type, including mandatory fields like LOT and Manufacturer, 
 * and optional fields with logic to calculate missing values based on entered data.
 * 
 * @component
 * @example
 * return <SeedVariantTypeEditor seedVariantType={seedVariantType} seedTypeId={seedTypeId} outerReset={outerReset} defaultEditOn={false} />
 */
const SeedVariantTypeEditor = ({ seedVariantType, seedTypeId, outerReset, defaultEditOn = false }) => {
    const {
        deleteSeedVariantType,
        updateSeedVariantType,
        saveNewSeedVariantType,
    } = useContext(SeedTypeManagementContext);

    const {
        loadAndSetaccessibleSeedTypesAsync,
        currentTenant,
        currentContainer
    } = useContext(AuthContext);

    const { notifyError } = useContext(ProcessContext);

    const [loading, setLoading] = useState(false); // Indicates loading state
    const initialState = {
        lot: {
            value: seedVariantType?.lot ?? '',
            touched: false,
            validate: function (text) {
                return text?.length >= 3;
            },
            isValid: !!seedVariantType?.lot,
        },
        manufacturer: {
            value: seedVariantType?.manufacturer ?? '',
            touched: false,
            validate: function (text) {
                return text?.length >= 3;
            },
            isValid: !!seedVariantType?.manufacturer,
        },
        containerId: {
            value: seedVariantType?.containerId ?? currentContainer?.id,
            touched: false,
            validate: function () {
                return true;
            },
            isValid: true,
        },
        seedTypeId: {
            value: seedTypeId ?? (seedVariantType?.seedTypeId ? seedVariantType.seedTypeId : ''),
            touched: false,
            validate: function () {
                return true;
            },
            isValid: true,
        },
        seedVariantTypeId: {
            value: seedVariantType?.id ?? '',
            touched: false,
            validate: function () {
                return true;
            },
            isValid: true,
        }
    };

    const [edit, setEdit] = useState(defaultEditOn == true);
    const [seedVariantTypeState, setSeedVariantTypeState] = useState(initialState);
    const [tgw, setTgw] = useState(null);
    const [seedCount, setSeedCount] = useState(null);
    const [packageWeight, setPackageWeight] = useState(null);
    const [seedConsumptionSoFar, setSeedConsumptionSoFar] = useState(undefined);
    const isStateValid = Object.values(seedVariantTypeState)
        .map((entry) => entry.isValid)
        .every((isValid) => isValid);

    // Initializes the component state based on the provided `seedVariantType` prop. 
    // This includes setting up the initial values for the LOT, Manufacturer, TGW, Seed Count, and Package Weight,
    // and ensuring that the component state reflects the current seed variant type's properties.
    useEffect(() => {
        if (seedVariantType) {
            const seedVariantTypeStateInit = Object.entries(seedVariantType).reduce((acc, [key, value]) => {
                if (key in seedVariantTypeState === false) return acc;

                return {
                    ...acc,
                    [key]: { ...acc[key], value, isValid: acc[key]?.validate(value) },
                };
            }, initialState);

            setSeedVariantTypeState(seedVariantTypeStateInit);

            setTgw({
                value: (seedVariantType?.tgw * 1000) ?? 0,
                touched: false,
                validate: function (text) {
                    return /^[+-]?\d+(\.\d+)?$/.test(text);
                },
                isValid: true,
                name: "TGW"
            });
            setPackageWeight({
                value: (seedVariantType?.packageWeight * 1000) ?? 0,
                touched: false,
                validate: function (text) {
                    return /^[+-]?\d+(\.\d+)?$/.test(text);
                },
                isValid: true,
                name: "Package Weight"
            });
            setSeedCount({
                value: seedVariantType?.count ?? ((seedVariantType?.packageWeight * 1000) * (seedVariantType?.tgw * 1000 * 1000)),
                touched: false,
                validate: function (text) {
                    return true;
                },
                isValid: true,
                name: "Seed Count"
            });
        }
    }, [seedVariantType]);

    // Calculates and updates the initial full seed count and the seed consumption so far whenever 
    // the TGW (Thousand Grain Weight), Package Weight, or Seed Count change. This is crucial for maintaining 
    // accurate calculations when any of these inputs are adjusted by the user.
    useEffect(() => {
        if (tgw && packageWeight && seedCount && !seedConsumptionSoFar) {
            let initialFullSeedCountRaw = Math.round(packageWeight.value / tgw.value * 1000);
            const initialFullSeedCount = !isFinite(initialFullSeedCountRaw) ? 0 : initialFullSeedCountRaw;
            let initialSeedConsumptionRaw = initialFullSeedCount - seedCount.value;
            const initialSeedConsumption = !isFinite(initialFullSeedCountRaw) ? 0 : initialSeedConsumptionRaw;
            setSeedConsumptionSoFar(initialSeedConsumption);
        } else if (tgw && packageWeight && seedCount && seedConsumptionSoFar) {
            let initialFullSeedCountRaw = Math.round(packageWeight.value / tgw.value * 1000);
            const initialFullSeedCount = !isFinite(initialFullSeedCountRaw) ? 0 : initialFullSeedCountRaw;
            let initialSeedConsumptionRaw = initialFullSeedCount - seedCount.value;
            const initialSeedConsumption = !isFinite(initialFullSeedCountRaw) ? seedConsumptionSoFar : initialSeedConsumptionRaw;
            setSeedConsumptionSoFar(initialSeedConsumption);
        }
    }, [tgw, packageWeight, seedCount, seedConsumptionSoFar]);

    // Enables the edit state, allowing the user to modify the current seed variant type's details.
    // This function is typically triggered by an "Edit" button or similar user action.
    const activateEditState = () => {
        setEdit(true);
    };

    // Constructs the request body for updating or creating a seed variant type.
    // This includes compiling the form data into the expected format for the backend service.
    const createRequestBody = () => {
        const updatedSeedVariantTypeProperties = Object.entries(seedVariantTypeState).reduce((acc, [key, valueObject]) => {
            return { ...acc, [key]: valueObject.value };
        }, {});

        const updatedSeedVariantType = {
            ...updatedSeedVariantTypeProperties,
        };

        const fullUpdatedSeedVariantType = { ...updatedSeedVariantType, count: seedCount.value, packageWeight: (packageWeight.value / 1000), tgw: (tgw.value / 1000) }
        return fullUpdatedSeedVariantType;
    };

    // Handles the deletion of the current seed variant type. 
    // It confirms the user's intent to delete and proceeds with the deletion if confirmed.
    const handleDelete = async () => {
        let result = await confirm({
            title: (
                <>
                    <strong>DELETE SEED VARIANT TYPE</strong>
                </>
            ),
            message: 'Are you sure?',
            confirmText: 'DELETE',
            confirmColor: 'success',
            cancelColor: 'muted',
            centered: true,
        }); //will display a confirmation dialog with default settings

        if (result) {
            setLoading(true);
            try {
                await deleteSeedVariantType(seedVariantType);
                await loadAndSetaccessibleSeedTypesAsync(currentTenant, currentContainer, true, 'seedVariantTypes');
            } catch (err) {
                console.error("Error: ", err)
                notifyError('We have encountered an issue while deleting the seed variant type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team farmer@support.diecityfarm.de.');
            } finally {
                setLoading(false);
            }
        }
    };

    // Handles the creation of a new seed variant type. 
    // It constructs the request body based on the component state and initiates the save operation.
    const handleSaveNew = async () => {
        setLoading(true);
        try {
            const newSeedVariantType = createRequestBody();
            await saveNewSeedVariantType(newSeedVariantType)
            await loadAndSetaccessibleSeedTypesAsync(currentTenant, currentContainer, true, 'seedVariantTypes');
            reset();
        } catch (err) {
            notifyError('We have encountered an issue while saving the new seed variant type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team farmer@support.diecityfarm.de.');
        } finally {
            setLoading(false);
        }
    };

    // Handles the update process for the seed variant type. 
    // It confirms the user's intent to alter the seed type properties and performs the update operation if confirmed.
    const handleUpdate = async () => {
        let result = await confirm({
            title: (
                <>
                    <strong>ALTER SEED VARIANT TYPE</strong>
                </>
            ),
            message:
                'Changing seed type properties will alter the data of every plant ever created with this seed type. Are you sure you want to continue with your changes?',
            confirmText: 'Are you sure?',
            confirmColor: 'success',
            cancelColor: 'muted',
            centered: true,
        });

        if (result) {
            setLoading(true);
            try {
                const updatedSeedVariantType = createRequestBody();
                await updateSeedVariantType(updatedSeedVariantType);
                await loadAndSetaccessibleSeedTypesAsync(currentTenant, currentContainer, true, 'seedVariantTypes')

                let initialFullSeedCountRaw = Math.round(updatedSeedVariantType.packageWeight / updatedSeedVariantType.tgw * 1000);
                const initialFullSeedCount = !isFinite(initialFullSeedCountRaw) ? 0 : initialFullSeedCountRaw;

                let initialSeedConsumptionRaw = initialFullSeedCount - updatedSeedVariantType.count;
                const initialSeedConsumption = !isFinite(initialFullSeedCountRaw) ? 0 : initialSeedConsumptionRaw;
                if (initialFullSeedCount !== 0) {
                    setSeedConsumptionSoFar(initialSeedConsumption);                }
                reset();
            } catch (err) {
                notifyError('We have encountered an issue while updating the seed variant type. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team farmer@support.diecityfarm.de.')
            } finally {
                setLoading(false);
            }
        }
    };

    // Handles generic changes to input fields by updating the corresponding state in the component.
    // This ensures any modifications to the form inputs are reflected in the component state, allowing for dynamic updates and validation.
    const onChange = (event) => {
        event.persist();
        const key = event.currentTarget.name;
        const value = event.currentTarget.value;
        setSeedVariantTypeState((prev) => ({
            ...prev,
            [key]: {
                ...seedVariantTypeState[key],
                value,
                isValid: seedVariantTypeState[key].validate(value),
                touched: true,
            },
        }));
    };

    // Handles changes to the Package Weight input field, recalculating and updating the Seed Count 
    // based on the new Package Weight and the existing TGW value. This ensures the Seed Count remains accurate and up-to-date.
    const onPackageWeightChange = (event) => {
        const newPackageWeight = parseFloat(event.currentTarget?.value);
        if (!isNaN(newPackageWeight) && tgw) {
            setPackageWeight({
                ...packageWeight,
                value: newPackageWeight,
                touched: true,
            });

            const newSeedCountCalculatedRaw = (newPackageWeight / tgw.value) * 1000;
            const newSeedCountCalculated = !isFinite(newSeedCountCalculatedRaw) ? 0 : newSeedCountCalculatedRaw;
            let newSeedCountRaw = Math.round(newSeedCountCalculated - seedConsumptionSoFar);
            const newSeedCount = !isFinite(newSeedCountRaw) ? 0 : newSeedCountRaw;
            setSeedCount({
                ...seedCount,
                value: newSeedCount,
                touched: true,
            });
        }
    };

    // Responds to changes in the Seed Count input field, updating the component state with the new value.
    // This allows the Seed Count to be directly edited by the user while ensuring the component state remains consistent.
    const onSeedCountChange = (event) => {
        event.persist();

        const newSeedCountRaw = Math.round(event.currentTarget.value);
        const newSeedCount = !isFinite(newSeedCountRaw) ? 0 : newSeedCountRaw;
        setSeedCount({
            value: newSeedCount,
            touched: true,
            validate: function (text) {
                return true;
            },
            isValid: true,
        });
    }

    // Handles changes to the TGW (Thousand Grain Weight) input field, recalculating and updating the Seed Count 
    // based on the new TGW and the existing Package Weight. This is necessary for keeping the Seed Count accurate after adjustments to the TGW.
    const onTgwChange = (event) => {
        const newTgw = parseFloat(event.currentTarget?.value);
        if (!isNaN(newTgw) && packageWeight.value) {
            setTgw({
                ...tgw,
                value: newTgw,
                touched: true,
            });

            const newSeedCountCalculatedRaw = (packageWeight.value / newTgw) * 1000;
            const newSeedCountCalculated = !isFinite(newSeedCountCalculatedRaw) ? 0 : newSeedCountCalculatedRaw;

            const newSeedCountRaw = Math.round(newSeedCountCalculated - seedConsumptionSoFar);
            const newSeedCount = !isFinite(newSeedCountRaw) ? 0 : newSeedCountRaw;
            setSeedCount({
                ...seedCount,
                value: newSeedCount,
                touched: true,
            });
        }
    };

    // Resets the form to its initial state and exits the edit mode.
    // This can be used to cancel the editing process, reverting any changes made by the user.
    const reset = () => {
        setSeedVariantTypeState(initialState);
        setTgw({
            value: (seedVariantType?.tgw * 1000) ?? 0,
            touched: false,
            validate: function (text) {
                return /^[+-]?\d+(\.\d+)?$/.test(text);
            },
            isValid: true,
            name: "Tgw"
        });
        setPackageWeight({
            value: (seedVariantType?.packageWeight * 1000) ?? 0,
            touched: false,
            validate: function (text) {
                return /^[+-]?\d+(\.\d+)?$/.test(text);
            },
            isValid: true,
            name: "Package Weight"
        });
        setSeedCount({
            value: seedVariantType?.count ?? ((seedVariantType?.packageWeight * 1000) * (seedVariantType?.tgw * 1000 * 1000)),
            touched: false,
            validate: function (text) {
                return true;
            },
            isValid: true,
            name: "Seed Count"
        });
        setEdit(false);
        if (!!outerReset) outerReset();
    };

    return (loading || !seedVariantType || !tgw || !seedCount || !packageWeight) ? <Loading fullScreen={true} displayText={''} /> : (
        <Card className='seed-variant-type-card'>
            <CardBody className='seed-variant-type-card-body'>
                <div className='lot-pos' >
                    <LabeledInputField
                        labelText={'LOT'}
                        inputName={'lot'}
                        value={seedVariantTypeState.lot.value}
                        isValid={seedVariantTypeState.lot.isValid}
                        touched={seedVariantTypeState.lot.touched}
                        inputType={'text'}
                        onChange={onChange}
                        placeholder={'LOT'}
                        disabled={edit === false}
                        errorMessage={'Minimum 3 characters.'}
                    />
                </div>
                <div className='manufacturer-pos' >
                    <LabeledInputField
                        labelText={'Manufacturer'}
                        inputName={'manufacturer'}
                        value={seedVariantTypeState.manufacturer.value}
                        isValid={seedVariantTypeState.manufacturer.isValid}
                        touched={seedVariantTypeState.manufacturer.touched}
                        inputType={'text'}
                        onChange={onChange}
                        placeholder={'Manufacturer'}
                        disabled={edit === false}
                        errorMessage={'Minimum 3 characters.'}
                    />
                </div>
                <div className='seed-count-pos' >
                    <LabeledInputField
                        labelText={'Seed Count'}
                        inputName={'count'}
                        value={seedCount.value}
                        isValid={true}
                        touched={seedCount.touched}
                        inputType={'number'}
                        onChange={onSeedCountChange}
                        disabled={edit === false}
                        errorMessage={'example: \'1\' or \'1.0\''}
                    />
                </div>
                <div className='package-weight-pos'>
                    <LabeledInputField
                        labelText={'Package Weight [g]'}
                        inputName={'packageWeight'}
                        value={packageWeight.value}
                        isValid={true}
                        touched={packageWeight.touched}
                        inputType={'number'}
                        onChange={onPackageWeightChange}
                        disabled={edit === false}
                        errorMessage={'example: \'1\' or \'1.0\''}
                    />
                </div>
                <div className='tgw-pos'>
                    <LabeledInputField
                        labelText={'TGW [g]'}
                        inputName={'tgw'}
                        value={tgw.value}
                        isValid={true}
                        touched={tgw.touched}
                        inputType={'number'}
                        onChange={onTgwChange}
                        disabled={edit === false}
                        errorMessage={'example: \'1\' or \'1.0\''}
                    />
                </div>
            </CardBody>
            <div className='card-button seed-card-footer'>
                {edit ? (
                    <Fragment>
                        <Button className='seed-card-btn' label='Cancel' clickHandler={reset} />
                        <Button
                            className='seed-card-btn'
                            label='Save As New'
                            clickHandler={handleSaveNew}
                            disabled={!isStateValid}
                        />
                        {seedVariantType && !defaultEditOn && (
                            <Button className='seed-card-btn' label='Save' clickHandler={handleUpdate} disabled={!isStateValid} />
                        )}
                    </Fragment>
                ) : (
                    <Fragment>
                        <Button className='seed-card-btn' label={'Delete'} clickHandler={handleDelete} />
                        <Button className='seed-card-btn' label={'Edit'} clickHandler={activateEditState} />
                    </Fragment>
                )}
            </div>
        </Card>
    );
};

export default SeedVariantTypeEditor;