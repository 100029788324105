import signalRHubConfiguration from '../signalRHubConfiguration';

/**
 * Factory class responsible for processing and generating SignalR hub configurations.
 * The configuration can include placeholders that are dynamically replaced with parameters.
 */
class HubConfigurationFactory {
  
  /**
   * Creates an instance of the HubConfigurationFactory.
   * 
   * @param {object} props - Parameters to replace placeholders in the configuration URL.
   */
  constructor(props) {
    this.params = props;
    this.hubConfiguration = null;

    this.processConfiguration(); // Initialize the configuration when the factory is created
  }

  /**
   * Replaces placeholders in the given URL with corresponding parameter values.
   * 
   * @param {string} url - The URL containing placeholders in the format `{key}`.
   * @param {object} params - An object containing key-value pairs to replace placeholders.
   * @returns {string|null} The URL with placeholders replaced, or null if no parameters are provided.
   */
  replaceUrlPlaceholders(url, params) {
    if (!params) return null;
    let replacedUrl = url;
    for (const [key, value] of Object.entries(params)) {
      const placeholder = `{${key}}`;
      replacedUrl = replacedUrl.replace(new RegExp(placeholder, ''), value);
    }
    return replacedUrl;
  }

  /**
   * Processes the SignalR hub configuration by replacing any placeholders in the URL
   * with the provided parameters. The processed configuration is stored in `hubConfiguration`.
   */
  processConfiguration() {
    try {
      if (signalRHubConfiguration) {
        const processedConfiguration = {
          ...signalRHubConfiguration,
          url: this.replaceUrlPlaceholders(signalRHubConfiguration.url, this.params),
        };
        
        this.hubConfiguration = [processedConfiguration]; // Store the configuration
      }
    } catch (err) {
      console.error('Error processing JSON:', err.message);
    }
  }

  /**
   * Retrieves the processed SignalR hub configuration.
   * 
   * @returns {Array|null} The processed hub configuration, or null if not available.
   */
  getConfiguration() {
    return this.hubConfiguration;
  }
}

export default HubConfigurationFactory;
