import React, { useContext, useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import SelectableState from '../../../contexts/local/selectable/SelectableState';
import ProcessContext from '../../../contexts/process/ProcessContext';
import PanelSelector from '../transplanting/PanelSelector';
import PanelSlotSelection from './PanelSlotSelection';
import WeighingContainer from './WeighingContainer';
import HarvestingProcedureSelection from './HarvestingProcedureSelection';

const Harvesting = () => {
  const navigate = useNavigate();
  const path = '/harvesting';

  const { processFinished, occupancyArray, finishProcess } = useContext(ProcessContext);

  const availableItems = occupancyArray
    .map((channel) =>
      channel.plantSlotBlocks.filter((block) => block.isOccupied && block.produceTypeId != null).map((block) => block.startPlantSlotOrdinalNumber)
    )
    .flat();

  useEffect(() => {
    return () => {
      finishProcess();
    }
  }, []);

  useEffect(() => {
    if (processFinished) {
      navigate('/');
    }
  }, [navigate, processFinished]);

  return (
    <div id='harvesting-container' className='process-container'>
      <Routes>
        <Route path={'/'} element={<HarvestingProcedureSelection forwardTo={`${path}/select-panel`} />} />
        <Route path={'/select-panel'} element={<PanelSelector forwardTo={`${path}/select-harvest`} backwardTo={`${path}`} />} />
        <Route
          path={`/select-harvest`}
          element={
            <SelectableState availableItems={availableItems}>
              <PanelSlotSelection forwardTo={`${path}/confirm-weight-and-print`} backwardTo={`${path}/select-panel`} harvesting={true} />
            </SelectableState>
          }
        />
        <Route path={`/confirm-weight-and-print`} element={<WeighingContainer backwardTo={`${path}/select-harvest`} />} />
      </Routes>
    </div>
  );
};

export default Harvesting;
