import React from "react";
import { Modal } from "reactstrap";
import Button from "../../layout/Button";

const ConfirmDeleteModal = ({ show = false, onConfirm, onCancel }) => {

    const handleCancel = () => {
        onCancel();
    }

    const handleConfirm = () => {
        onConfirm();
    }

    return (
        <Modal isOpen={show} className='modal-config confirm-delete-modal'>
            <div className='confirm-delete-modal-container'>
                <div className='confirm-delete-modal-header-container'>
                    <div className="confirm-delete-modal-header">DO YOU REALLY WANT TO DELETE?</div>
                </div>
                <div className="confirm-delete-modal-body">
                    Deleting this seed type will permanently remove it from the available choices for seeding. Furthermore, all associated seed type variants will also be deleted. This action is irreversible.
                    <br />
                    Are you sure you want to delete it?
                </div>
                <Button label='No' className="confirm-delete-modal-cancel-button" clickHandler={handleCancel} />
                <Button label='Yes' className="confirm-delete-modal-confirm-button" clickHandler={handleConfirm} />
            </div>
        </Modal>
    );
}

export default ConfirmDeleteModal;