import { useContext, useState, useEffect } from "react";
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";
import AuthContext from "../../contexts/auth/AuthContext";
import TicketsContext from "../../contexts/tickets/TicketsContext";
import TicketInformation from "./TicketInformation";
import SessionInformationContext from "../../contexts/session-information/SessionInformationContext";
import _ from 'lodash';

const Tickets = () => {
    const {currentTenant, currentContainer, userInformation} = useContext(AuthContext);
    const {sessionInformation, setSessionInformation} = useContext(SessionInformationContext);
    const { getServicedeskIssues, servicedeskIssues } = useContext(TicketsContext);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        const setServicedeskIssues = async () => {
            await getServicedeskIssues(currentTenant?.tenantId, currentContainer?.id, userInformation?.userId)
        };
        setServicedeskIssues();
        const interval = setInterval(setServicedeskIssues, 300000);
        
        return () => {
            clearInterval(interval);
          };
    }, []);

    const handleClick = (issue) =>{
        window.open(issue.sourceUrl, '_blank');
        
        const previousState = _.cloneDeep(sessionInformation);
        var ticketIndex = previousState?.readServiceDeskIssues?.findIndex(index => index.id === issue.id);
        if (ticketIndex !== undefined && ticketIndex !== -1) {
            previousState.readServiceDeskIssues[ticketIndex].status = issue.status;
            setSessionInformation(previousState);
        }
        else{
            setSessionInformation({
                ...previousState,
                readServiceDeskIssues: [
                    ...(previousState?.readServiceDeskIssues || []),
                    {id: issue.id, status: issue.status}
                ]
            });
        }       
    };

    const isTicketUpdated = (issue) => {
        if (sessionInformation?.readServiceDeskIssues?.some(readIssue => readIssue?.id == issue.id && readIssue?.status == issue.status)) {
            return false;
        }
        return true;
    }

    const unreadCount = servicedeskIssues?.filter(issue => isTicketUpdated(issue)).length;

    return (servicedeskIssues &&
        <ButtonDropdown className="button-dropdown-ticket-information" isOpen={open} toggle={() => setOpen((prev) => !prev)}>
            <DropdownToggle className='bg-transparent border-0 rounded shadow-none'>
                <i className="fa-solid fa-bell bell-icon-background"></i>
                {unreadCount > 0 && (
                        <div className="notification-badge">
                            {unreadCount}
                        </div>
                    )}
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-ticket-information">
                <DropdownItem disabled={true}>
                    {servicedeskIssues.length > 0 ? "Support Tickets" : "No current tickets."}
                </DropdownItem>
                {servicedeskIssues?.map((issue) => (
                    <DropdownItem key={issue.reference} onClick={() => handleClick(issue)}>
                        <TicketInformation summary={issue.summary} reference={issue.reference} createdAt={issue.createdAt} status={issue.status} newTicket={isTicketUpdated(issue)}/>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </ButtonDropdown>
    )
}

export default Tickets;