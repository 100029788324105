import { useContext, useEffect } from "react";
import UIContext from "../../contexts/ui/UIContext";

const MarketingUserView = () => {
  const {setInfo} = useContext(UIContext);

  useEffect(() => {
    setInfo('');
  },[]);

  return <div className='marketing-user-start-process-container' >
    <i className='fa-solid fa-lock lock-settings' ></i>
    <p className='no-access-text' >You do not have access to any processes.</p>
  </div>
};

export default MarketingUserView;