import { useState } from "react";

/**
 * @component InputField Component - A reusable React component for rendering an input field with error messaging.
 * @param {string} inputName The name attribute for the input field.
 * @param {object} inputProp An object containing properties related to the input field.
 * @property {string | number} inputProp.value Current value of the input field.
 * @property {boolean} inputProp.isValid Indicates whether the input is considered valid.
 * @property {boolean} inputProp.touched Indicates whether the input field has been interacted with.
 * @param {string} inputType The type attribute for the input field (e.g., "text", "password", etc.).
 * @param {function} onChange Callback function to handle the onChange event of the input field.
 * @param {boolean} disabled Indicates whether the input field is disabled.
 * @param {string} errorMessage The error message to be displayed when the input is invalid.
 * @returns {JSX.Element} The rendered InputField component.
 */
const InputField = ({inputName, value, isValid, touched, inputType, onChange, onBlur = () => {}, placeholder, disabled, errorMessage, step}) => {
    /**
     * @type {useState<boolean>} Controls the visibility of the error message. It is toggled based on the focus and blur events of the input field.
     */
    const [hasFocus, setHasFocus] = useState(false);

    /**
     * @function handleBlur Event handler triggered when the input field loses focus. It sets showErrorMessage to false.
     */
    const handleBlur = (event) => {
        setHasFocus(false);
        onBlur(event);
    };

    /**
     * @function handleFocus Event handler triggered when the input field gains focus. It sets showErrorMessage to true.
     */
    const handleFocus = () => {
        setHasFocus(true);
    };

    return(
        <div className='card-body-input-row' >
            <input
                type={inputType}
                name={inputName}
                className={isValid || !touched
                    ? 'input-property-field'
                    : 'input-property-field invalid-field'
                }
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
                onFocus={handleFocus}
                onBlur={handleBlur}
                step={step}
            />
            {!isValid && touched && hasFocus &&
                (
                    <div className='error-banner' >
                        <span>{errorMessage}</span>
                    </div>
                )
            }
        </div>
    )
};

export default InputField;