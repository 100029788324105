export const awsconfig = {
  "Auth": {
    "region": process.env.REACT_APP_REGION,
    "userPoolId": process.env.REACT_APP_COGNITO_USERPOOL_ID,
    "userPoolWebClientId": process.env.REACT_APP_COGNITO_USERPOOL_CLIENTID,
    "mandatorySignIn": true,
    "redirectSignIn": process.env.REACT_APP_CALLBACK_URL,
    "redirectSignOut": process.env.REACT_APP_CALLBACK_URL,
    "oauth": {
      "domain": process.env.REACT_APP_COGNITO_DOMAIN,
      "scope": ['email', 'openid', 'profile'],
      "redirectSignIn": process.env.REACT_APP_CALLBACK_URL,
      "redirectSignOut": process.env.REACT_APP_CALLBACK_URL,
      "responseType": 'code'
    }
  }
}
