import axiosClient from "../../http-common";

/**
 * The farmhand installations api service.
 */
class FarmhandInstallationsApiService {

    /**
   * Assigns a farmhand installation to a container.
   * @param {string} tenantId - The tenant identifier.
   * @param {string} containerId - The container identifier.
   * @param {object} farmHandInstallation - The farmhand installation object which contains the installation id and the name.
   * @returns A promise with the response from the API.
   */
    assignFarmHandInstallationToContainer(tenantId, containerid, farmHandInstallation){
        return axiosClient.post(`/api/v1/tenants/${tenantId}/containers/${containerid}/farmHandInstallation`, farmHandInstallation);
    };

    /**
   * Gets the farmhand installation to a given name.
   * @param {string} farmHandInstallationName - The name of a farmhand installation.
   * @returns A promise with the response from the API that contains the farmhand installation.
   */
    getFarmHandInstallationId(farmHandInstallationName){
        return axiosClient.get(`/api/v1/farmhandinstallations/${farmHandInstallationName}`);
    };

    /**
   * Gets the farmhand installation to a given id.
   * @param {string} farmHandInstallationId - The id of a farmhand installation.
   * @returns A promise with the response from the API that contains the farmhand installation.
   */
    getFarmHandInstallationById(farmHandInstallationId){
        return axiosClient.get(`/api/v1/farmhandinstallations/${farmHandInstallationId}`);
    };

    /**
   * Gets the id of the container the given farmhand installation is assigned to. Gets null if the farmhand installation is not assigned to a container.
   * @param {string} farmHandInstallationId - The name of a farmhand installation.
   * @returns A promise with the response from the API that contains the container id.
   */
    getContainerForFarmHandInstallationId(farmHandInstallationId){
        return axiosClient.get(`/api/v1/farmhandinstallations/${farmHandInstallationId}/container`);
    };

    /**
   * Unassigns a farmhand installation from a container.
   * @param {string} tenantId - The tenant identifier.
   * @param {string} containerId - The container identifier.
   * @param {string} farmHandInstallationId - The farmhand installation identifier.
   * @returns A promise with the response from the API.
   */
    unassignFarmHandInstallationFromContainer(tenantId, containerId, farmHandInstallationId){
        return axiosClient.delete(`/api/v1/tenants/${tenantId}/containers/${containerId}/farmhandinstallations/${farmHandInstallationId}`);
    };
};

export default new FarmhandInstallationsApiService();