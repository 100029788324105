import React, { Fragment, useContext, useEffect } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import EntitySelector from '../../ui-elements/EntitySelector';
import ReportFormContainer from './ReportFormContainer';
import ReportingScopeOption from './ReportingScopeOption';
import ReportOnPlantsWrapper from './ReportOnPlantsWrapper';
import ProcessContext from '../../../contexts/process/ProcessContext';

const Reporting = () => {
  const location = useLocation();
  const path = '/reporting';
  const { finishProcess } = useContext(ProcessContext);

  useEffect(() => {
    return () => {
      finishProcess();
    }
  },[]);

  const style =
    location.pathname === `${path}/submit-report`
      ? {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }
      : {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      };

  return (
    <div id='reporting-container' className='process-container' style={style}>
      <Routes>
        <Route path={'/'} element={<EntitySelector forwardTo={path} allPanels={true} />} />
        <Route path={`/tray`} element={<ReportingScopeOption />} />
        <Route path={`/panel`} element={<ReportingScopeOption />} />
        <Route path="/:sourceFromParams/units" element={<ReportOnPlantsWrapper process="reporting" />} />
        <Route path={`/submit-report`} element={
          <Fragment>
            <ReportFormContainer />
          </Fragment>
        }/>
      </Routes>
    </div>
  );
};

export default Reporting;