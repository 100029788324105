import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';
import StatusBarButton from '../../layout/StatusBarButton';
import Loading from '../../ui-elements/Loading';

const WallSlotSelector = ({ forwardTo }) => {
  const {
    activeProcessId,
    continueProcess,
    occupancyArray,
    loadOccupancy,
    processFinished,
    additionalProcessInformation,
  } = useContext(ProcessContext);

  const { loading } = useContext(UIContext);
  const navigate = useNavigate();

  const load = async () => {
    await loadOccupancy(additionalProcessInformation.destinationWall, 'panel');
  };

  const [confirmed, setConfirmed] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState(undefined);
  const [copyArray, setCopyArray] = useState([]);

  const confirmWallSlot = async () => {
    try {
      setConfirmed(true);
      await continueProcess(activeProcessId, selectedSlot.ordinalNumber);
      navigate(processFinished ? '/' : forwardTo ?? '/');

    } catch (error) {
      console.error(error);
    }
  };

  const ENTITY = additionalProcessInformation?.sourceEntity.toLowerCase().startsWith('p')
    ? additionalProcessInformation?.sourceEntity
    : additionalProcessInformation?.destinationEntity;

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    setCopyArray([...occupancyArray]);
  }, [occupancyArray]);

  const handleSelect = (clickedSlot) => {
    if (clickedSlot.occupancy === ENTITY || clickedSlot.occupancy !== 'available') return;

    const prevSlot = copyArray.find((slot) => slot.occupancy === ENTITY);

    clickedSlot.occupancy = ENTITY;
    if (prevSlot) prevSlot.occupancy = 'available';

    setSelectedSlot(clickedSlot);
    setCopyArray((prev) =>
      prev.map((slot) => {
        if (prevSlot && slot.ordinalNumber === prevSlot.ordinalNumber) return prevSlot;
        if (slot.ordinalNumber === clickedSlot.ordinalNumber) return clickedSlot;

        return slot;
      })
    );
  };

  return loading && confirmed ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      <div className='wall'>
        <div className='wall-container'>
          {copyArray.map((unit) => (
            <WallSlot
              key={unit.ordinalNumber}
              selected={selectedSlot?.ordinalNumber === unit.ordinalNumber}
              unit={unit}
              selectionHandler={() => handleSelect(unit)}
            />
          ))}
        </div>
      </div>
      {selectedSlot && (
        <StatusBarButton
          label='Confirm'
          icon='fas fa-check'
          reversed={true}
          clickHandler={confirmWallSlot}
          statusSlot={5}
        />
      )}
    </Fragment>
  );
};

export default WallSlotSelector;

const WallSlot = ({ unit, selected, selectionHandler }) => {
  return (
    <div className='wall-unit' data-selected={selected} data-occupied={unit.occupancy !== 'available'}>
      <div className='display-circle' onClick={selectionHandler}>
        {unit.occupancy == 'available' ? unit.ordinalNumber + 1 : unit.occupancy}
      </div>
    </div>
  );
};
