import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import App from './App';
import 'react-toastify/dist/ReactToastify.css';
import UIState from './contexts/ui/UIState';
import AuthProvider from './contexts/auth/AuthProvider';
import ProcessProvider from './contexts/process/ProcessState';
import TicketsProvider from './contexts/tickets/TicketsProvider';
import SessionInformationProvider from './contexts/session-information/SessionInformationProvider';
//import registerServiceWorker from './registerServiceWorker';

const rootElement = document.getElementById('root');

/**
 * https://a26058b5-fe3d-4c2e-9066-63d5037bb94d.auth.eu-central-1.amazoncognito.com/oauth2/authorize?redirect_uri=https%3A%2F%2Flocalhost%3A44316&response_type=token&client_id=28n378ligdqv03vkpg284ic8qa&identity_provider=COGNITO&scope=phone%20email%20profile%20openid%20aws.cognito.signin.user.admin&state=4lxGjCxiCBFEdxc2OOMm2buRIB4hGsTa
 */

const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />
  }
])

ReactDOM.createRoot(rootElement).render(
  <UIState>
    <SessionInformationProvider>
      <AuthProvider>
        <ProcessProvider>
          <TicketsProvider>
            <RouterProvider router={router} />
          </TicketsProvider>
        </ProcessProvider>
      </AuthProvider>
    </SessionInformationProvider>
  </UIState>
);

// Uncomment the line above that imports the registerServiceWorker function
// and the line below to register the generated service worker.
// By default create-react-app includes a service worker to improve the
// performance of the application by caching static assets. This service
// worker can interfere with the Identity UI, so it is
// disabled by default when Identity is being used.
//
//registerServiceWorker();
