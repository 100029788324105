export const HELP_DESK_LINK=(
    <>
        <a onClick={() => {
            var btn = document.getElementById('lailo-widget-toggle-button');
            btn.click();
        }} target='_blank' >
            <span className="link-styling">
                support
            </span>
        </a>
    </>
);