import ReactAvatar from 'react-avatar';
import React, { useState } from 'react';
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { useNavigate } from 'react-router-dom';

const Avatar = ({ userName }) => {
  return (
    <ReactAvatar
      round={true}
      color={ReactAvatar.getRandomColor('sitebase', [
        'black',
        'darkgray',
        'darkgrey',
        'dimgray',
        'dimgrey',
        'gainsboro',
        'gray',
        'grey',
        'lightgray',
        'lightgrey',
        'silver',
      ])}
      size='32px'
      className='login-initials'
      name={userName}
    />
  );
};

const LoggedInAvatar = ({ givenName, familyName, signOut }) => {
  const options = [
    {
      label: 'Change Password',
      value: '/changepassword',
      action: null,
    },
    {
      label: 'Logout',
      value: '/logout',
      action: signOut,
    },
  ];

  const [open, setOpen] = useState(false);
  const userName = givenName + ' ' + familyName;
  const navigate = useNavigate();

  const clickHandler = (option) => {
    if (option.action) {
      option.action();
    } else {
      navigate(option.value);
    }
  };

  return (
    <ButtonDropdown isOpen={open} toggle={() => setOpen((prev) => !prev)}>
      <DropdownToggle className='bg-transparent border-0 rounded shadow-none'>
        <Avatar userName={userName} />
      </DropdownToggle>
      <DropdownMenu
        className='border-radius-2'
        style={{
          borderColor: 'whitesmoke',
          boxShadow: '0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1)',
        }}
      >
        {options.map((option) => (
          <DropdownItem key={option.value} onClick={() => clickHandler(option)}>
            {option.label}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export { LoggedInAvatar as default, Avatar };
