import React from 'react';

const Notification = ({ text, type = "info", onClose }) => {

  const onClickClose = () => {
    onClose();
  }

  const renderNotification = () => {
    if (type === 'error-stay') {
      return <div className={`sweden-notification`} data-type={type}>
      <div className='sweden-notification-body'>
        {text}
      </div>
      <div className='close-notification-button close-button-color' onClick={onClickClose}>
        <i className='fas fa-window-close'></i>
      </div>
    </div>
    }
    if (type === 'info-stay' || type === 'info-stay-permanent') {
      return <div className={`sweden-notification`} data-type={type}>
      <div className='sweden-notification-body'>
        {text.map((line,i) => (<p key={i}>{line}</p>)) }
      </div>
      <div className='close-notification-button close-button-color' onClick={onClickClose}>
        <i className='fas fa-window-close'></i>
      </div>
    </div>
    }
    
    return <div className={`sweden-notification auto-close`} data-type={type}>
    <div className='sweden-notification-body'>{`${text}`}</div>    
  </div>;
  }

  return renderNotification();
}


export default Notification