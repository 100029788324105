import React from 'react';
import Channel from '../../discarding/panel/Channel';
import Panel from '../../discarding/panel/Panel';

const TransplantedPanelOverview = ({ panel, transplantingRelations }) => {
  const renderBlock = (block) => {
    const relation = (() => {
      return transplantingRelations.find(
        (relation) =>
          relation.plantSlotOrdinalNumber === block.startPlantSlotOrdinalNumber
      );
    })();

    const isSelected = !!relation;

    const width =
      block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber + 1 + '%';

    return (
      <div
        key={`${block.startPlantSlotOrdinalNumber}-${block.endPlantSlotOrdinalNumber}`}
        className='panel-item'
        data-selected={isSelected}
        style={{ width }}
      >
        {isSelected && (
          <p className='panel-item-text'>{relation.seedSlotOrdinalNumber + 1}</p>
        )}
        {!isSelected && block.isOccupied && (
          <i className={block.harvestCount >= 1 ? 'fab fa-pagelines panel-item-icon' : 'fas fa-seedling panel-item-icon'}></i>
        )}
      </div>
    );
  };

  return (
    <div className='transplanted-panel'>
      <Panel alignment='horizontal'>
        {panel.map((channel, channelIndex) => (
          <Channel key={channelIndex} channel={channel}>
            {channel.map((block, index) => renderBlock(block, index, channelIndex))}
          </Channel>
        ))}
      </Panel>
    </div>
  );
};

export default TransplantedPanelOverview;
