import React, { Fragment, useState, useEffect } from 'react';

const CategoryDropdown = ({ label, categories, activeCategory, setCategory }) => {
  const [defaultCategory, setDefaultCategory] = useState(null);
  const [options, setOptions] = useState(categories); 

  return (
    <Fragment>
      {label && <label htmlFor='select-box'>{label}</label>}
      <select
        id='select-box'
        value={activeCategory}
        onChange={(event) => setCategory(event.currentTarget.value)}
      >
        {categories?.sort().map((optionValue) => (
          <option key={optionValue} value={optionValue}>
            {optionValue}
          </option>
        ))}
      </select>
    </Fragment>
  );
};

export default CategoryDropdown;
