import axiosClient from "../../http-common";

class SessionInformationApiService {
  addSessionInformation(userId, content) {
    axiosClient.post(`api/v1/Users/${userId}/SessionInformation`, content)
  }
  
  async getSessionInformation(userId) {
      const result = await axiosClient.get(`api/v1/Users/${userId}/SessionInformation`);   
      return result;
  };
  
  updateSessionInformation(userId, content) {
      return axiosClient.put(`api/v1/Users/${userId}/SessionInformation`, content);
  };
};
export default new SessionInformationApiService();