const isValidEAN13GTIN = (gtinToCheck) => {
  // checks if the given gtinToCheck is a valid ean 17 gtin

  if (!gtinToCheck) return false;
  var checkSum = gtinToCheck.split('').reduce(function (p, v, i) {
    return i % 2 == 0 ? p + 1 * v : p + 3 * v;
  }, 0);

  if (checkSum % 10 != 0 || gtinToCheck.length !== 13) {
    return false;
  }

  return true;
}

const isValidEmailAddress = (email) => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/;
  const containsNoWhitespace = !/\s/.test(email);
  return regex.test(email) && containsNoWhitespace;
}

export {isValidEAN13GTIN, isValidEmailAddress};