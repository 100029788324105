import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import AuthContext from '../../contexts/auth/AuthContext';
import ProcessContext from '../../contexts/process/ProcessContext';
import { NavMenu } from '../NavMenu';
import NotificationContainer from '../NotificationContainer';
import StatusBar from '../StatusBar';

const Layout = (props) => {

  const { processes } = useContext(ProcessContext);
  const { accessibleSeedTypes, accessibleContainers, accessibleTemplates } = useContext(AuthContext);
  const location = useLocation();

  const isContainerManagementDisplayed = location.pathname === '/container';
  const readyToWork = accessibleContainers?.length > 0 && accessibleTemplates?.length > 0 && accessibleSeedTypes?.filter((seedType) => !seedType.outdated).length > 0;
  const areProcessesDisplayed = processes.length >= 9 && location.pathname === '/' && readyToWork;
  // const isContainerManagementDisplayed = false;
  // const readyToWork = false;
  // const areProcessesDisplayed = false;

  // console.log("Should not be center if any of the next 2is true:");
  // console.log("ProcessDisplay", areProcessesDisplayed);
  // console.log("ManagementDisplay", isContainerManagementDisplayed);
  // console.log("ReadyToWrokDisplay", readyToWork);
  const shouldNotBeCenterIf = [ isContainerManagementDisplayed ]
  const style = shouldNotBeCenterIf.some(condition => condition === true) ? { display: 'block' } : { display: 'flex', alignItems: 'baseline', justifyContent: 'center' }
  // Check if the used browser is safari
  const isSafari = navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
    navigator.userAgent &&
    navigator.userAgent.indexOf('CriOS') == -1 &&
    navigator.userAgent.indexOf('FxiOS') == -1;

  return (
    <div>
      {isSafari ? (
        <div id='layout-container-safari'>
          <NotificationContainer />
          <NavMenu />
          <div id='layout-container-safari-body' style={style}>{props.children}</div>
          <StatusBar />
        </div>
      ) : (
        <div id='layout-container'>
          <NotificationContainer />
          <NavMenu />
          <div id='layout-container-body' style={style}>{props.children}</div>
          <StatusBar />
        </div>
      )
      }
      <div id="lailo-smart-character"></div>
    </div>
  );
};

export default Layout;
