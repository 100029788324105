import React from 'react';

const PlantBlockPhotographing = ({ block, channelNr, isSelected, isInSelection, index, toBePhotographed, isFinished, ...rest }) => {
    const blockCode = `${channelNr + 1}/${index + 1}`;
    const slotSpan = block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber;
    const hasCurrentPhoto = block.hasCurrentPhoto;
    /**
     * TODO: Return another icon to indicate that the plant is multi-harvestable
     * @returns an Icon Element with the fitting font-awesome classname
     */
    const getIcon = () => {
      if(isFinished && !block?.isMultiharvestable) return <i className='fas fa-gift plant-icon'></i>;
      
      return <i className={block.harvestCount >= 1 ? 'fab fa-pagelines plant-icon': 'fas fa-seedling plant-icon'}></i>;
    }

    return (
      <div
        className='channel-block photographing-confirm'
        data-selected={isSelected}
        data-occupied={block.isOccupied}
        data-to-be-photographed={toBePhotographed}
        data-finished={isFinished}
        data-in-selection={isInSelection}
        data-current-photo={hasCurrentPhoto}
        style={{ width: block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber + 1 + "%" }}
        {...rest}
      >
        {getIcon()}
        {block.isOccupied && <span className='plant-name'>{block.seedName.length > 7 ? block.seedName.substring(0, 6) + "..." : block.seedName}</span>}
        {slotSpan >= 5 && <span className='block-code'>{blockCode}</span>}
      </div>
    );
}

export default PlantBlockPhotographing;