import React, { Fragment, useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';

import StatusBarButton from '../../layout/StatusBarButton';
import NumberPad from '../../ui-elements/NumberPad';
import Loading from '../../ui-elements/Loading';

const NumPlantsPerChannel = ({ forwardTo }) => {
  const { activeProcessId, continueProcess, updateOccupancy } = useContext(ProcessContext);
  const { loading } = useContext(UIContext);

  const navigate = useNavigate();

  useEffect(() => {
    return () => updateOccupancy([]);
  }, [updateOccupancy]);

  const [display, setDisplay] = useState({
    prefix: '',
    value: '',
  });

  const proceed = useCallback(async () => {
    try {
      await continueProcess(activeProcessId, display.value).then(() => {
        navigate(forwardTo);
      });
    } catch (error) {
      console.error(error);
    }
  }, [activeProcessId, continueProcess, display.value, forwardTo, navigate]);

  const setValue = (value, setDisplay) => {
    setDisplay((prev) => ({ ...prev, value }));
  };  

  return loading ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      <NumberPad prefix={display.prefix} value={display.value} setValue={(value) => setValue(value, setDisplay)} />
      {display.value && (
        <StatusBarButton
          label='Continue'
          icon='fas fa-chevron-right'
          reversed={true}
          clickHandler={proceed}
          statusSlot={5}
        />
      )}
    </Fragment>
  );
};

export default NumPlantsPerChannel;
