import { useEffect, useState } from 'react';

export default function useControlBrowserNavigation(onPreventingBack, useBeforeUnload = true) {

  const [paused, setPaused] = useState(false);

  // From popstate event docs:
  // " [...] browsers may not fire the popstate event at all unless the page has been interacted with."
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/popstate_event
  const onPopstate = (event) => {
    if (!paused) {
      event.preventDefault();
      setTimeout(() => {
        window.history.pushState(null, null, window.location.href);
      }, 0)
      onPreventingBack();
    }
  }

  // Preventing default for 'beforeunload' event is not supported in Safari
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/beforeunload_event
  const onBeforeUnload = (event) => {
    if (!paused) {
      event.preventDefault();
      return "";
    }
  }

  const blockNav = () => {
    setPaused(true);
  }

  const unblockNav = () => {
    setPaused(false);
  }  

  useEffect(() => {
    if (!paused) {
      window.history.pushState(null, null, window.location.href);

      if (useBeforeUnload) {
        window.addEventListener('beforeunload', onBeforeUnload);
      }

      window.addEventListener('popstate', onPopstate);

      return () => {
        window.removeEventListener('popstate', onPopstate);
        if (useBeforeUnload) {
          window.removeEventListener('beforeunload', onBeforeUnload);
        }
      }
    }
  }, []);

  return {
    blockState: paused ? 'paused' : 'blocking',
    blockNav,
    unblockNav
  }
}