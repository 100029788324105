import React, { useContext, useEffect, useState, Fragment } from 'react';
import UIContext from '../../contexts/ui/UIContext';
import { Route, Routes } from 'react-router-dom';
import TenantManager from './TenantManager';
import AddTenant from './AddTenant';

const TenantManagement = () => {
  const { setInfo } = useContext(UIContext);
  useEffect(() => {
    setInfo('Tenant Management');
  }, []);

  return (
    <div id='seed-management' className='seed-management-container process-container'>
      <Routes>
        <Route path={'/'} element={<TenantManager />} />
        <Route path={`/add-new-tenant`} element={<AddTenant />} />
      </Routes>
    </div>
  );
};
export default TenantManagement;
