import React from 'react';
import { Utilities } from '../../../../Utilities';

const TraySlot = ({ unit, index, isSelected, handleClick, cols }) => {
  const isAvailable = unit.occupancy === 'available';

  return (
    <div className='slot-unit' onClick={handleClick}>
      <div className='tray-name-container'>
        <i
          className='fas fa-th plant-icon'
          data-available={isAvailable && !isSelected}
        ></i>
        {!isAvailable && (
          <span className='tray-name'>
            {Utilities.truncateText(unit)}
          </span>
        )}
      </div>
      <div className='slot-number'>
        <span>{index < cols ? 'U' : 'L'} </span>
        <span>
          {index < cols ? `${(index % 10) + 1}` : `${((index - cols) % 10) + 1}`}
        </span>
      </div>
    </div>
  );
};

export default TraySlot;
