import {
    ADD_PRODUCE_TYPE,
    DELETE_PRODUCE_TYPE,
    GET_MANY_PRODUCE_TYPES,
    GET_PRODUCE_TYPE,
    SET_LOADING,
    UPDATE_PRODUCE_TYPE
} from "../../types/contextTypes";

export default (state, action) => {
    switch (action.type) {
        case ADD_PRODUCE_TYPE:
            return {
                ...state,
                allProduceTypes: state.allProduceTypes.push(action.payload)
            };
        case DELETE_PRODUCE_TYPE:
            return {
                ...state,
                allProduceTypes: state.allProduceTypes.filter(produceType => produceType.id !== action.payload)
            };
        case GET_MANY_PRODUCE_TYPES:
            return {
                ...state,
                allProduceTypes: action.payload
            };
        case GET_PRODUCE_TYPE:
            return {

            };
        case SET_LOADING:
            return {
                ...state,
                loading: action.payload
            };
        case UPDATE_PRODUCE_TYPE:
            const updatedIndex = state.allProduceTypes.findIndex(produceType => produceType.id === action.payload.id);

            if (updatedIndex !== -1) {
                const updatedProduceType = {
                    ...state.allProduceTypes[updatedIndex],
                    ...action.payload.updateProduceType
                };

                const updatedAllProduceTypes = [...state.allProduceTypes];
                updatedAllProduceTypes[updatedIndex] = updatedProduceType;

                return {
                    ...state,
                    allProduceTypes: updatedAllProduceTypes
                };
            }
            return state;
        default:
            return state;
    };
};