import React, { useContext } from 'react';
import ProcessContext from '../../../../contexts/process/ProcessContext';

const SeedSlotDiscardConfirm = ({ displayName, index }) => {
  const { additionalProcessInformation } = useContext(ProcessContext);

  const isOccupied = displayName !== '';
  const isSelected = additionalProcessInformation.sourceSelection.includes(index)  && isOccupied;
  const icon = (isSelected && isOccupied) ? 'fas fa-times plant-icon' : 'fas fa-seedling plant-icon';

  
  
  const row = Math.floor(index / 10);
  const col = index - (row * 10);
  const trayGridLabel = `${row + 1}/${col + 1}`;

  return (
    <div
      className='slot-unit seed-slot-discard-confirm'
      data-selected={isSelected}
      data-occupied={isOccupied}
    >
      <i className={icon}></i>
      <span className='tray-name'>{displayName}</span>
      <span className="tray-grid-label">{trayGridLabel}</span>
    </div>
  );
};

export default SeedSlotDiscardConfirm;
