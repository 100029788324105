import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import HarvestingProcedureCard from './HarvestingProcedureCard';
import ProcessContext from '../../../contexts/process/ProcessContext';
import HarvestingProceduresContext from '../../../contexts/management/harvestingProcedures/HarvestingProceduresContext';
import Loading from '../../ui-elements/Loading';

const HarvestingProcedureSelection = ({ forwardTo }) => {
    const { activeProcessId, continueProcess, notifyError } = useContext(ProcessContext);
    const { allHarvestingProcedures } = useContext(HarvestingProceduresContext);
    const [loading, setLoading] = useState(false);

    const navigate = useNavigate();


    const proceed = async (harvestingProcedureId) => {
        try {
            await continueProcess(activeProcessId, harvestingProcedureId);
            navigate(forwardTo);
        } catch (error) {
            if (/p(?=[0-9])/i.test(error.message)) {
                notifyError(`You tried to enter an invalid panel. Please select a panel between 1 and ${process.env.REACT_APP_MAX_PANEL_NUMBER}`);
            }
            console.error(error);
        }
    };


    return (loading ?
        <Loading fullScreen /> :
        <div className='harvesting-procedure-selection'>
            {allHarvestingProcedures &&
                allHarvestingProcedures.sort((a, b) => {
                    var nameA = a.type.toUpperCase();
                    var nameB = b.type.toUpperCase();
                    if (nameA < nameB)   {
                        return -1;
                    }
                    if (nameA > nameB) {
                        return 1;
                    }
                    return 0;
                }).map((hp) => {
                    var imageString = "/assets/icons/harvesting-procedure/";
                    var displayString = "";
                    for (let i = 0; i < hp.type.length; i++) {
                        if (hp.type[i] === hp.type[i].toUpperCase() && i !== 0) {
                            displayString += ' ';
                            imageString += '-';
                        }
                        displayString += hp.type[i];
                        imageString += hp.type[i].toLowerCase();
                    }

                    imageString += ".svg";

                    return <HarvestingProcedureCard
                        key={hp.id}
                        imagePath={imageString}
                        text={displayString}
                        onClickCallback={() => {proceed(hp.id) }}
                    />
                })}
        </div>
    )
}

export default HarvestingProcedureSelection
