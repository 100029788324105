export const GLOBAL_ADMIN = 'GlobalAdmin';

export const TENANT_ADMIN = 'TenantAdmin';
export const OPERATOR = 'Operator';

export const MARKETING = 'Marketing';

export const ROLES = {
  GLOBAL_ADMIN: 'GlobalAdmin',
  TENANT_ADMIN: 'TenantAdmin',
  OPERATOR: 'Operator',
  MARKETING: 'Marketing'
};

export const ALLOWEDROLES = [ TENANT_ADMIN, OPERATOR, MARKETING ];



