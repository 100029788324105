export const PROCESS_BASE_URL = '/Api/V1/Processes';

export const CONTROL_PROCESS_URL = '/Api/V1/ProcessControl';

export const ENTITIES_BASE_URL = '/Api/V1/Entities';

export const CONTAINERS_URL = '/api/v1/containers'

export const LABELPRINTERTEMPLATES_URL = "/api/v1/labelPrinterTemplates";

// *** Refactoring endpoints ***
export const TENANTS_BASE_URL = "api/v1/tenants";
export const USERS_BASE_URL = 'api/v1/users'

// Old Routes, pre-refactoring

export const GET_USER_CONTEXT_URL = "api/v1/authorization";
export const CONTAINERS_BASE_URL = 'api/v1/containers';

// Unsure
export const GET_CONTAINERS_BY_TENANT_ID_URL = "api/v1/tenant";



