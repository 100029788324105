import { Auth } from 'aws-amplify';
import axios from 'axios';


//#region Axios Interceptors
// Axios Request Interceptor to sanitize body in JSON and to set loading
axios.interceptors.request.use(
  async (config) => {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken();

    if (process.env.REACT_APP_ENV === 'development') {
      config.baseURL = process.env.REACT_APP_FARMER_API_URL;
    }

    if (!accessToken) throw new Error('Unauthorized');

    config.headers['Authorization'] = `Bearer ${accessToken.getJwtToken()}`;
    config.headers['content-type'] = 'application/json';
    return config;
  },
  (error) => {
    console.error("ERROR FROM HTTPAGENT:" ,error);
    delete axios.defaults.headers['Authorization'];

    Promise.reject('No access token available');
  }
);

//#endregion

export class HttpAgent {
  static responseBody = (response) => {
    if (response) return response.data;
  };

  static cancelRequest = () => {
    const cancelToken = axios.CancelToken.source();
    cancelToken.cancel();
  };

  static get(url, header) {
      return axios.get(url, header).catch((err) => {
        throw new Error(err?.response?.data?.message ?? err);
      }).then(HttpAgent.responseBody)
  }
  static post(url, body, header) {
      return axios.post(url, body, header).catch((err) => {
        throw new Error(err?.response?.data?.message ?? err);
      }).then(HttpAgent.responseBody)
  }
  static put(url, body, header) {
      return axios.put(url, body, header).catch((err) => {
        throw new Error(err?.response?.data?.message ?? err);
      }).then(HttpAgent.responseBody)
  }
  static delete(url, header) {
      return axios.delete(url, header).catch((err) => {
        throw new Error(err?.response?.data?.message ?? err);
      }).then(HttpAgent.responseBody)
  }
}
