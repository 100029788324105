import { useReducer, useContext, useEffect } from "react";

import AuthContext from "../../auth/AuthContext";
import ProduceTypesApiService from "../../../services/apiServices/ProduceTypesApiService";
import ProduceTypeContext from "./ProduceTypeContext";
import ProduceTypeReducer from "./ProduceTypeReducer";
import ProcessContext from "../../process/ProcessContext";
import { DELETE_PRODUCE_TYPE, GET_MANY_PRODUCE_TYPES, SET_LOADING, UPDATE_PRODUCE_TYPE } from "../../types/contextTypes";
import FarmerAppApiErrorMapper from "../../../utility/FarmerAppApiErrorMapper";
import { HELP_DESK_LINK } from "../../../constants/helpDesk";

const ProduceTypeProvider = (props) => {
    const initialState = {
        allProduceTypes: [],
        loading: false
    };

    const { currentTenant } = useContext(AuthContext);
    const { notifyError } = useContext(ProcessContext);
    const [state, dispatch] = useReducer(ProduceTypeReducer, initialState);

    useEffect(() => {
        if (currentTenant) {
            getManyProduceTypes();
        };
    }, [currentTenant]);

    const addProduceType = async (addProduceTypeRequest) => {
        setLoading(true);
        const body = {
            data: addProduceTypeRequest
        }

        try {
            await ProduceTypesApiService.addProduceType(currentTenant?.tenantId, body);
        } catch (e) {
            let error = FarmerAppApiErrorMapper.mapFarmerAppError(e);
            console.error("Error while adding produce type: ", error);
            throw FarmerAppApiErrorMapper.mapFarmerAppError(e);
        }
        finally {
            setLoading(false);
        }
    }

    const deleteProduceType = async (produceTypeId) => {
        setLoading(true);


        try {
            await ProduceTypesApiService.deleteProduceType(currentTenant?.tenantId, produceTypeId);
            deleteFromProduceTypes(produceTypeId);
        } catch (e) {           
            let error = FarmerAppApiErrorMapper.mapFarmerAppError(e);
            console.error("Error while adding produce type: ", error);
            throw FarmerAppApiErrorMapper.mapFarmerAppError(e);
        } finally {
            setLoading(false);
        }
    };

    const deleteFromProduceTypes = (produceTypeId) => {
        dispatch({
            type: DELETE_PRODUCE_TYPE,
            payload: produceTypeId,
        });
    };

    const getManyProduceTypes = async () => {
        setLoading(true);
        try {
            const produceTypes = await ProduceTypesApiService.getManyProduceTypes(currentTenant?.tenantId);
            setAllProduceTypes(produceTypes.data.data)
        } catch (error) {
            if (error.message === 'Not found.') {
                notifyError(<>We have encountered an issue while getting the produce types. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>)
            }
            else {
                notifyError(<>We have encountered an issue on our side while getting the produce types. Please contact the support team {HELP_DESK_LINK}.</>);
            }
            console.error("Error while getting produce types: ", error);
        } finally{
            setLoading(false);
        }
    };

    const getProduceType = (produceTypeId) => {
        const produceType = ProduceTypesApiService.getProduceType(currentTenant?.tenantId, produceTypeId)
            .then((response) => {
                return response.data.data;
            })
            .catch((error) => {
                notifyError(<>We have encountered an issue on our side while getting the produce types. Please contact the support team {HELP_DESK_LINK}.</>);
                console.error(error);
            });
        return produceType;
    };

    const setAllProduceTypes = (allProduceTypes) => {
        dispatch({
            type: GET_MANY_PRODUCE_TYPES,
            payload: allProduceTypes,
        });
    };

    const setLoading = (loading) => {
        dispatch({
            type: SET_LOADING,
            payload: loading
        });
    };

    const updateProduceType = async (produceTypeId, updateProduceType) => {
        setLoading(true);
        const body = {
            data: updateProduceType
        }

        try {
            await ProduceTypesApiService.updateProduceType(currentTenant?.tenantId, produceTypeId, body)
            updateFromProduceTypes(updateProduceType);
        } catch (e) {
            let error = FarmerAppApiErrorMapper.mapFarmerAppError(e);
            console.error("Error while updating produce type: ", error);
            throw FarmerAppApiErrorMapper.mapFarmerAppError(e);
        } finally {
            setLoading(false);
        };
    };

    const updateFromProduceTypes = (updateProduceType) => {
        dispatch({
            type: UPDATE_PRODUCE_TYPE,
            payload: updateProduceType,
        });
    };

    return (
        <ProduceTypeContext.Provider
            value={{
                ...state,
                addProduceType,
                deleteProduceType,
                getManyProduceTypes,
                getProduceType,
                setLoading,
                updateProduceType
            }}
        >
            {props.children}
        </ProduceTypeContext.Provider>
    );
};

export default ProduceTypeProvider;