import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Modal, ModalBody, ModalHeader } from 'reactstrap';
import AuthContext from '../../contexts/auth/AuthContext';
import UIContext from '../../contexts/ui/UIContext';
import StatusBarButton from '../layout/StatusBarButton';
import { HttpAgent } from '../../utility/HttpAgent';
import AddTenant from './AddTenant';
import TenantEditor from './TenantEditor';
import ProcessContext from '../../contexts/process/ProcessContext';
import { HELP_DESK_LINK } from '../../constants/helpDesk';

const TenantManager = () => {
  const { setInfo, addNotification } = useContext(UIContext);
  const { accessibleTenants, loadAccessibleTenantsAsync, reloadAccessibleTenants, userInformation } = useContext(AuthContext);

  const [allTenants, setAllTenants] = useState(accessibleTenants.sort(function (a, b){
    let x = a.name.toUpperCase(),
        y = b.name.toUpperCase();
    return x == y ? 0 : x > y ? 1 : -1; 
  }));
  const [allContainers, setAllContainers] = useState(null);
  const [adding, setAdding] = useState(false);
  const [taskRunning, setTaskRunning] = useState(false);
  const {notifyError} = useContext(ProcessContext);

  useEffect(() => {
    loadAllContainers().then((containers) => {
      setAllContainers(containers);
    });
    return () => {
      setInfo('Start a process');
    };
  }, []);

  const reload = async () => {
    setInfo('Tenant Management');
    setAdding(false);
    const tenants = await reloadAccessibleTenants(userInformation);
    setAllTenants(tenants.sort((a, b) => a.name < b.name));
  };

  const loadAllContainers = async () => {
    try {
      let url = 'api/v1/Containers';

      let result = await HttpAgent.get(url);

      return result.data;
    } catch (error) {
      notifyError(<>We have encountered an issue on our side while loading all containers. Please contact the support team {HELP_DESK_LINK}.</>);
      console.error(error);
    }
  };

  const navigate = useNavigate();

  const handleCancel = () => {
    setAdding(false);
  };

  const toggleAdding = () => {
    setAdding(!adding);
  };

  const updateTenantHandler = async (tenant) => {
    const body = {
      data: tenant,
    };

    HttpAgent.put(`api/v1/Tenants/${tenant.tenantId}`, body)
      .then(() => {
        addNotification('Successfully updated tenant.', 'success');
        reload();
      })
      .catch((error) => {
        if (error.message === 'Not found') {
          notifyError(<>We have encountered an issue while updating the tenant. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
        }
        else{
          notifyError(<>We have encountered an issue on our side while updating the tenant. Please contact the support team {HELP_DESK_LINK}.</>);
        }
        console.log(error.message);
      });
  };

  const addTenantHandler = async (tenant) => {
    try {
      tenant.role = 2;
      let result = await HttpAgent.post('api/v1/tenants', tenant);
      addNotification('Successfully created tenant.', 'success');
      await reload();
    } catch (error) {
      notifyError(<>We have encountered an issue on our side while adding the new tenant. Please contact the support team {HELP_DESK_LINK}.</>);
      console.log(error.message);
    }
  };

  const deleteTenantHandler = async (tenant) => {
    let url = `api/v1/tenants/${tenant.tenantId}`;
    setTaskRunning(true);
    try {
      let result = await HttpAgent.delete(url);
      addNotification('Successfully deleted tenant.', 'success');
      setTaskRunning(false);
      reload();
    } catch (error) {
      if (error.message === 'Not found') {
        notifyError(<>We have encountered an issue while deleting the tenant. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
      }
      else{
        notifyError(<>We have encountered an issue on our side while deleting the tenant. Please contact the support team {HELP_DESK_LINK}.</>);
      }
      console.log(error.message);
      setTaskRunning(false);
    } finally {
    }
  };

  const renderComponents = () => {
    return (
      <div className='seed-list-overview'>
        {!adding && (
          <Card className='seed-card' onClick={toggleAdding}>
            <h1 className='add-seed-symbol'>
              <i className='fas fa-plus'></i>
            </h1>
          </Card>
        )}
        {adding && <AddTenant cancelHandler={handleCancel} saveHandler={addTenantHandler} />}
        {allTenants?.length > 0 &&
          allTenants?.map((tenant, index) => (            
            <TenantEditor
              key={tenant?.tenantId}
              tenant={tenant}
              containers={allContainers?.filter((c) => c.tenantId === tenant?.tenantId)}
              saveHandler={updateTenantHandler}
              deleteHandler={deleteTenantHandler}
            />
          ))}
        <Modal isOpen={taskRunning} style={{backgroundColor: 'red !important'}}>
          <ModalHeader>Tenant deletion in progress...</ModalHeader>
          <ModalBody>
            <p>
              Tenant deletion may take up a long time, depending on the number of containers this tenant had. Please
              allow up to several minutes per container. Please don't navigate away or close the app.
            </p>
            <i className='fas fa-cog fa-spin'></i>
          </ModalBody>
        </Modal>
        <StatusBarButton
          label='Done'
          clickHandler={() => navigate('/')}
          statusSlot={3}
          icon='fair fai-circle-check'
          type='inline'
        />
      </div>
    );
  };

  return renderComponents();
};

export default TenantManager;
