import { GET_SERVICEDESK_ISSUES } from "../types/contextTypes";

export default (state, action) => {
    switch(action.type){
        case GET_SERVICEDESK_ISSUES:
            return{
                ...state,
                servicedeskIssues: action.payload
            };
        default:
            return state;
    };
};