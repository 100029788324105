import React, { useContext, useEffect } from 'react';
import ProcessContext from '../../contexts/process/ProcessContext';
import Button from '../layout/Button';
import NumberPadDisplay from './NumberPadDisplay';
import NumberPadNumbersContainer from './NumberPadNumbersContainer';

const NumberPad = ({ disabled, prefix, value, setValue }) => {
  const { updateDisplayPrefix } =
    useContext(ProcessContext);

  useEffect(() => {
    updateDisplayPrefix(prefix);
  }, []);

  const buttonReturn = (unit) => {
    let newDisplay = value + unit;
    setValue(newDisplay);
    // updateDisplayValue(newDisplay);
  };

  const backspaceReturn = () => {
    if (!value) {
      return;
    }

    let newDisplayValue = value.slice(0, -1);
    // updateDisplayValue(newDisplayValue);
    setValue(newDisplayValue);
  };

  return (
    <div className='sweden-ui-number-pad'>
      <div className='display-section'>
        <div className='screen-and-backspace'>
          <NumberPadDisplay displayValue={value} />
          <Button
            label=''
            icon='fas fa-backspace'
            disabled={disabled}
            clickHandler={backspaceReturn}
          />
        </div>
      </div>
      <div className='numbers'>
        <NumberPadNumbersContainer disabled={disabled} clickHandler={buttonReturn} />
      </div>
    </div>
  );
};

export default NumberPad;
