import React, { Fragment, useContext, useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import SelectableContext from '../../../contexts/local/selectable/SelectableContext';
import ProcessContext from '../../../contexts/process/ProcessContext';
import UIContext from '../../../contexts/ui/UIContext';

import StatusBarButton from '../../layout/StatusBarButton';
import Loading from '../../ui-elements/Loading';
import Channel from '../discarding/panel/Channel';
import Panel from '../discarding/panel/Panel';
import PlantBlockSelectable from '../discarding/panel/PlantBlockSelectable';
import SelectionSidebar from '../../layout/Sidebar/SelectionSidebar';

const PanelSlotSelector = ({ forwardTo }) => {
  const navigate = useNavigate();
    
  const { loading } = useContext(UIContext);
  const { selection, Sidebar } = useContext(SelectableContext);
  const { additionalProcessInformation, activeProcessId, continueProcess } = useContext(ProcessContext);

  const [occupancyArray, setOccupancyArray] = useState([]);

  useEffect(() => {
    if (additionalProcessInformation) {
      setOccupancyArray(additionalProcessInformation.virtualOccupancy ?? []);
    }
  }, [additionalProcessInformation]);

  const proceedToConfirmation = useCallback(async () => {
    try {
      await continueProcess(activeProcessId, selection);
      navigate(forwardTo);
    } catch (error) {
      console.error(error);
    }
  }, [activeProcessId, continueProcess, forwardTo, navigate, selection]);  

  const renderBlock = (block, index, channelIndex) => {
    const slotSpan = block.endPlantSlotOrdinalNumber - block.startPlantSlotOrdinalNumber + 1;

    return (
      <PlantBlockSelectable
        key={`${index}-${block.startPlantSlotOrdinalNumber}-${block.endPlantSlotOrdinalNumber}`}
        block={block}
        channelNr={channelIndex - 1}
        index={index}
        disabled={!block.isOccupiable}        
        hideContent={!block.isOccupiable && !block.isOccupied}
        style={{ width: slotSpan + '%' }}
        harvesting={false}
      />
    );
  };

  return loading ? (
    <Loading fullScreen />
  ) : (
    <Fragment>
      {Sidebar}
      <Panel alignment='horizontal'>
        {occupancyArray.map((channel, channelIndex) => (
          <Channel key={channelIndex} channel={channel}>
            {channel.map((block, index) => renderBlock(block, index, channelIndex + 1))}
          </Channel>
        ))}
      </Panel>
      {selection.length > 0 && (
        <Fragment>
          <StatusBarButton
            label='Continue'
            icon='fas fa-chevron-right'
            reversed={true}
            clickHandler={proceedToConfirmation}
            statusSlot={5}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

export default PanelSlotSelector;
