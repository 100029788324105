import axiosClient from "../../http-common";

class ContainersApiService {
    createContainer(tenantId, container) {
        return axiosClient.post(`api/v1/Tenants/${tenantId}/Containers`, container);
    };

    deleteContainer(tenantId, containerId) {
        return axiosClient.delete(`/api/v1/tenants/${tenantId}/containers/${containerId}`);
    };

    getContainersByTenantId(tenantId) {
        return axiosClient.get(`/api/v1/tenants/${tenantId}/containers`);
    };

    updateContainer(tenantId, containerId, updateContainerRequest) {
        return axiosClient.put(`/api/v1/tenants/${tenantId}/containers/${containerId}`, updateContainerRequest);
  }
};

export default new ContainersApiService();