import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router';
import UIContext from '../contexts/ui/UIContext';
import Notification from './Notification';

const NotificationContainer = () => {
  const { notifications, error, errorBoundary, removeError } = useContext(UIContext);
  const location = useLocation();
  const onCloseNotification = () => {
    removeError();
  }

  useEffect(() => {
    if (!errorBoundary) {
      removeError();
      return;
    }

    if (!location.pathname.includes(errorBoundary)) {
      removeError();
      return;
    }
  }, [location]);

  return (
    <div id="sweden-notification-container">
      {notifications.length > 0 && notifications.map((notif) =>
        <Notification key={notif.id} text={notif.text} type={notif.type} />
      )}
      {error && <Notification key={'errorNotification'} text={error} type={'error-stay'} onClose={onCloseNotification} />}
    </div>
  )
}


export default NotificationContainer;