import { useContext, useState, useEffect } from "react";
import DestinationSelectionCard from "./DestinationSelectionCard";
import ProcessContext from "../../../contexts/process/ProcessContext";
import { useLocation, useNavigate } from "react-router";
import StatusBarButton from "../../layout/StatusBarButton";
import Button from "../../layout/Button";

const DestinationSelection = ({forwardTo}) => {
    const {activeProcessId, continueProcess, processFinished, loadOccupancy, occupancyArray} = useContext(ProcessContext);
    const navigate = useNavigate();
    const location = useLocation();
    const {selectionType} = location.state || {};
    const [disabled, setDisabled] = useState(false);

    const [selectionTypes, setSelectionTypes] = useState([
        {
            key: 0,
            value: "RemoveFromLocation",
            text: "Remove From Location",
            iconClass: "fair2 fai-put-to-nowhere",
            selected: false
        },
        {
            key: 1,
            value: "PlaceOnTable",
            text: "Place on Table",
            iconClass: "fair2 fai-place-on-seeding-table",
            selected: false
        },
        {
            key: 2,
            value: "PlaceInObject",
            text: selectionType == "p" ? "Place into Wall" : "Place into Seed Table",
            iconClass: selectionType === "p" ? "fair2 fai-place-into-wall" : "fair2 fai-place-into-seeding-table",
            selected: false
        }
    ]);

    const load = async () => {
        await loadOccupancy('s1', 'panel');
    };

    useEffect(() => {
        load();
    }, []);

    useEffect(() => {
        if (occupancyArray?.length > 0) {
            if (selectionType === "p") {
                const panelSlot = occupancyArray?.find(sl => sl.slotType === 2);
                setDisabled(panelSlot?.occupancy != "available");
            };
            if (selectionType === "t") {
                const traySlot = occupancyArray?.find(sl => sl.slotType === 1 && sl.ordinalNumber === 16);
                setDisabled(traySlot?.occupancy != "available");
                console.log(traySlot?.occupancy != "available");
            };
        }
    }, [occupancyArray])

    const [selectedOption, setSelectedOption] = useState(null);

    const clickHandler = (option) => {
        const updatedSelectionTypes = selectionTypes.map((item) => {
            if (item.key === option.key) {
                return { ...item, selected: !item.selected };
            } else {
                return { ...item, selected: false };
            }
        });

        setSelectionTypes(updatedSelectionTypes);

        const newlySelectedOption = updatedSelectionTypes.find(item => item.key === option.key && item.selected);
        setSelectedOption(newlySelectedOption || null);
    };

    const finishSelection = async () => {
        try {
            await continueProcess(activeProcessId, selectedOption.value);
    
            if (selectionType === "p" && selectedOption.key === 2) {
                console.log("Navigate to: ", forwardTo + "/panel");
                navigate(forwardTo + "/panel");
            }
            else if (selectionType === "t" && selectedOption.key === 2) {
                navigate(forwardTo + "/tray");
            }
            else {
                console.log("Navigate to: root");
                navigate("/");
            };
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <>
            <div className="destination-selection">
                {selectionTypes.map(option => (
                    <Button
                        className="destination-selection-card"
                        label={option.text}
                        key={option.key}
                        clickHandler={() => clickHandler(option)}
                        icon={option.iconClass}
                        disabled={option.key === 1 ? disabled : false}
                    />
                ))}
            </div>
            {selectedOption && 
            <StatusBarButton
                label={selectedOption.key === 2 ? 'Continue' : 'Confirm'}
                icon='fas fa-chevron-right'
                reversed={true}
                clickHandler={finishSelection}
                statusSlot={5}
            />}
        </>
    );
};

export default DestinationSelection;