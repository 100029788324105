import { useEffect, useContext, useState } from "react";
import AuthContext from "../../../../contexts/auth/AuthContext";
import UIContext from "../../../../contexts/ui/UIContext";
import SeedTypeSelectionCard from "./Card";
import Loading from "../../../ui-elements/Loading";

const List = ({ clickHandler }) => {
    const { accessibleSeedTypes, loading } = useContext(AuthContext);
    const { setInfo } = useContext(UIContext);
    const [showCurrent, setShowCurrent] = useState(true);
    const [showOutdated, setShowOutdated] = useState(false);

    useEffect(() => {
        setInfo('Seed Type Selection');
        return () => {
            setInfo("Produce Type Management");
        }
    }, []);

    return loading ? <Loading fullScreen={true} /> : (
        <div className="select-seed-type-container">
            <div className="select-seed-type-accordion">
                <div className="produce-type-accordion-title" onClick={() => setShowCurrent(!showCurrent)}>
                    <div className="select-seed-type-icon">
                        <i className={showCurrent ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>
                    </div>
                    <div>
                        Current
                    </div>
                </div>
                <div className={"select-seed-type-list" + (showCurrent ? "-active" : "-inactive")}>
                    {showCurrent && (accessibleSeedTypes?.filter((seedType) => !seedType.outdated).sort((a, b) => {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                    })
                        .map((seedType) => {
                            return (
                                <SeedTypeSelectionCard key={seedType.seedTypeId} seedType={seedType} clickHandler={clickHandler} />
                            );
                        }))}
                </div>
            </div>
            <div className="select-seed-type-accordion">
                <div className="produce-type-accordion-title" onClick={() => setShowOutdated(!showOutdated)}>
                    <div className="select-seed-type-icon">
                        <i className={showOutdated ? "fas fa-chevron-down" : "fas fa-chevron-right"}></i>
                    </div>
                    <div>
                        Deleted
                    </div>
                </div>
                <div className={"select-seed-type-list" + (showOutdated ? "-active" : "-inactive")}>
                    {showOutdated && accessibleSeedTypes?.filter((seedType) => seedType.outdated).sort((a, b) => {
                        return a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1;
                    })
                        .map((seedType) => {
                            return (
                                <SeedTypeSelectionCard key={seedType.seedTypeId} seedType={seedType} clickHandler={clickHandler} />
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default List;