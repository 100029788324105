import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import AuthContext from '../../../contexts/auth/AuthContext';
import ProcessContext from '../../../contexts/process/ProcessContext';
import { useNavigate, useParams } from 'react-router-dom';
import StatusBarButton from "../../layout/StatusBarButton";
import PrintLabelModal from './PrintLabelModal';
import ConfirmEditModal from './ConfirmEditModal';
import LabelPrinterTemplateApiService from '../../../services/apiServices/LabelPrinterTemplateApiService';
import ProduceTypeContext from '../../../contexts/management/produceTypes/ProduceTypeContext';
import Loading from '../../ui-elements/Loading';
import { HELP_DESK_LINK } from '../../../constants/helpDesk';
import SeedTypesApiService from '../../../services/apiServices/SeedTypesApiService';

const Detail = () => {
    const {produceTypeId} = useParams();
    const {allProduceTypes, loading} = useContext(ProduceTypeContext);
    const produceType = allProduceTypes.find(pt => pt.id === produceTypeId);
    const navigate = useNavigate();
    const { currentTenant, currentContainer, accessibleSeedTypes, accessibleTemplates } = useContext(AuthContext);
    const [showPrintLabelModal, setShowPrintLabelModal] = useState(false);
    const [showConfirmEditModal, setShowConfirmEditModal] = useState(false);
    const [seedType, setSeedType] = useState(null);
    const { notifySuccess, notifyError } = useContext(ProcessContext);

    useEffect(() => {
        const loadSeedType = async () => {
            const seedType = accessibleSeedTypes.find((seedType) => seedType?.id === produceType?.seedTypeId);
           setSeedType(seedType);
        }
        
        if (produceType) {
            loadSeedType();
        }
    }, [produceType]);

    const getSeedType = () => {
        return seedType?.name + ' (Nº ' + seedType.number + ')';
    };

    const getTemplate = () => {
        const template = accessibleTemplates?.find((template) => template.id === produceType.labelPrinterTemplateId);
        return template?.name;
    };

    const onPrintLabel = async () => {
        setShowPrintLabelModal(true);
    };

    const onCancelPrintLabel = () => {
        setShowPrintLabelModal(false);
    };

    const printLabel = useCallback((container, harvestingProcedure) => {
        const labelPrintingRequest = {
            labelPrinterTemplateId: produceType.labelPrinterTemplateId,
            printData: {
                produceBusinessKeyHash: 'testprint',
                produceHarvestingDate: new Date(),
                produceWeight: 0.00,
                produceTypeClassification: produceType.classification,
                produceTypeGtin: produceType.gtin ?? null,
                produceTypeMarketingName: produceType.marketingName,
                produceTypeNumber: produceType.number,
                produceStorageAdvice: harvestingProcedure?.storageAdvice,
            }
        }

        LabelPrinterTemplateApiService.printLabel(currentTenant?.tenantId, container?.id, labelPrintingRequest)
            .then(() => {
                notifySuccess("Printed label.");
            })
            .catch((e) => {
                notifyError(<>We have encountered a problem when printing. Could you please logout, sign back in, and try again? If the error still exists, please contact the support team {HELP_DESK_LINK}.</>);
                console.error("Error while printing a test label: ", e);
            });

    });

    const onConfirmEdit = async () => {
        setShowConfirmEditModal(true);
    };

    const onCancelConfirmEdit = () => {
        setShowConfirmEditModal(false);
    };

    const confirmEdit = useCallback(() => {
        navigate(`/management/produce-types/${produceType.id}/edit`);
    });

    return loading || accessibleSeedTypes < 1 || !seedType ? (
        <Loading fullScreen={true} />
    ):(<>
        <PrintLabelModal show={showPrintLabelModal} onCancel={onCancelPrintLabel} onPrint={printLabel} />
        <ConfirmEditModal show={showConfirmEditModal} onCancel={onCancelConfirmEdit} onConfirm={confirmEdit} />
        <div className='produce-type-detail-container'>
            <div className="produce-type-detail-card-container">
                <div className="produce-type-detail-card">
                    <div className="produce-type-detail-marketing-name produce-type-detail-label">
                        Marketing Name
                    </div>
                    <div className="produce-type-detail-marketing-name-value produce-type-detail-value">
                        {produceType?.marketingName}
                    </div>
                    <div className="produce-type-detail-classification produce-type-detail-label">
                        Classification
                    </div>
                    <div className="produce-type-detail-classification-value produce-type-detail-value">
                        {produceType?.classification}
                    </div>
                    <div className="produce-type-detail-number produce-type-detail-label">
                        Produce Number
                    </div>
                    <div className="produce-type-detail-number-value produce-type-detail-value">
                        {produceType?.number}
                    </div>
                    <div className="produce-type-detail-gtin produce-type-detail-label">
                        GTIN
                    </div>
                    <div className="produce-type-detail-gtin-value produce-type-detail-value">
                        {produceType?.gtin}
                    </div>
                    <div className="produce-type-detail-label-printer-template produce-type-detail-label">
                        Label Printer Template
                    </div>
                    <div className="produce-type-detail-label-printer-template-value produce-type-detail-value">
                        {getTemplate()}
                    </div>
                    <div className="produce-type-detail-seed produce-type-detail-label">
                        Seed Type
                    </div>
                    <div className="produce-type-detail-seed-value produce-type-detail-value">
                        {getSeedType()}
                    </div>
                    <div className="produce-type-detail-image produce-type-detail-label">
                        Produce Image
                    </div>
                    <div className="produce-type-detail-image-value">
                        {produceType?.imageUrl ?
                            <div
                                className="produce-type-detail-image"
                                style={{ background: `url(${produceType?.imageUrl}) no-repeat center center/contain` }}
                            />
                            :
                            <div className="produce-type-detail-no-image-container">
                                <div className="fas fa-image produce-type-detail-no-image-icon"></div>
                            </div>
                        }
                    </div>
                    <div className="produce-type-detail-appetizer-text produce-type-detail-label">
                        Appetizer Text
                    </div>
                    <div className="produce-type-detail-appetizer-text-value produce-type-detail-value">
                        {produceType?.appetizerText}
                    </div>
                    <div className="produce-type-detail-description produce-type-detail-label">
                        Produce Description
                    </div>
                    <div className="produce-type-detail-description-value produce-type-detail-value">
                        {produceType?.description}
                    </div>
                </div>
            </div>
            <StatusBarButton
                className='seed-card-btn'
                statusSlot={1}
                label='Print'
                type='inline'
                icon='fas fa-print'
                clickHandler={onPrintLabel}
                disabled={!produceType.labelPrinterTemplateId}
            />
            <StatusBarButton
                label='Done'
                clickHandler={() => navigate('/management/produce-types/list')}
                statusSlot={3}
                icon='fas fa-check-circle'
                type='inline'
            />
            <StatusBarButton
                label='Edit'
                clickHandler={onConfirmEdit}
                statusSlot={5}
                reversed={true}
                icon='fas fa-pen'
                type='inline'
            />
        </div>
    </>)
}

export default Detail;