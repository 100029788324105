import React, { useCallback, useContext } from "react";
import { useNavigate } from "react-router";
import { Card, CardHeader, CardBody } from "reactstrap";
import Button from "../../layout/Button";
import AuthContext from "../../../contexts/auth/AuthContext";

const ContainerCard = ({ container, deleteHandler }) => {
  const navigate = useNavigate();
  const {
    currentContainer
  } = useContext(AuthContext);

  const locationJSX = (container?.latitude && container?.longitude) ? (
    <>
      {container.street} {container.number}
      <br />
      {container.postalCode} {container.city}
    </>
  ) : 'No location set';

  const handleCardClick = (event) => {
    event?.stopPropagation();
    navigate(`/management/containers/${container.id}/details`);
  }

  const handleDelete = (event) => {
    event?.stopPropagation();
    deleteHandler(container?.id);
  }
  
  const handleEdit = useCallback((event) => {
    event?.stopPropagation();
    navigate(`/management/containers/${container?.id}/edit`);
  });

  return <Card className='container-card' onClick={handleCardClick}>
    <>
      <CardHeader className='container-card-header'>
        <div >
          <div className='container-name'>{container?.name}</div>
          <div className='container-location-icon'><i className="resize-icon fas fa-map-marker-alt"></i>
          </div>
          <div className='container-location-container'>
            {locationJSX}
          </div>
        </div>
      </CardHeader>
      <CardBody className='container-card-body'>
        <div className='container-description-label'>Description</div>
        <div className='container-description'>{container?.description}</div>
      </CardBody>
      <div className='container-card-buttons'>
        <>
          <Button
            className='container-card-btn'
            label={'Delete'}
            clickHandler={handleDelete}
            disabled={currentContainer?.id === container?.id}
          />
          <Button
            className='container-card-btn'
            label={'Edit'}
            clickHandler={handleEdit} />
        </>
      </div>
    </>
  </Card>
}

export default ContainerCard;